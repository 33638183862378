import React, { useState, useContext, useRef, useCallback, useEffect } from "react";
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import submit from '../../images/icons/Check.svg'
import { Loader } from "../../components/Loader/Loader";
import './CompanyLogo.css'
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export const CompanyLogo = () => {
  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading } = useHttp();
  const ref = useRef();
  const [file, setFile] = useState();

  const [companyMetadata, setCompanyMetadata] = useState([]);
  const [editmode, setEditmode] = useState(false);

  const getMetadata = useCallback(async () => {
    try {
      if (token) {
        const metadata = await request('/back_office/api/user/company_metadata', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyMetadata(metadata);

        return metadata;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const saveLogo = async (companyMetadata) => {
    try {
      if (token) {
        await request(`/back_office/api/user/company_metadata_edit`, 'POST', companyMetadata, {
          Authorization: `Bearer ${token}`
        })
      }
    } catch (error) { }
  }
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData()
    formData.append('file', file)

    const url = `/back_office/api/user/bucket_upload`
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch(err => showToastMessage('Oops, something went wrong, please try again later'));

    if (!response.error) {
      const metadata = {
        '1397': `<img alt="" border="0" height="125" src="https://storage.googleapis.com/dynamic-content-images/${response.data.metadata.name}" style="border-width: 0px; border-style: solid; height: 125px;" />`,
        '112': `<img alt="" border="0" height="100" src="https://storage.googleapis.com/dynamic-content-images/${response.data.metadata.name}" style="height: 100px; border-width: 0px; border-style: solid;" />`
      }
      await saveLogo(metadata)
      await getMetadata()
    }
  };

  return (
    <>
      {!loading && <div className='logo-card'>
        <div className='logo-card-title'>
          <h2 style={{ marginBottom: '8px' }}>Company logo</h2>
          {!editmode ?
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div dangerouslySetInnerHTML={{ __html: companyMetadata['1397'] }}></div>
              <NavLink className="button-link">
                <button
                  onClick={() => {
                    setEditmode(true)
                  }}>

                  Edit
                </button>
              </NavLink>
            </div>

            :
            <div>
              <p>Please use company logo in a format PNG, JPEG or SVG</p>
              <form
                onSubmit={() => this.refs.file.value = ''}
              >
                <input
                  type="file" id="file" name="file"
                  accept="image/png"
                  onChange={handleOnChange}
                  ref={ref}
                />
                <br />

                <button
                  onClick={(e) => {
                    setEditmode(false)
                    handleOnSubmit(e)
                  }}
                  className="button-submit"
                // disabled={!fileName?.endsWith('.csv') || file?.size > 10485760 || !lang?.label}
                >
                  <img src={submit} alt="submit"></img>
                  Submit
                </button>
              </form>
            </div>
          }
        </div>
      </div>}
      {
        loading && <Loader />
      }
    </>
  );
}
