import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import Modal from 'react-modal';
import cn from 'classnames';
import { AuthContext } from '../../context/AuthContext';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
import { ssoOauthProviders, ssoSamlProviders, ssoProtocols } from '../../config/config';
import { useHttp } from "../../hooks/http.hook";
import { useCrypto } from "../../hooks/crypto.hook";
import { Loader } from "../Loader/Loader";
import { CustomizedTooltipDisabled } from '../Tooltips/Tooltip';
import {
  customModalStyles, googleConfigOptions, azureConfigOptions, storageName,
  keycloakConfigOptions, oktaConfigOptions, samlConfigOptions,
} from '../../config/config';
import copy from '../../images/icons/copy.svg';
import eye from '../../images/icons/Eye.svg';
import closeEye from '../../images/icons/Eye Slash.svg';
import { getCookie, removeCookie } from "../../common/cookieHandler";
import { customStyles } from "../../styles/customStyles";
import './SSOConfig.css';

export const SSOConfig = ({ config, setConfig, fetchConfig, card, setCard }) => {
  const inputRef = useRef(null);
  const { showToastMessage, token, user, dateExpired } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState({});
  const [selectedProviderOption, setSelectedProviderOption] = useState({ value: 'google', label: 'google' });
  const [selectedProtocolOption, setSelectedProtocolOption] = useState({ value: 'OAuth2.0', label: 'OAuth2.0' });
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [configModal, openConfigModal] = useState(false);
  const [inputConfig, setInputConfig] = useState({scope: ['email', 'profile']});
  const [secretShown, setSecretShown] = useState(false);
  const [inputOptions, setInputOptions] = useState([]);
  const [modalChangeConfigIsOpen, setModalChangeConfigIsOpen] = useState(false);

  useEffect(() => {
    if (config?.config) {
      setInputConfig(config.config);
      setSelectedProtocolOption({ value: config.protocol, label: config.protocol });
      setSelectedProviderOption({ value: config.provider, label: config.provider });
    }
  }, [config]);
  
  useEffect(() => {
    if (selectedProviderOption.value === 'google' || selectedProviderOption.value === 'keycloak') {
      setInputConfig(prev =>({
        ...prev,
        scope: ['email', 'profile'],
        callbackURL: process.env.REACT_APP_DOMAIN_BASE_URL + '/back_office/api/auth/' + selectedProviderOption.value + '/callback'
      }));
    }
    if (selectedProviderOption.value === 'okta') {
      setInputConfig(prev =>({
        ...prev,
        scope: ['email', 'profile', 'openId'],
        callbackURL: process.env.REACT_APP_DOMAIN_BASE_URL + '/back_office/api/auth/' + selectedProviderOption.value + '/callback'
      }));
    }
    if (selectedProviderOption.value === 'azuread-openidconnect') {
      setInputConfig(prev =>({
        ...prev,
        scope: ['email', 'profile'],
        redirectUrl: process.env.REACT_APP_DOMAIN_BASE_URL + '/back_office/api/auth/' + selectedProviderOption.value + '/callback'
      }));
    }
    if (selectedProviderOption.value === 'saml') {
      setInputConfig(prev =>({
        ...prev,
        callbackUrl: process.env.REACT_APP_DOMAIN_BASE_URL + '/back_office/api/auth/' + selectedProviderOption.value + '/callback',
        acceptedClockSkewMs: -1
      }));
    }
    setFileName('');
  }, [selectedProviderOption.value])
  
  const closeConfigModal = () => {
    openConfigModal(false);
  }

  const onInputChange = e => {
    const { name, value } = e.target;

    let newValue;
    switch (value) {
      case 'true':
        newValue = true;
        break;
      case 'false':
        newValue = false;
        break;
      default:
        if (value.includes('-----BEGIN CERTIFICATE-----')) {
          newValue = value.trim().replace(/-----BEGIN CERTIFICATE-----|-----END CERTIFICATE-----|\n/g, '');
        } else {
          newValue = value.trimStart();
        }
        break;
    }

    setInputConfig(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  const validateConfigObject = (obj, keysLength) => {
    const keys = Object.keys(obj);
    const keysValidation = keys.every(key => {
      return key.length <= 100;
    });

    const values = Object.values(obj);
    const valuesValidation = values.every(value => {
      if (Array.isArray(value)) return value.every(element => element.length <= 50) && value.length <= 10;
      else if (!Array.isArray(value) && typeof value === 'object') return false;
      else return String(value)?.length <= 5000
    });
  
    return keysValidation && valuesValidation && keys.length <= keysLength;
  }

  const maxConfigOdjKeysLength = Math.max(googleConfigOptions.length, azureConfigOptions.length,
    keycloakConfigOptions.length, oktaConfigOptions.length, samlConfigOptions.length
  );

  const createConfig = async (provider, protocol, config, fileName) => {
    closeModalConfigChange();
    if (validateConfigObject(config, maxConfigOdjKeysLength)) {
      try {
        const data = {
          provider,
          protocol,
          config,
          fileName
        }
        const ecryptedData = encryptData(data);
        await request('/back_office/api/user/sso_config', 'POST', { data: ecryptedData }, {
          Authorization: `Bearer ${token}`
        });

        setSelectedFile(null);
        setFileName('');

        return window.open(`${process.env.REACT_APP_DOMAIN_BASE_URL}/back_office/api/auth/verify_sso/${user.companyName}`, '_self');
      } catch (error) {
        console.log(error);
      }
    } else {
      showToastMessage('Configuration settings are not valid')
    }
  };

  const handleFileChange = (event) => {
    const maxSizeInBytes = 100 * 1024;
    const file = event.target.files[0];
    const fileName = event.target.files[0]?.name;
    if (file && file.size > maxSizeInBytes) {
      showToastMessage('The file exceeds the maximum size. Please select another file.')
      event.target.value = null;
      setSelectedFile(null);
      setFileName('')
    } else {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          const content = JSON.parse(e.target.result);

          if (content) {
            setFileContent(content);
            setSelectedFile(file);
            setFileName(fileName);
          } else {
            showToastMessage('The file could not be read. Please select another file.');
          }
        };
        reader.readAsText(file);
      } catch (error) {
        showToastMessage('The file could not be read. Please select another file.');
      }
    }
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleProviderSelect = (data) => {
    setSelectedProviderOption(data);
  };

  const handleProtocolSelect = (data) => {
    setSelectedProtocolOption(data);
    if (data.value === 'OAuth2.0') setSelectedProviderOption({ value: 'google', label: 'google' })
    if (data.value === 'SAML2.0') setSelectedProviderOption({ value: 'saml', label: 'saml' })
  };

  const protocolsOptionList = ssoProtocols.map((template) => (
    { value: template, label: template }
  ))

  const oauthProvidersOptionList = ssoOauthProviders.map((template) => (
    { value: template, label: template }
  ))

  const samlProvidersOptionList = ssoSamlProviders.map((template) => (
    { value: template, label: template }
  ))

  const loginUrl = encodeURI(`${process.env.REACT_APP_DOMAIN_BASE_URL}/back_office/login/${user.companyName}`);

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(loginUrl);

      showToastMessage(null, 'Login URL copied to the clipboard');
    } catch (error) {
      showToastMessage('Could not copy the Login URL');
    }
  }, [loginUrl, showToastMessage]);

  const handleCopy = (event) => {
    const selectedText = window.getSelection().toString();

    if (selectedText !== loginUrl) {
      event.preventDefault();
      showToastMessage('Please copy the Login URL completely!');
    }
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      setIsTextSelected(selection && selection.toString().length === loginUrl.length);
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [loginUrl]);

  useEffect(() => {
    isTextSelected && document.addEventListener('copy', handleCopyClick);
    return () => {
      document.removeEventListener('copy', handleCopyClick);
    };
  }, [handleCopyClick, isTextSelected]);

  useEffect(() => {
    switch (selectedProviderOption.value) {
    case 'google':
      setInputOptions(googleConfigOptions)
      break;
    case 'azuread-openidconnect':
      setInputOptions(azureConfigOptions)
      break;
    case 'keycloak':
      setInputOptions(keycloakConfigOptions)
      break;
    case 'okta':
      setInputOptions(oktaConfigOptions)
      break;
    case 'saml':
      setInputOptions(samlConfigOptions)
      break;
    default:
      break;
    }
  }, [selectedProviderOption.value]);

  const setFileMannualy = (fileName, fileContent) => {
    const name = fileName + '-config.json';
    setFileName(name);

    Object.keys(fileContent).forEach(key => {
      if (fileContent[key] === null || fileContent[key] === "") {
        delete fileContent[key];
      }
    });

    setFileContent(fileContent);
    closeConfigModal();
  }

  const Option = (props) => {
    const { value } = props.data;
  
    return (
      <components.Option {...props}>
        <div className='sso-config-label'>{value}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    const cookieData = getCookie(storageName);
    const decodedCookieData = decodeURIComponent(cookieData);

    if (decodedCookieData) {
      removeCookie(storageName);
      const userData = JSON.parse(decodedCookieData);

      showToastMessage(null, userData.message, userData.warning);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cookieData = getCookie('sso_verified_test');
    const decodedCookieData = decodeURIComponent(cookieData);
    removeCookie('sso_verified');

    if (decodedCookieData) {
      removeCookie('sso_verified_test');
      const data = decryptData(JSON.parse(decodedCookieData));

      showToastMessage(data.error, data.message, data.warning);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModalConfigChange = () => {
    setModalChangeConfigIsOpen(true);
  }
  const closeModalConfigChange = () => {
    setModalChangeConfigIsOpen(false);
  }

  return (
    <div className='sso-config-card'>
      {loading
        ? <Loader />
        : <>
            <h3>SSO Setup & Configuration</h3>
            {(card === 1 || card === 2) &&
              <p>
                WARNING: Turning on SSO will change your login URL.<br />
                Reference the setup documentation for more information
              </p>
            }
            {card === 1 &&
            <div>
              {user?.paymentPlanId !== 4 && 
                <CustomizedTooltipDisabled
                  position={'right'}
                  text1={'This feature is available'}
                  text2={'in the Enterprise plan'}
                />
              }
              {user?.paymentPlanId === 4 && dateExpired && 
                <CustomizedTooltipDisabled
                  position={'right'}
                  text1={'Your Enterprise plan'}
                  text2={'has expired'}
                />
              }
              <button
                type="button"
                className="sso-config-button-set"
                onClick={() => setCard(2)}
                disabled={user.paymentPlanId !== 4 || dateExpired || user.role !== 'owner'}
              >
                Set up configuration
              </button>
            </div>
            }
            {card === 2 &&
              <form>
                <span className='sso-config-label-span'>Protocol</span>
                <Select
                  id='select'
                  className='sso-config-select'
                  options={protocolsOptionList}
                  components={{ Option }}
                  placeholder="Select..."
                  defaultValue={{ value: 'OAuth2.0', label: 'OAuth2.0' }}
                  value={selectedProtocolOption}
                  onChange={handleProtocolSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                />
                
                <span className='sso-config-label-span'>Provider</span>
                <Select
                  id='select'
                  className='sso-config-select'
                  options={
                    (selectedProtocolOption.value === 'OAuth2.0' && oauthProvidersOptionList)
                    || (selectedProtocolOption.value === 'SAML2.0' && samlProvidersOptionList)
                  }
                  components={{ Option }}
                  placeholder="Select..."
                  defaultValue={{ value: config?.provider || 'google', label: config?.provider || 'google' }}
                  value={selectedProviderOption}
                  onChange={handleProviderSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                />

                <div className="attach-button-container">
                  <div>
                    <button className='attach-button' onClick={handleUploadClick}>
                      {selectedFile ? <span>{selectedFile.name}</span> : 'Upload config file (*.json)'}
                    </button>
                    <input
                      type="file"
                      accept=".json"
                      ref={inputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {selectedFile &&
                      <button
                        onClick={() => {
                          setSelectedFile(null);
                          setFileName('');
                        }}
                        type='button'
                        className='attach-button-cross'
                      />
                    }
                  </div>

                  {!selectedFile &&
                    <>
                      <div>OR</div>
                      <div>
                        <button type="button" className='attach-button-manually' onClick={() => openConfigModal(true)}>
                          {fileName ? <span>{fileName}</span> : 'Enter config manually'}
                        </button>
                      </div>
                    </>
                  }
                </div>
                
                <div className='sso-config-actions'>
                  <button
                    type='button'
                    onClick={() => config
                      ? openModalConfigChange()
                      : createConfig(selectedProviderOption.value, selectedProtocolOption.value, fileContent, fileName)
                    }
                    className="sso-config-button-submit"
                    disabled={!fileName}
                  >
                    Submit
                  </button>
                  <button
                    type='button'
                    className="sso-config-button-cancel"
                    onClick={() => {
                      setCard(config ? 3 : 1)
                      setSelectedFile(null);
                      setFileName('')
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            }
            {card === 3 && config &&
              <div>
                <div className={'sso-select'}>
                  <p>Log In URL</p>
                  <div onCopy={handleCopy} className={'sso-select-background'}>
                    {loginUrl}
                    <img onClick={handleCopyClick} src={copy} alt='copy'></img>
                  </div>
                </div>
                <div className="sso-config-details">
                  <div>
                    <span>Provider</span>
                    <p>{config.provider}</p>
                  </div>
                  <div>
                    <span>Protocol</span>
                    <p>{config.protocol}</p>
                  </div>
                  <div>
                    <span>Config</span>
                    <p>{config.fileName}</p>
                  </div>
                </div>
                <div>
                {user?.paymentPlanId !== 4 &&
                  <CustomizedTooltipDisabled
                    position={'right'}
                    text1={'This feature is available'}
                    text2={'in the Enterprise plan'}
                  />
                }
                {user?.paymentPlanId === 4 && dateExpired &&
                  <CustomizedTooltipDisabled
                    position={'right'}
                    text1={'Your Enterprise plan'}
                    text2={'has expired'}
                  />
                }
                <button
                    type="button"
                    className="sso-config-button-set"
                    onClick={() => setCard(2)}
                    disabled={user.paymentPlanId !== 4 || dateExpired || user.role !== 'owner'}
                  >
                    Change configuration
                  </button>
                </div>
              </div>
            }
          </>
        }

        <Modal
          isOpen={configModal}
          onRequestClose={closeConfigModal}
          style={customModalStyles}
          contentLabel="Example Modal"
        >
          <form className="form-sso-edit">
            <div className="content-sso-edit">
              <span className="card-title">Set up config</span> <br></br>
              <div className="card-content">
                {inputOptions.map(o => {
                  const option = Object.entries(o)[0];
                  const key = option[0];
                  const value = option[1];

                  return (
                    <>
                      {key === 'clientSecret'
                        ? <div className="input-field-sso">
                            <img
                              src={!secretShown ? eye : closeEye}
                              alt='Eye' className='eye-sso'
                              onClick={() => setSecretShown(!secretShown)}
                            />
                            <label
                              htmlFor={key}
                              className={cn("input-label", {
                                "input-label-required": value
                              })}
                            >
                              {key}
                            </label>
                            <input
                              id={key}
                              type={secretShown ? "text" : "password"}
                              name={key}
                              placeholder={key
                                .replace(/([a-z])([A-Z])/g, '$1 $2')
                                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
                                .split(/\s+/)
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ") + '...'
                              }
                              className={"input-sso-edit"}
                              value={inputConfig[key]}
                              onChange={onInputChange}
                              autoComplete="new-password"
                            />
                          </div>
                        : <div className="input-field-sso">
                            <label
                              htmlFor={key}
                              className={cn("input-label", {
                                "input-label-required": value
                              })}
                            >
                              {key}
                            </label>
                            <input
                              id={key}
                              type="text"
                              name={key}
                              placeholder={key
                                .replace(/([a-z])([A-Z])/g, '$1 $2')
                                .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
                                .split(/\s+/)
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ") + '...'
                              }
                              className={"input-sso-edit"}
                              value={inputConfig[key]}
                              onChange={onInputChange}
                              autoComplete="new-password"
                              disabled={key === 'scope'}
                              required={value}
                            />
                          </div>
                      }
                    </>
                  )
                })}
              </div>
            </div>
            <div className="sso-card-action-modal">
              <button
                type='button'
                onClick={() => setFileMannualy(selectedProviderOption.value, inputConfig)}
              >
                Save
              </button>
              <button
                type='button'
                onClick={closeConfigModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={modalChangeConfigIsOpen}
          onRequestClose={closeModalConfigChange}
          style={customStyles}
          contentLabel="Example Modal"
          closeTimeoutMS={200}
        >
          <button onClick={closeModalConfigChange} className="modal-close"></button>
          <form className="form">
            <div className="title">
              <span className="card-title ">
                Change SSO config
              </span><br/>
            </div>

            <div className='remove-form'>
              <p>
                WARNING:<br/>
              </p>
              <p>
                This action will delete the existing configuration and save the new settings.
                After that, you will be redirected to the authentication page to the portal you specified in the configuration.
                Only after successful authentication will the new configuration take effect.
                If you fail to authenticate, the saved previous configuration will be deleted permanently.
                Do you really want to change the settings?
              </p>
            </div>
            
            <div className="card-action-modal-remove">
              <button
                type='button'
                onClick={() => createConfig(selectedProviderOption.value, selectedProtocolOption.value, fileContent, fileName)}
              >
                Yes, save changes
              </button>
              <button
                type='button'
                onClick={closeModalConfigChange}
              >
                No, I'll think about it
              </button>
            </div>
          </form>
        </Modal>
    </div>
  )
};
