import React, { useState, useContext, useEffect, useCallback } from "react";
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { SyncLog } from "../SyncLog/SyncLog";
import { Loader } from "../Loader/Loader";
import { NavLink } from 'react-router-dom';
import edit from '../.././images/icons/Pen.svg';
import './CsvUpload.css'
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip.js';

export const CsvUploadDetails = () => {
  const { token } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { request, loading } = useHttp();

  const [tenants, setTenants] = useState([]);

  const getTenants = useCallback(async () => {
    try {
      if (token) {
        const tenants = await request('/back_office/api/user/csv_tenants', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setTenants(tenants);
        
        return tenants;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getTenants();
  }, [getTenants]);

  return (
    <>
      <div className="details-card">
        <div className="details-action-csv">
          <div>
            <h3 className="details-action-title">
              CSV Sync details
            </h3>
          </div>
        </div>

        {tenants?.map((tenant) => (
          <ul className="details-account-list" key={tenant.id}>
            <li className="details-wide-item-csv">
              Tenant name
              <p className="details-value">{tenant.name}</p>
            </li>
            {tenant.language && <li className="details-wide-item-csv">
              Language
              <p className="details-value">{tenant.language}</p>
            </li>}
            <li className="details-item-csv details-item-button">
              <NavLink
                to={`/back_office/user/users_database/csv/edit/${tenant.id}`}
                className="button-link"
              >
                <button>
                  <img src={edit} alt="edit"></img>
                  Edit
                </button>
              </NavLink>
            </li>
          </ul>
        ))}

        {(!user?.syncCSV || (!user?.tenants?.syncAccess && user?.role !== 'owner')) ?
          <CustomizedTooltipDisabled
            position={'left'}
            text1={'The Owner did not'}
            text2={'give you permission'}
            text3={'to set up a synchronization'}
            button={<NavLink
              to="/back_office/user/users_database/csv/create"
              className="edit-submit"
            >
              <button className="button-submit" disabled={true}>+ Add one more tenant</button>
            </NavLink>}
          />
          : <NavLink
            to="/back_office/user/users_database/csv/create"
            className="edit-submit"
          >
            <button className="button-submit">+ Add one more tenant</button>
          </NavLink>
        }

      </div>
      {loading
        ? <Loader />
        : <SyncLog activeDirectory={false} csv={true} syncType={'csv'}/>
      }
    </>
  );
}
