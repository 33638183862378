export const getSubjectFromDescription = (description = '') => {
  const regex = /Title: (.+)/;
  const match = description.match(regex);

  if (match && match[1]) return match[1];
  else return '';
};

export const getDateFromDescription = (description = '') => {
  const regex = /Time Reported: (.+)/;
  const match = description.match(regex);

  if (match && match[1]) return match[1];
  else return '';
};