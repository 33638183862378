import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { NavLink } from 'react-router-dom';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import { config } from './../common/config';
import eye from '.././images/icons/Eye.svg';
import closeEye from '.././images/icons/Eye Slash.svg';
import cn from 'classnames';
import { storageName } from '../config/config';
import { getCookie } from '../common/cookieHandler';

export const Register = () => {
  useTitle("Welcome to PhishFirewall - Get Started Today FREE");

  const { login, showToastMessage } = useContext(AuthContext)
  const { request, loading } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const { blockedServices } = config;

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    company: '',
    password: '',
    confirmPassword: '',
    isSSOacc: false,
  });

  const [inputError, setError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    password: '',
    company: '',
    confirmPassword: '',
    userPhoto: '',
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const [card, setCard] = useState(1);
  const [isSSO, setIsSSO] = useState(false);
  const [maxTelLength, setMaxTelLength] = useState(17);

  const { companyName } = useParams();
  const navigate = useNavigate();

  const SSOAuth = () => (
    window.open(`${process.env.REACT_APP_DOMAIN_BASE_URL}/back_office/api/auth/${companyName}`, '_self')
  );

  const getCompany = useCallback( async () => {
    const res = await request(`/back_office/api/auth/sso_by_company/${companyName}`, 'GET');
    if (res.error) {
      showToastMessage(res.error);
      navigate('/back_office/register');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, navigate, request, showToastMessage]);
  
  useEffect(() => {
    if (companyName) getCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName])

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onInputChange = e => {
    const { name, value, country, formattedValue } = e.target;

    if (country?.dialCode === '226') {
      setIsPhoneValid(country?.format.length === (formattedValue?.length + 8)
        && (value.startsWith(country?.dialCode) || country?.dialCode.startsWith(value) || country?.dialCode !== value)
      );
    } else {
      setIsPhoneValid(country?.format.length === formattedValue?.length
        && (value.startsWith(country?.dialCode) || country?.dialCode.startsWith(value) || country?.dialCode !== value)
      );
    }

    setInput(prev => ({
      ...prev,
      [name]: value.trimStart()
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value, country, formattedValue } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter your First Name";
          } else if (!value.match(/^[\w- .]{1,50}$/)) {
            stateObj[name] = "This First Name is not valid";
          }
          break;
        case "lastName":
          if (!value) {
            stateObj[name] = "Please enter your Last Name";
          } else if (!value.match(/^[\w- .]{1,50}$/)) {
            stateObj[name] = "This Last Name is not valid";
          }
          break;
        case "email":
          if (!value) {
            stateObj[name] = "Please enter email";
          } else if (blockedServices.includes('@' + value.split('@')[1])) {
            stateObj[name] = "Enter a valid business email address";
          } else if (!value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
            stateObj[name] = "This email address is not valid";
          }
          break;

        case "tel":
          if (!value) {
            stateObj[name] = "Please enter your phone number";
          }
          else if (country?.format.length !== formattedValue?.length
            && (!value.startsWith(country?.dialCode) || !country?.dialCode?.startsWith(value) || country.dialCode === value)
            && country?.dialCode !== '226'
          ) {
            stateObj[name] = "Enter a valid phone number";
          }
          else if (country?.format.length !== (formattedValue?.length + 8)
            && (!value.startsWith(country?.dialCode) || !country?.dialCode?.startsWith(value) || country.dialCode === value)
            && country?.dialCode === '226'
          ) {
            stateObj[name] = "Enter a valid phone number";
          }
          break;

        case "company":
          if (!value) {
            stateObj[name] = "Please enter company name";
          } else if (!value.match(/^[\w-& .,/]{1,50}$/)) {
            stateObj[name] = "This company name is not valid";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match";
          } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{12,50}$/)) {
            stateObj[name] = "The password must contain lowercase letters, uppercase letters, numbers and be at least 12 characters long";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : inputError.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  const validateTelInput = (value, isPhoneValid) => {
    setError(prev => {
      const stateObj = { ...prev, tel: "" };
      if (!value) {
        stateObj.tel = "Please enter your phone number";
      }
      else if (!isPhoneValid) {
        stateObj.tel = "Enter a valid phone number";
      }

      return stateObj;
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
  };

  const registerHandler = async (e) => {
    try {
      const data = encryptData(input);

      const response = await request('/back_office/api/auth/register', 'POST', { data })
      showToastMessage(response.error, response.message);

      if (!response.error) {
        const res = await request('/back_office/api/auth/login', 'POST', { data })
        const decryptRes = decryptData(res);

        login(decryptRes.token, decryptRes.userId);
      }
    } catch (error) { console.log(error); }
  }

  useEffect(() => {
    if (input.firstName === '') {
      const cookieData = getCookie(storageName);
      const decodedCookieData = decodeURIComponent(cookieData);

      if (decodedCookieData) {
        const userData = JSON.parse(decodedCookieData);

        if (userData.email && userData.firstName && userData.lastName) {
          setInput({
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            password: String(new Date()),
            confirmPassword: String(new Date()),
            isSSOacc: true,
            userPhoto: userData.photo
          })
          setCard(2)
          setIsSSO(true)
        }
      }
    }
    if (isPhoneValid === undefined) {
      setIsPhoneValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneValid]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="title">
        <span className="card-title">Welcome to PhishFirewall!</span><br />
        <span className="card-subtitle">
          Sign up today to get started with our Free Security Awareness Training
        </span>
      </div>

      {card === 1 &&
        <>
          <div className="card-content">
            <div className="input-field">
              <label
                htmlFor="firstName"
                className="input-label input-label-required"
              >
                First name
              </label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                placeholder='Your First name...'
                className={cn("input", "validate", { "err-input": inputError.firstName })}
                value={input.firstName}
                onChange={onInputChange}
                disabled={isSSO}
                onBlur={validateInput}></input>
              {inputError.firstName && <span className='err'>{inputError.firstName}</span>}
            </div>

            <div className="input-field">
              <label
                htmlFor="lastName"
                className="input-label input-label-required"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                placeholder='Your Last name...'
                className={cn("input", "validate", { "err-input": inputError.lastName })}
                value={input.lastName}
                onChange={onInputChange}
                disabled={isSSO}
                onBlur={validateInput}></input>
              {inputError.lastName && <span className='err'>{inputError.lastName}</span>}
            </div>

            <div className="input-field">
              <label
                htmlFor="email"
                className="input-label input-label-required"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder='Your email...'
                className={cn("input", "validate", { "err-input": inputError.email })}
                value={input.email.trim()}
                onChange={onInputChange}
                disabled={isSSO}
                onBlur={validateInput}></input>
              {inputError.email && <span className='err'>{inputError.email}</span>}
            </div>
          </div>

          <div className="card-action card-action-auth">
            <button
              className={cn('next-register-button', {
                "card-action-auth-last": !companyName
              })}
              onClick={() => setCard(2)}
              disabled={!input.firstName || !input.lastName
                || !input.email || inputError.email
                || inputError.firstName || inputError.lastName
              }
              type="button"
            >
              Next
            </button>
          </div>
        </>
      }

      {card === 2 &&
        <>
          <div className='back-register'>
            <button
              className='back-register-button'
              onClick={() => setCard(1)}
              type="button"
            >
              Back
            </button>
          </div>
          <div className='back-register'>
            <h3>Set up your account details</h3>
          </div>
          <div className="card-content">
            <div className="input-field">
              <label
                htmlFor="email"
                className="input-label input-label-required"
              >
                Company name
              </label>
              <input
                id="company"
                type="text"
                name="company"
                placeholder='Your company name...'
                className={cn("input", "validate", { "err-input": inputError.company })}
                value={input.company}
                onChange={onInputChange}
                onBlur={validateInput}></input>
              {inputError.company && <span className='err'>{inputError.company}</span>}
            </div>
            <div className="input-field">
              <label
                htmlFor="tel"
                className="input-label input-label-required"
              >
                Phone number
              </label>
              <PhoneInput
                inputProps={{
                  maxLength: maxTelLength
                }}
                enableLongNumbers={true}
                country={'us'}
                inputClass={cn("input", "validate", { "err-input": inputError.tel })}
                dropdownClass={"input-dropdown"}
                id="tel"
                type="tel"
                name="tel"
                value={input.tel}
                required={true}
                onChange={(value, country, event, formattedValue) => {
                  setMaxTelLength(country.format.length)
                  const e = {
                    target: {
                      name: "tel",
                      value,
                      country,
                      event,
                      formattedValue
                    }
                  }
                  onInputChange(e)
                }}
                onBlur={(value) => {
                  validateTelInput(value, isPhoneValid)
                }}
              />
              {inputError.tel && <span className='err'>{inputError.tel}</span>}
            </div>
          </div>

          <div className="card-action">
            <button
              className='next-register-button'
              onClick={!isSSO ? () => setCard(3) : registerHandler}
              disabled={!input.company || !input.tel || inputError.company || inputError.tel}
              type="button"
            >
              {!isSSO ? "Next" : "Start Training Now"}
            </button>
          </div>
        </>
      }
      {card === 3 && !isSSO &&
        <>
          <div className='back-register'>
            <button
              className='back-register-button'
              onClick={() => setCard(2)}
              type="button"
            >
              Back
            </button>
          </div>
          <div className='back-register'>
            <h3>Set up your password</h3>
          </div>
          <div>
            <div className="input-field">
              <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
              <label
                htmlFor="password"
                className="input-label input-label-required"
              >
                Password
              </label>
              <input
                id="password"
                type={passwordShown ? "text" : "password"}
                name="password"
                placeholder='Create your password...'
                className={cn("input", "validate", { "err-input": inputError.password })}
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}></input>
              {inputError.password && <span className='err'>{inputError.password}</span>}
            </div>

            <div className="input-field">
              <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
              <label
                htmlFor="confirmPassword"
                className="input-label input-label-required"
              >
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type={passwordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder='Confirm your password...'
                className={cn("input", "validate", { "err-input": inputError.confirmPassword })}
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}></input>
              {inputError.confirmPassword && <span className='err'>{inputError.confirmPassword}</span>}
            </div>

            <div className="checkbox-action"></div>
          </div>

          <div className="card-action">
            <button
              className='last-register-button'
              onClick={registerHandler}
              disabled={
                !input.password || !input.confirmPassword ||
                inputError.password || inputError.confirmPassword ||
                loading
              }
              type="submit"
            >
              Start Training Now
            </button>
          </div>
        </>
      }

      {card === 1 && !!companyName &&
        <>
          <p className='card-action-or'>OR SIGN UP WITH</p>
          <button
            className='sso-button'
            type="button"
            onClick={SSOAuth}
          >
            SSO
          </button>
        </>
      }
      <div className='signup-action'>
        <span>
          Already have an account?&nbsp;
        </span>
        <NavLink
          to="/back_office/login"
          className="signin-navlink-auth"
        >
          Log In
        </NavLink>
      </div>
    </form>
  );
}
