import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { useAuth } from '../../../hooks/auth.hook';
import { AuthContext } from '../../../context/AuthContext';
import { useSortableData } from '../../../hooks/sort.hook';
import { getClassNamesFor } from '../../../common/getClassNamesFor';
import button from '../../..//images/icons/Button.svg'
import { useCrypto } from '../../../hooks/crypto.hook';
import ReactSelect from '../../../components/ReactSelect/ReactSelect';
import { AllUsersOption } from '../../../components/ReactSelect/ReactSelectOption';
import './Users.css';

export const Users = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData} = useCrypto();
  const auth = useContext(AuthContext)

  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [card, setCard] = useState(1);
  const [addCard, setAddCard] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1);

  const { items, requestSort, sortConfig } = useSortableData(users);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(fetched);
        setAllUsers(decryptUsers);
  
        return decryptUsers;
      }
      
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);
  
  const fetchUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        
        const decryptUsers = decryptData(fetched);
        setUsers(decryptUsers)
  
        return decryptUsers;
      }
      
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const submitHandler = async (e) => {
    try {
      const data = encryptData(selectedOptions);
      const response = await request('/back_office/api/exclusions/users', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setSelectedOptions([])
      setSelectedRow(-1)
      setCard(3);
      setAddCard(false);
      fetchUsers()
      fetchAllUsers()

    } catch (error) {}
  }

  const deleteUser = async () => {
    try {
      const data = encryptData(selectedRow);
      const response = await request('/back_office/api/exclusions/users', 'DELETE', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setCard(3)
      fetchUsers()
      fetchAllUsers()

    } catch (error) {}
  }

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);
  
  useEffect(() => {
    fetchUsers()
  }, [fetchUsers]);

  useEffect(() => {
    if (!users?.length) setCard(1)
    else setCard(3)
  }, [users?.length])

  if (loading) {
    return <Loader />
  }

  const optionList = allUsers.map(user => (
    { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName }
  ));

  return (
    <> {!loading &&
        <div className="users-main">
          {card === 1 &&
            <div>
              <p className="users-description">
                There are no exclusions yet.
              </p>
              <button
                className='users-button'
                onClick={() => setCard(2)}
                disabled={loading}
                type="submit"
              >
                Add users
              </button>
            </div>
          }

          {card === 2 &&
            <div>
              <p className="users-add-title">
                Add user
              </p>

              <div className='all-users-select-multi'>
                <label className='userslist-select-label'>
                  <ReactSelect
                    classNames={'multiselect-user'}
                    options={optionList}
                    optionComponent={AllUsersOption}
                    placeholder={'Select users'}
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti={true}
                    optionHeight={50}
                    rows={6}
                  />
                </label>
              </div>
              <button
                className='users-button users-button-simple'
                onClick={submitHandler}
                disabled={!selectedOptions?.length}
                type="submit"
              >
                Add users
              </button>
            </div>
          }

          {card === 3 &&
            <div>
              {!addCard
                ?
                  <div className='users-actions'>
                    <p className="audit-desc">Exclusion list of users</p>
                    <button
                      className='users-button'
                      onClick={() => {setAddCard(!addCard); setSelectedRow(-1)}}
                      type="submit"
                    >
                      Add users
                    </button>
                  </div>
                :
                  <div className="users-add-actions">
                  <p className="users-add-title">
                    Add user
                  </p>

                  <div className={'all-users-select-multi'}>
                    <label className='userslist-select-label'>
                      <ReactSelect
                        classNames={'multiselect-user'}
                        options={optionList}
                        optionComponent={AllUsersOption}
                        placeholder={'Select users'}
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti={true}
                        optionHeight={50}
                        rows={6}
                      />
                    </label>
                  </div>
                  <button
                    className='users-button users-button-simple'
                    onClick={submitHandler}
                    disabled={!selectedOptions?.length}
                    type="submit"
                  >
                    Add users
                  </button>

                  <p className='users-add-subtitle'>Exclusion list of users</p>
                </div>
              }

              <div className="audit table">
                <table className="users-table">
                  <thead className="table-th">
                    <tr className="users-not-clickable users-tr">
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          User name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          Email
                        </button>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {items.map((u) => (
                      <tr
                        key={u.id}
                        onClick={() => setSelectedRow(u.id)}
                        className={"users-clickable-row users-tr ".concat(selectedRow === u.id ? "users-selected" : "")}
                      >
                        <td>{u?.firstName} {u?.secondName}</td>
                        <td className='td-email'>{u.email}</td>
                        <td>
                          <div className='delete-container'>
                            <button
                              type='button'
                              className='button-delete'
                              onClick={deleteUser}
                              disabled={selectedRow !== u.id}
                            >
                              <img src={button} alt='btn'></img>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        }
    </>
  )
}
