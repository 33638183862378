import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CategoriesSummaryFilterBar } from './CategoriesSummaryFilterBar';
import { useHttp } from '../../hooks/http.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import { AllMandatoryTrainingUsers } from './AllMandatoryTrainingUsers';

export const CategoriesSummary = () => {
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [initialTenants, setInitialTenants] = useState([]);
  const [initialDepartments, setInitialDepartments] = useState([]);
  const [initialLocations, setInitialLocations] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectSequence, setSelectSequence] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({ id: 0, value: 0, label: 'All Trainings' });
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchGeneralData = useCallback(async (selectedTenant, selectedDepartment, selectedLocation) => {
    try {
      if (token) {
        const data = encryptData({
          tenantId: selectedTenant?.value,
          department: selectedDepartment?.value,
          location: selectedLocation?.value
        })
        const categoriesGeneralData = await request('/back_office/api/categories/general_data', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });
        const decryptCatogoriesGeneralData = decryptData(categoriesGeneralData);

        !selectedTenant && setInitialTenants(decryptCatogoriesGeneralData?.tenants);
        !selectedDepartment && setInitialDepartments(decryptCatogoriesGeneralData?.departments);
        !selectedLocation && setInitialLocations(decryptCatogoriesGeneralData?.locations);

        (selectSequence === 'tenant' || selectedTenant) && (!selectedDepartment && !selectedLocation)
          ? setTenants(initialTenants)
          : setTenants(decryptCatogoriesGeneralData?.tenants);

        (selectSequence === 'department' || selectedDepartment) && (!selectedLocation && !selectedTenant)
          ? setDepartments(initialDepartments)
          : setDepartments(decryptCatogoriesGeneralData?.departments);

        (selectSequence === 'location' || selectedLocation) && (!selectedDepartment && !selectedTenant)
          ? setLocations(initialLocations)
          : setLocations(decryptCatogoriesGeneralData?.locations);

        setUsers(decryptCatogoriesGeneralData?.users);
        setCategories(decryptCatogoriesGeneralData?.categories);

        return decryptCatogoriesGeneralData;
      }
    } catch (error) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, selectedDepartment, selectedLocation]);

  useEffect(() => {
    fetchGeneralData(selectedTenant, selectedDepartment, selectedLocation);
  }, [fetchGeneralData, selectedDepartment, selectedLocation, selectedTenant]);

  return (
    <>
      <CategoriesSummaryFilterBar
        tenants={tenants}
        departments={departments}
        locations={locations}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        loading={loading}
        selectSequence={selectSequence}
        setSelectSequence={setSelectSequence}
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      <AllMandatoryTrainingUsers
        selectedTenant={selectedTenant}
        selectedDepartment={selectedDepartment}
        selectedLocation={selectedLocation}
        users={users}
        selectedCategory={selectedCategory}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}
