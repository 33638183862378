import React, { useCallback } from 'react';
import cn from 'classnames';
import { usePagination, dots } from '../../hooks/paginate.hook';
import './Pagination.css';

export const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const onNext = useCallback(() => {
    if (currentPage < paginationRange[paginationRange.length - 1]) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, onPageChange, paginationRange]);

  const onPrevious = useCallback(() => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={cn('pagination-container', { [className]: className })}>
      <ul>
        <li
          className={cn('pagination-item', { 'pagination-item--disabled': currentPage === 1 })}
          onClick={onPrevious}
          role='button'
        >
          <div className="pagination-arrow pagination-arrow--left" />
        </li>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === dots) {
              return <li key={`dots-${index}`} className="pagination-item dots">&#8230;</li>;
            }

            return (
              <li
                key={pageNumber}
                className={cn('pagination-item', { 'pagination-item--selected': pageNumber === currentPage })}
                onClick={() => onPageChange(pageNumber)}
                role='button'
              >
                {pageNumber}
              </li>
            );
          })}
        <li
          className={cn('pagination-item', { 'pagination-item--disabled': currentPage === lastPage })}
          onClick={onNext}
          role='button'
        >
          <div className="pagination-arrow pagination-arrow--right" />
        </li>
      </ul>
    </div>
  );
};
