import React, { useContext } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { customModalStyles, validateDateScheduling } from '../../config/config';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import './SetSchedulerModal.css';

export const SetSchedulerModal = ({ isOpen, onRequestClose, editTenant, setEditTenant, callback }) => {
  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
  const nextMonth = moment().add(1, 'month').format('YYYY-MM-DD');

  const { encryptData } = useCrypto();
  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading } = useHttp();

  const updateTenant = async (editTenant) => {
    try {
      onRequestClose();
      const data = encryptData(editTenant);
      const res = await request('/back_office/api/user/schedule_tenant', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);

      if (callback) callback();
    } catch (error) { }
  };

  return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Scheduler Modal"
        style={customModalStyles}
      >
        <form className="scheduler-form">
          <div className="scheduler-content">
            <span className="card-title">Set the Launch Date</span>
            <span className="card-subtitle">Tenant: <strong>{editTenant?.name} ({editTenant?.type})</strong></span>
            <div className='scheduler-input-container'>
              <label className='scheduler-input-label'>
                Schedule the Launch:
              </label>
              <input
                type="date"
                value={editTenant.scheduledLaunch}
                min={tomorrow}
                max={nextMonth}
                className="input-edit-scheduler"
                onChange={(e) => setEditTenant(prev => ({
                  ...prev,
                    scheduledLaunch: e.target.value
                }))}
              />
            </div>
          </div>
          <div className="scheduler-card-action-modal">
            <button
              type='button'
              onClick={() => updateTenant(editTenant)}
              disabled={loading || (!validateDateScheduling(editTenant.scheduledLaunch, tomorrow, nextMonth) && editTenant.scheduledLaunch)}
            >
              Save changes
            </button>
            <button
              type='button'
              onClick={onRequestClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
  );
};
