module.exports = {
  storageName: 'PhishFirewallStorage',
  activaTabTime: 'activeTab',
  lastVisitedUrl: 'lastVisitedUrl',
  itemsOnPage: 25,
  groupsOnPage: 10,
  maxUsers: 500,
  autoLogoutMinutes: 30,
  ssoProtocols: ['OAuth2.0', 'SAML2.0'],
  ssoOauthProviders: [
    'google',
    'keycloak',
    'azuread-openidconnect',
    'okta'
],
  ssoSamlProviders: [
    'saml'
  ],
  roles: [{value: 'tenant-admin', name: 'Tenant Admin'}, {value: 'admin', name: 'Admin'}],
  syncOptions: [{value: 'azure', label: "Azure Active Directory", id: 1 }, { value: "google", label: "Google Workspace", id: 2 }],
  languageOptions: ['English', 'French', 'Spanish', 'German', 'Italian', 'Arabic', 'Hindi', 'Chinese', 'Japanese', 'Turkish', 'Portuguese'],
  customModalStyles: {
    content: {
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.15)',
      borderRadius: '4px',
      overflow: 'visible'
    }
  },
  googleConfigOptions: [
    {clientID: 'required'}, {clientSecret: 'required'},
    {callbackURL: 'required'}, {scope: false}
  ],
  keycloakConfigOptions: [
    {clientID: 'required'}, {clientSecret: 'required'},
    {callbackURL: 'required'}, {issuer: 'required'},
    {tokenURL: 'required'}, {userInfoURL: 'authorizationURL'},
    {scope: false},
  ],
  azureConfigOptions: [
    {clientID: 'required'}, {redirectUrl: 'required'},
    {identityMetadata: 'required'}, {responseType: 'required'},
    {responseMode: 'required'}, {clientSecret: false},
    {scope: false}, {thumbprint: false},
    {passReqToCallback: false}, {allowHttpForRedirectUrl: false},
    {privatePEMKey: false}, {isB2C : false},
    {validateIssuer: false}, {issuer: false},
    {jweKeyStore: false}, {useCookieInsteadOfSession: false},
    {cookieEncryptionKeys : false}, {loggingLevel: false},
    {loggingNoPII: false}, {nonceLifetime: false},
    {nonceMaxAmount: false}, {clockSkew: false}
  ],
  oktaConfigOptions: [
    {clientID: 'required'}, {clientSecret: 'required'},
    {callbackURL: 'required'}, {issuer: 'required'},
    {authorizationURL: 'required'},
    {tokenURL: 'required'}, {userInfoURL: 'authorizationURL'},
    {scope: false},
  ],
  samlConfigOptions: [
    {entryPoint: 'required'}, {issuer: 'required'},
    {callbackUrl: 'required'}, {cert: 'required'},
    {protocol: false}, {host: false},
    {audience: false}, {privateKey: false},
    {decryptionPvk: false}, {signatureAlgorithm: false},
    {digestAlgorithm: false}, {xmlSignatureTransforms: false},
    {identifierFormat: false}, {acceptedClockSkewMs: false},
    {maxAssertionAgeMs: false}
  ],
  validateDateScheduling: (date, minDate, maxDate) => (date >= minDate && date <= maxDate),
  dateRangeOptions: [
    { value: 29, label: 'Last 30 days' },
    { value: 89, label: 'Last 90 days' },
    { value: 179, label: 'Last 180 days' },
    { value: 364, label: 'Last 365 days' },
    { value: 0, label: 'All time' }
  ],
  dateRangeOptionsAnalytics: [
    { value: 29, label: 'Last 30 days' },
    { value: 89, label: 'Last 90 days' },
    { value: 179, label: 'Last 180 days' },
    { value: 364, label: 'Last 365 days' },
    { value: 0, label: 'All time' },
    { value: -1, label: 'Custom range' }
  ],
  minFasttrackSendingDuration: 7,
  maxFasttrackSendingDuration: 90,
  defaultFasttrackSendingDuration: 30,
  minPhishEmailPerDays: 7,
  maxPhishEmailPerDays: 120,
  minNextEmailDelay: 1,
  maxNextEmailDelay: 30,
  defaultNextEmailDelay: 14,
  defaultFasttrackNextEmailDelay: 7,
  minResendingEmailCount: 0,
  maxResendingEmailCount: 7,
  lastThirtyDaysChunk: 29,
  sysAdminAccessCompanyIds: ['72834656-7859-473d-9040-0f5eedd29098'],
  riskLevelOptions: [
    { value: 'Low Risk', label: 'Low', id: 1 },
    { value: 'Moderate Risk', label: 'Moderate', id: 2 },
    { value: 'High Risk', label: 'High', id: 3 }
  ],
  csvValidationMaxValueLength: 64,
};
