import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';
import { CustomizedTooltipDescription } from '../../components/Tooltips/Tooltip';
import { AuthContext } from '../../context/AuthContext';
import cn from 'classnames';
import './MembershipPlan.css';

export const MembershipPlanMain = () => {
  useTitle("PhishFirewall | Membership Plan");

  const { loading } = useHttp();
  const { user } = useContext(AuthContext);

  return (
    <> {!loading &&
        <div className="plan-main">
          <h2 className="plan-main-title">
            Membership plan
          </h2>

          <nav className="plan-nav">
            {user?.role === 'owner'
              ? <NavLink
                  to="/back_office/user/membership_plan/plans"
                  className={({ isActive }) => cn('plan-item',
                    { 'plan-item--active': isActive })}
                >
                  Plans & Pricing
                </NavLink>
              : <CustomizedTooltipDescription
                  title={'Plans & Pricing'}
                  text1={'Only owner'}
                  text2={'has access'}
                />
            }

            {user?.role === 'owner'
              ? <NavLink
                  to="/back_office/user/membership_plan/billing_history"
                  className={({ isActive }) => cn('plan-item',
                    { 'plan-item--active': isActive })}
                >
                  Billing history
                </NavLink>
              : <CustomizedTooltipDescription
                  title={'Billing history'}
                  text1={'Only owner'}
                  text2={'has access'}
                />
            }
            <NavLink
              to="/back_office/user/membership_plan/invoices"
              className={({ isActive }) => cn('plan-item',
                { 'plan-item--active': isActive })}
            >
              Invoices
            </NavLink>
            {/* <p className="exclusions-item exclusions-item--inactive">
              Billing
            </p> */}
          </nav>

          <Outlet/>
        </div>
      }
    </>
  )
}
