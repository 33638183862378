import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useHttp } from '../../../hooks/http.hook';
import { useTitle } from '../../../hooks/title.hook';
import { Loader } from "../../Loader/Loader";
import { AuthContext } from "../../../context/AuthContext";
import './CheckoutSession.css'

export const CheckoutSession = () => {
  useTitle("PhishFirewall | Payment checkout");

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { company, plan, priceDetails, seats, annualPrice, monthlyPrice, renewPlan, testPayment } = state;
  const { type, price } = priceDetails;
  const { token, showToastMessage, fetchUser } = useContext(AuthContext);
  const { request } = useHttp();
  
  const [loading, setLoading] = useState(false);
  const [newSubscription, setNewSubscription] = useState({});

  let priceCount = 0;
  const updateSubscription = async (priceCount) => {
    try {
      if (token) {
        setLoading(true);
        const subscription = await request(`/back_office/api/payment/update_subscription`, 'POST', { state, priceCount }, {
          Authorization: `Bearer ${token}`
        })

        setNewSubscription(subscription);
        setLoading(false);
        return subscription;
      }
    } catch (error) {}
  };

  useEffect(() => {
    priceCount++;
    if (renewPlan) updateSubscription(priceCount);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createSubscriptionCheckout = async () => {
    try {
      setLoading(true);
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      const stripe = await stripePromise;
      const session = await request('/back_office/api/payment/create_subscription_checkout', "POST", { state }, {
        Authorization: `Bearer ${token}`
      })
  
      await stripe.redirectToCheckout({ sessionId: session.sessionId })
    } catch (error) {
      setLoading(false);
      showToastMessage(
        'We encountered an error. Please try again or if this problem persists contact support@phishfirewall.com for additional assistance'
      );
      console.error(error);
    }
  };
  
  // eslint-disable-next-line no-unused-vars
  const updateSubscriptionCheckout = async () => {
    try {
      setLoading(true);
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      const stripe = await stripePromise;
      const session = await request('/back_office/api/payment/update_subscription_checkout', "POST", { ...newSubscription, newPrice: price, seats }, {
        Authorization: `Bearer ${token}`
      })
  
      await stripe.redirectToCheckout({ sessionId: session.sessionId })
    } catch (error) {
      setLoading(false);
      showToastMessage(
        'We encountered an error. Please try again or if this problem persists contact support@phishfirewall.com for additional assistance'
      );
      console.error(error);
    }
  };
  
  const updateSubscriptionConfirm = async () => {
    try {
      setLoading(true);
      const res = await request('/back_office/api/payment/update_subscription_confirm', "POST", { ...newSubscription, newPrice: price, seats }, {
        Authorization: `Bearer ${token}`
      })
      
      showToastMessage(res.error, null, res.warning);
      if (res.message) {
        setTimeout(() => {
          fetchUser();
          setLoading(false);
          navigate('/back_office/user/membership_plan/plans');
          showToastMessage(res.error, res.message, res.warning);
        }, 2000)
      }
    } catch (error) {
      setLoading(false);
      showToastMessage(
        'We encountered an error. Please try again or if this problem persists contact support@phishfirewall.com for additional assistance'
      );
      console.error(error);
    }
  };

  return (
    <div className="billing-page">
      <NavLink
        className="billing-back-link"
        to={'/back_office/user/membership_plan/plans'}
      >
        Back to Membership plans
      </NavLink>
      <div className="billing-cards">
      {!loading
        ?
          <>
            <h2>Payment checkout</h2>
              <div className="billing-card card-description">
                <h4>Your subscription plan:</h4>
                <ul>
                  <li>
                    For<br/>
                    <span>{company}</span>
                  </li>
                  <li>
                    Subscription Plan<br/>
                    <span>{plan}</span>
                  </li>
                  <li>
                    Billing<br/>
                    <span>{type}</span>
                  </li>
                  <li>
                    Seats<br/>
                    <div className="explanation">
                      <span>{seats}</span>
                      {type === 'annual' &&
                        <div>{annualPrice} USD / seat / year</div>
                      }
                      {type === 'monthly' &&
                        <div>{monthlyPrice} USD / seat / month</div>
                      }
                    </div>
                  </li>
                </ul>
                <div className="divider"></div>
                {renewPlan
                  ? newSubscription.type !== 'monthly' 
                      ? <>
                          <div className="total-price-subtotal">
                            <span>Subtotal:</span>
                            <span>${price} USD</span>
                          </div>
                          <div className="total-price-subtotal">
                            <span>Paid subscription balance:</span>
                            <span>${(price - newSubscription.differenceInPrice / 100).toFixed(2)} USD</span>
                          </div>
                          <div className="divider"></div>
                          <div className="total-price">
                            <div>Total:</div>
                            <div className="total-price-tax">
                              <div>${(newSubscription.differenceInPrice / 100).toFixed(2)} USD</div>
                              <div>+ applicable tax</div>
                            </div>
                          </div>
                          <button
                            // onClick={updateSubscriptionCheckout}
                            onClick={updateSubscriptionConfirm}
                            className="submit-price-button"
                            disabled={!testPayment}
                          >
                            Buy now
                          </button>
                        </>
                      : <>
                          <div className="total-price">
                          <div>Total:</div>
                          <div className="total-price-tax">
                            <div>${price} USD</div>
                            <div>+ applicable tax</div>
                          </div>
                          </div>
                          <button
                            className="submit-price-button"
                            // onClick={updateSubscriptionCheckout}
                            onClick={updateSubscriptionConfirm}
                            disabled={!testPayment}
                          >
                            Buy now
                          </button>
                        </>
                  : <>
                      <div className="total-price">
                        <div>Total:</div>
                        <div className="total-price-tax">
                          <div>${price} USD</div>
                          <div>+ applicable tax</div>
                        </div>
                      </div>
                      <button
                        className="submit-price-button"
                        onClick={createSubscriptionCheckout}
                        disabled={!testPayment}
                      >
                        Buy now
                      </button>
                    </>
                }
                <p className="billing-card-description">
                  By clicking "Buy now", you are agreeing to our
                  <NavLink to="/back_office/user/membership_plan/plans"> subscriber terms</NavLink>. Your subscription will
                  <strong> renew automatically</strong> by charging your payment
                  method on file until you
                  <NavLink to="/back_office/user/membership_plan/plans"> cancel</NavLink>. You may cancel at any time prior to
                  the next billing cycle.
                </p>
              </div>

              
            <div className='billing-page-questions'>
              <span>Have questions about pricing or billings?</span>
              <a className='billing-page-questions-link' href='mailto:sales@phishfirewall.com'>Contact Sales</a>
            </div>
            
          </>
        : <Loader/>}
      </div>
    </div>
  )
}