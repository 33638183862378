const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'transperent',
    border: state.isFocused ? '1px solid #D6D6D6' : '1px solid #D6D6D6',
    boxShadow: state.isFocused ? '0 0 3px 2px #e7e6e6;' : 0,
    borderRadius: '4px',
    // boxShadow: state.isFocused ? 0 : 0,
    paddingLeft: '28px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.02em',
    color: '#343232',
    '&:hover': {
      cursor: state.isDisabled ? 'not-allowed' : 'default',
      border: state.isFocused ? '1px solid #D6D6D6' : '1px solid #D6D6D6',
      boxShadow: state.isFocused
        ? '0 0 3px 2px #e7e6e6;'
        : '0 0 3px 0.5px #e7e6e6;',
    },
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    maxHeight: '100px',
    overflowY: 'scroll',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#FBEDED';
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : // : isSelected
        // ? data.color
        isFocused
        ? color
        : undefined,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? 'white' > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      color: '#343232',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '4px',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'white'
            : 'white'
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = 'white';
    return {
      ...styles,
      backgroundColor: color,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#5D5B5B',
    ':after': {},
    ':hover': {
      backgroundColor: data.color,
      color: data.color,
    },
  }),
};

export default colourStyles;
