import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { AllUsersOption, CompanyOption, DepartmentOption, EmailTemplateOption, EmailTypeOption, LocationOption, SegmentOption, TenantOption } from '../../components/ReactSelect/ReactSelectOption';
import { customModalStyles, riskLevelOptions } from '../../config/config';
import { CustomizedTooltipInfo } from '../../components/Tooltips/Tooltip';
import {
  EXPEDITED_TRAINING_EMAIL, INCREASE_RISK_LEVEL_EMAIL,
  MANDATORY_TRAINING_EMAIL, MANDATORY_TRAINING_LINK_EMAIL,
  MIXED_PHISHING_EMAIL, MIXED_TRAINING_EMAIL,
  PHISHING_EMAIL, TRAINING_EMAIL, INTRODUNCTION_EMAIL
} from '../../config/enum';

const trackingOpenEmailTypeIds = [
  PHISHING_EMAIL, TRAINING_EMAIL,
  MIXED_TRAINING_EMAIL, MIXED_PHISHING_EMAIL,
  MANDATORY_TRAINING_EMAIL, MANDATORY_TRAINING_LINK_EMAIL,
  INCREASE_RISK_LEVEL_EMAIL, EXPEDITED_TRAINING_EMAIL
];
const trackingClickEmailTypeIds = [
  PHISHING_EMAIL, TRAINING_EMAIL, INTRODUNCTION_EMAIL,
  MIXED_TRAINING_EMAIL, MIXED_PHISHING_EMAIL,
  MANDATORY_TRAINING_EMAIL, MANDATORY_TRAINING_LINK_EMAIL,
  INCREASE_RISK_LEVEL_EMAIL, EXPEDITED_TRAINING_EMAIL
];

export const BulkEmailing = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { showToastMessage, token } = useContext(AuthContext);
  const { decryptData, encryptData } = useCrypto();

  const [potentialReceiversCount, setPotentialReceiversCount] = useState(0);
  const [allActiveUsersCount, setAllActiveUsersCount] = useState(0);
  const [emailTemplatesOptions, setEmailTemplatesOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [tenantsOptions, setTenantsOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState([
    { value: 57382, label: 'Manual Send' },
  ]);
  const [emailTypesOptions, setEmailTypesOptions] = useState([
    { value: 13, label: 'Manual sending' },
  ]);
  const [requestState, setRequestState] = useState({
    allActiveUsers: false,
    companyId: null,
    companyName: null,
    riskLevel: null,
    tenantId: null,
    tenantName: null,
    department: null,
    location: null,
    userIds: [],
    emailTemplateId: null,
    segmentId: 57382,
    campaignId: 'Manual sending',
    emailTypeId: 13,
    disableOpenTracking: true,
    disableClickTracking: true
  });
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }
  const fetchEmailTemplates = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/sys_admin/email_templates', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        if (fetched?.code === 403) {
          return showToastMessage(fetched.error)
        }

        const data = decryptData(fetched);

        showToastMessage(data.error, data.message);
        setEmailTemplatesOptions(data?.templates || []);
        setSegmentsOptions(data?.segments || []);
        setEmailTypesOptions(data?.emailTypes || []);

        return data;
      }
    } catch (error) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGeneralData = useCallback(async () => {
    try {
      if (
           (!requestState.allActiveUsers || (requestState.allActiveUsers && requestState.riskLevel))
           && requestState.emailTemplateId
         ) {
        const data = encryptData(requestState)
        const fetched = await request('/back_office/api/sys_admin/general_data', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        if (fetched?.code === 403) {
          return showToastMessage(fetched.error)
        }

        const fetchedData = decryptData(fetched);

        if (fetchedData.companies?.length) {
          setCompaniesOptions(fetchedData.companies);
          setAllActiveUsersCount(fetchedData.usersCount);
        }
        if (requestState.companyId && fetchedData.tenants?.length) setTenantsOptions(fetchedData.tenants);
        if (requestState.tenantId && fetchedData.users?.length) {
          setDepartmentOptions(fetchedData.departments);
          setLocationOptions(fetchedData.locations);
          setUsersOptions(fetchedData.users);
        };
        setPotentialReceiversCount(fetchedData.usersCount);

        showToastMessage(fetchedData.error, fetchedData.message)

        return fetchedData;
      }
    } catch (error) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
        requestState.allActiveUsers, requestState.companyId,
        requestState.tenantId, requestState.emailTemplateId,
        requestState.riskLevel, requestState.department, requestState.location
     ]
  );

  useEffect(() => {
    fetchGeneralData();
  }, [fetchGeneralData]);

  useEffect(() => {
    fetchEmailTemplates();
  }, [fetchEmailTemplates]);

  const handleEmailTemplateChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      emailTemplateId: data?.value || null,
      segmentId: 57382,
      emailTypeId: 13,
      allActiveUsers: false,
      riskLevel: null,
      companyId: null,
      companyName: null,
      tenantId: null,
      tenantName: null,
      department: null,
      location: null,
      userIds: [],
      disableOpenTracking: true,
      disableClickTracking: true
    }));

    setUsersOptions([]);
    setTenantsOptions([]);
    setDepartmentOptions([]);
    setLocationOptions([]);
    setSelectedUsersOptions([]);
  };

  const handleSegmentChangeChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      segmentId: data?.value
    }));
  };

  const handleEmailTypesChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      emailTypeId: data?.value,
      disableOpenTracking: !trackingOpenEmailTypeIds.includes(data?.value),
      disableClickTracking: !trackingClickEmailTypeIds.includes(data?.value)
    }));
  };

  const handleRiskLevelChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      riskLevel: data?.value || null,
    }));
  };

  const handleCompanyChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      companyId: data?.value || null,
      companyName: data?.label || null,
      tenantId: null,
      tenantName: null,
      department: null,
      location: null,
      userIds: []
    }));

    setUsersOptions([]);
    setTenantsOptions([]);
    setDepartmentOptions([]);
    setLocationOptions([]);
    setSelectedUsersOptions([]);
  };

  const handleTenantChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      tenantId: data?.value || null,
      tenantName: data?.label || null,
      department: null,
      location: null,
      userIds: []
    }));

    setDepartmentOptions([]);
    setLocationOptions([]);
    setUsersOptions([]);
    setSelectedUsersOptions([]);
  };

  const handleDepartmentChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      department: data?.value || null,
      userIds: []
    }));

    setUsersOptions([]);
    setSelectedUsersOptions([]);
  };

  const handleLocationChange = (data) => {
    setRequestState(prev => ({
      ...prev,
      location: data?.value || null,
      userIds: []
    }));

    setUsersOptions([]);
    setSelectedUsersOptions([]);
  };

  const handleUsersChange = (data) => {
    setSelectedUsersOptions(data);
    setRequestState(prev => ({
      ...prev,
      userIds: data?.map(({ value }) => value) || []
    }));
  };

  const allUsersCheckBoxHandler = () => {
    setRequestState(prev => ({
      ...prev,
      allActiveUsers: !requestState.allActiveUsers,
      riskLevel: null,
      companyId: null,
      companyName: null,
      tenantId: null,
      tenantName: null,
      department: null,
      location: null,
      userIds: []
    }));

    setUsersOptions([]);
    setTenantsOptions([]);
    setDepartmentOptions([]);
    setLocationOptions([]);
    setSelectedUsersOptions([]);
  };

  const sendEmail = async () => {
    try {
      const data = encryptData(requestState);
      const res = await requestWithSecondaryLoading('/back_office/api/sys_admin/send_email', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message, res.warning);
      closeModal();

      if (res.message) {
        setRequestState(prev => ({
          ...prev,
          emailTemplateId: null,
          allActiveUsers: false,
          companyId: null,
          companyName: null,
          tenantId: null,
          tenantName: null,
          userIds: []
        }));
    
        setUsersOptions([]);
        setTenantsOptions([]);
        setSelectedUsersOptions([]);
      }
    } catch (error) { }
  }

  return (
    <div className="sys-admin-card">
      <h2 className="sys-admin-title">
        Bulk Emailing
      </h2>
      <p className='sys-admin-subtitle'>
        Here you can send an Email Template to a lot of users at once.
        <br />
        <br />
        <strong>Important!</strong>
        <br />
        Regardless of the filters you choose, the email will be sent only to active users who have an active company, active and launched tenant! <br />
        You can send only 1 Email Template email per day for 1 user!
      </p>

      <label className='sys-admin-select'>
        Email Template
        <ReactSelect
          classNames={'email-template-select'}
          options={emailTemplatesOptions}
          optionComponent={EmailTemplateOption}
          placeholder={'Select Email Template'}
          value={emailTemplatesOptions?.find(option => option?.value === requestState.emailTemplateId) || null}
          onChange={handleEmailTemplateChange}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          optionHeight={65}
          rows={4}
          isLoading={loading}
          isDisabled={loading}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || !requestState.emailTemplateId
      })}>
        Segment
        <ReactSelect
          classNames={'segment-select'}
          options={segmentsOptions}
          optionComponent={SegmentOption}
          placeholder={'Select Segment'}
          value={segmentsOptions?.find(option => option?.value === requestState.segmentId)}
          onChange={handleSegmentChangeChange}
          isSearchable={true}
          isClearable={false}
          isMulti={false}
          optionHeight={46}
          rows={4}
          isLoading={loading}
          isDisabled={loading || !requestState.emailTemplateId}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || !requestState.emailTemplateId
      })}>
        Email Type
        <ReactSelect
          classNames={'email-type-select'}
          options={emailTypesOptions}
          optionComponent={EmailTypeOption}
          placeholder={'Select Email Type'}
          value={emailTypesOptions?.find(option => option?.value === requestState.emailTypeId)}
          onChange={handleEmailTypesChange}
          isSearchable={true}
          isClearable={false}
          isMulti={false}
          optionHeight={34}
          rows={6}
          isLoading={loading}
          isDisabled={loading || !requestState.emailTemplateId}
        />
      </label>

      <div className="card-content-all-users">
        <label
          className={"slider-checkbox"}
        >
          <input
            type="checkbox"
            className='checkbox-input'
            checked={!requestState.disableOpenTracking}
            onChange={() => {
              setRequestState(prev => ({
                ...prev,
                disableOpenTracking: !prev.disableOpenTracking
              }))
            }}
            disabled={loading || !requestState.emailTemplateId}
          />
          <span className={cn("slider", {
            "slider-checkbox-disabled": loading || !requestState.emailTemplateId,
          })}/>
        </label>
        <label className='slider-checkbox-label'>Track Opens?</label>
        <CustomizedTooltipInfo
          text={
            `Opens need to be tracked only for Phishing and all Training emails,
            we do not process them for other cases and enabling them for other
            types of emails will lead to a significant unnecessary load on the system!!!!`
          }
        />
      </div>

      <div className="card-content-all-users">
        <label
          className={"slider-checkbox"}
        >
          <input
            type="checkbox"
            className='checkbox-input'
            checked={!requestState.disableClickTracking}
            onChange={() => {
              setRequestState(prev => ({
                ...prev,
                disableClickTracking: !prev.disableClickTracking
              }))
            }}
            disabled={loading || !requestState.emailTemplateId}
          />
          <span className={cn("slider", {
            "slider-checkbox-disabled": loading || !requestState.emailTemplateId,
          })}/>
        </label>
        <label className='slider-checkbox-label'>Track Clicks?</label>
        <CustomizedTooltipInfo
          text={
            `Clicks need to be tracked only for Phishing, Introduction and all Training emails,
            we do not process them for other cases and enabling them for other
            types of emails will lead to a significant unnecessary load on the system!!!!`
          }
        />
      </div>

      <div className='divider'></div>

      <div className="card-content-all-users">
        <label
          className={"slider-checkbox"}
        >
          <input
            type="checkbox"
            className='checkbox-input'
            checked={requestState.allActiveUsers}
            onChange={allUsersCheckBoxHandler}
            disabled={loading || !requestState.emailTemplateId}
          />
          <span className={cn("slider", {
            "slider-checkbox-disabled": !requestState.emailTemplateId,
          })}/>
        </label>
        <label className='slider-checkbox-label'>All Active Users</label>
      </div>

      <span className='sys-admin-subtitle'>Additional filters:</span>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || requestState.allActiveUsers || !requestState.emailTemplateId
      })}>
        Company
        <ReactSelect
          classNames={'company-select'}
          options={companiesOptions}
          optionComponent={CompanyOption}
          placeholder={'Select Company...'}
          value={companiesOptions?.find(option => option?.value === requestState.companyId) || null}
          onChange={handleCompanyChange}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          optionHeight={32}
          rows={6}
          isDisabled={loading || requestState.allActiveUsers || !requestState.emailTemplateId}
          isLoading={loading}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || requestState.allActiveUsers || !requestState.companyId
      })}>
        Tenant
        <ReactSelect
          options={ tenantsOptions }
          optionComponent={TenantOption}
          placeholder={'Select Tenant...'}
          classNames={'tenant-select'}
          value={tenantsOptions?.find(option => option?.value === requestState.tenantId) || null}
          onChange={handleTenantChange}
          isSearchable={true}
          isClearable={true}
          isDisabled={!requestState.companyId || loading}
          isLoading={loading}
          optionHeight={28}
          rows={10}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || requestState.allActiveUsers || !requestState.companyId || !requestState.tenantId
      })}>
        Department
        <ReactSelect
          options={ departmentOptions }
          optionComponent={DepartmentOption}
          placeholder={'Select Department...'}
          classNames={'department-select'}
          value={departmentOptions?.find(option => option?.value === requestState.department) || null}
          onChange={handleDepartmentChange}
          isSearchable={true}
          isClearable={true}
          isDisabled={!requestState.companyId || !requestState.tenantId || loading}
          isLoading={loading}
          optionHeight={32}
          rows={10}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || requestState.allActiveUsers || !requestState.companyId || !requestState.tenantId
      })}>
        Location
        <ReactSelect
          options={ locationOptions }
          optionComponent={LocationOption}
          placeholder={'Select Location...'}
          classNames={'location-select'}
          value={locationOptions?.find(option => option?.value === requestState.location) || null}
          onChange={handleLocationChange}
          isSearchable={true}
          isClearable={true}
          isDisabled={!requestState.companyId || !requestState.tenantId || loading}
          isLoading={loading}
          optionHeight={32}
          rows={10}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading || !requestState.emailTemplateId || (!requestState.allActiveUsers && !requestState.companyId)
      })}>
        User Risk Level
        <ReactSelect
          classNames={'risk-level-select'}
          options={riskLevelOptions}
          optionComponent={CompanyOption}
          placeholder={'Select Risk Level...'}
          value={riskLevelOptions?.find(option => option?.value === requestState.riskLevel) || null}
          onChange={handleRiskLevelChange}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          optionHeight={32}
          rows={6}
          isDisabled={loading || !requestState.emailTemplateId || (!requestState.allActiveUsers && !requestState.companyId)}
          isLoading={loading}
        />
      </label>

      <label className={cn('sys-admin-select', {
        'sys-admin-select--disabled': loading
          || requestState.allActiveUsers
          || !requestState.companyId
          || !requestState.tenantId
          || !potentialReceiversCount
      })}>
        User(s)
        <ReactSelect
          options={ usersOptions }
          optionComponent={AllUsersOption}
          placeholder={'Select User(s)...'}
          classNames={'users-select'}
          value={selectedUsersOptions}
          onChange={handleUsersChange}
          isSearchable={true}
          isMulti={true}
          isClearable={true}
          isDisabled={!requestState.tenantId || !requestState.companyId || loading || !potentialReceiversCount}
          isLoading={loading}
          optionHeight={50}
          rows={6}
          closeMenuOnSelect={true}
          menuPlacement={'top'}
        />
      </label>

      <h4>Send email to: {requestState.allActiveUsers
        ? `${allActiveUsersCount} ${potentialReceiversCount === 1 ? 'user' : 'users'}`
        : requestState.companyId
          ? `${(!!potentialReceiversCount && selectedUsersOptions?.length) || potentialReceiversCount} ${(selectedUsersOptions?.length || potentialReceiversCount) === 1 ? 'user' : 'users'}`
          : 0 + ' users'
      } </h4>

      <button
        className='sys-admin-btn'
        disabled={loading
          || !requestState.emailTemplateId
          || (!requestState.allActiveUsers && !requestState.companyId)
          || !potentialReceiversCount
        }
        onClick={openModal}
      >
        Send Email
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div className='sys-admin-modal-container'>
          <button onClick={closeModal} className="modal-close"></button>
          <h2 className="modal-title">Confirmation of Email sending</h2>
            <p>
              Do you really want to send an email
            </p>
            <ul>
              <li>Template ID: <b>{emailTemplatesOptions?.find(option => option?.value === requestState.emailTemplateId)?.value}</b></li>
              <li>Subject: <b>{emailTemplatesOptions?.find(option => option?.value === requestState.emailTemplateId)?.caption}</b></li>
            </ul>

            <p>from</p>

            <ul>
              <li>Segment ID: <b>{requestState.segmentId}</b></li>
              <li>Segment Name: <b>{segmentsOptions.find(option => option?.value === requestState.segmentId)?.label}</b></li>
            </ul>

            <p>with type <b>{emailTypesOptions.find(option => option?.value === requestState.emailTypeId)?.label}</b> email,</p>
            <p>with <b>{requestState.disableOpenTracking ? 'NO TRACKING' : 'TRACKING'}</b> opens and
            <b> {requestState.disableClickTracking ? 'NO TRACKING' : 'TRACKING'}</b> clicks</p><br />


            {requestState?.allActiveUsers
              ? <div>
                  <p>
                    to <b>All Active Users</b>
                    {requestState?.riskLevel &&
                      <span> and only with <b>{requestState.riskLevel}</b> level</span>
                    }?
                  </p><br />

                  <ul>
                    <li>Recepints Count:
                      <b> {`${allActiveUsersCount} ${potentialReceiversCount === 1 ? 'user' : 'users'}`}</b>
                    </li>
                  </ul>
                </div>
              : <div>
                  <p>
                    to users from <b>{requestState.companyName}</b>
                    {requestState.tenantName &&
                      <span> and <b>{requestState.tenantName}</b> tenant</span>
                    }
                    {requestState.department &&
                      <span> and <b>{requestState.department}</b> department</span>
                    }
                    {requestState.location &&
                      <span> and <b>{requestState.location}</b> location</span>
                    }
                    {requestState.riskLevel &&
                      <span> and only with <b>{requestState.riskLevel}</b> level</span>
                    }?
                  </p>

                  <ul>
                    <li>Potential Recepints Count:
                      <b> {`${(!!potentialReceiversCount && selectedUsersOptions?.length) || potentialReceiversCount} ${(selectedUsersOptions?.length || potentialReceiversCount) === 1 ? 'user' : 'users'}`}</b>
                    </li>
                  </ul>
              </div>
            }

            <div className='sys-admin-modal-button-container'>
              {!secondaryLoading
                ? <button
                    type='button'
                    className='button'
                    onClick={sendEmail}
                  >
                    Yes, Send!
                  </button>
                : <button
                    type='button'
                    className='button'
                    disabled={true}
                  >
                    <span className='mini-loader-send-email'></span>
                  </button>
              }
              <button
                type='button'
                className='button button--cancel'
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
        </div>
      </Modal>
    </div>
  )
}
