import './EmailSignatures.css';
import edit from '../.././images/icons/Pen.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/auth.hook';

export const EmailSignatures = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const [companyMetadata, setCompanyMetadata] = useState([]);

  const getMetadata = useCallback(async () => {
    try {
      if (token) {
        const metadata = await request('/back_office/api/user/company_metadata', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyMetadata(metadata);

        return metadata;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);
  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details">
            <div className='signature-card-phish'>
              <ul className='signature-account-list-phish'>
                <li className='signature-item'>
                  Security contact
                  <div className='signature-value'>
                    <div dangerouslySetInnerHTML={{ __html: companyMetadata['1609'] }}></div>
                  </div>
                </li>
                <li className='signature-item'>
                  HR Contact
                  <div className='signature-value'>
                    <div dangerouslySetInnerHTML={{ __html: companyMetadata['1611'] }}></div>
                  </div>
                </li>

              </ul>
              <div className='signature-item signature-item-button'>
                <NavLink to={`/back_office/email_settings/email_signatures/edit`} className="button-link">
                  <button>
                    <img src={edit} alt="edit"></img>
                    Edit
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
