import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useHttp } from '../hooks/http.hook';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import { ChangePassword } from './ChangePassword';
import eye from '.././images/icons/Eye.svg';
import closeEye from '.././images/icons/Eye Slash.svg';
import { storageName, activaTabTime } from '../config/config';
import cn from 'classnames';
import { getCookie, removeCookie } from '../common/cookieHandler';
import './form.css';

export const AuthPage = () => {
  useTitle("Welcome to PhishFirewall | Log In");
  localStorage.removeItem(activaTabTime);

  const { login, showToastMessage, logout } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const [authForm, setAuthForm] = useState({
    email: '',
    password: ''
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [card, setCard] = useState('login');
  const [authData, setAuthData] = useState({});

  const { companyName } = useParams();
  const navigate = useNavigate();

  const SSOAuth = () => (
    window.open(`${process.env.REACT_APP_DOMAIN_BASE_URL}/back_office/api/auth/${companyName}`, '_self')
  );

  const getCompany = useCallback( async () => {
    const res = await request(`/back_office/api/auth/sso_by_company/${companyName}`, 'GET');

    if (res.error) {
      navigate('/back_office/login');
      showToastMessage(res.error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, navigate, request, showToastMessage]);
  
  useEffect(() => {
    if (companyName) getCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName])
  
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    const cookieData = getCookie(storageName);
    const decodedCookieData = decodeURIComponent(cookieData);
    removeCookie(storageName);

    if (decodedCookieData) {
      const userData = JSON.parse(decodedCookieData);

      showToastMessage(userData.error);

      if (userData.error && userData.companyName) {
        navigate(`/back_office/login/${userData.companyName}`);
      } else {
        login(userData.token, userData.userId);
        showToastMessage(null, userData.message);
      }
    }
  }, [login, showToastMessage, logout, navigate]);

  const changeAuthHandler = (event) => {
    setAuthForm({ ...authForm, [event.target.name] : event.target.value.trim() })
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const data = encryptData(authForm);
      const res = await request('/back_office/api/auth/login', 'POST', { data });
      showToastMessage(res.error);
      const decryptRes = decryptData(res);

      if (decryptRes.ssoRequired) {
        navigate(`/back_office/login/${decryptRes.companyName}`);
      } else if (decryptRes.passwordNotExpired) {
        login(decryptRes.token, decryptRes.userId);
        showToastMessage(res.error, decryptRes.message);
      } else if (!res.error && decryptRes.token) {
        setAuthData(decryptRes);
        setCard('passwordChange');
      }
    } catch (error) {}
  }

  return (
    <>
      {card === 'login' &&
        <form className="form">
          <div className="title">
            <span className="card-title">Welcome back!</span><br/>
            <span className="card-subtitle">
              To access Phishfirewall please enter your details.
            </span>
          </div>

          {!companyName
            ? <>
                <div className="card-content">
                  <div className="input-field">
                    <label htmlFor="email">Email</label><br/>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder='Your email...'
                      className="input"
                      onChange={changeAuthHandler}
                    />
                  </div>
                  <div className="input-field">
                    <img
                      src={!passwordShown ? eye : closeEye}
                      alt='Eye'
                      className='eye'
                      onClick={togglePassword}
                    ></img>
                    <label htmlFor="password">Password</label><br/>
                    <input
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder='Password...'
                      className="input"
                      onChange={changeAuthHandler}
                    />
                  </div>
                  <div className="checkbox-action">
                    <div></div>
                    <NavLink to="/back_office/forgot_password" className="forgot-password">
                      Forgot password?
                    </NavLink>
                  </div>
                </div>
            
                <div className={cn("card-action card-action-auth", {
                  "card-action-auth-last": !companyName
                })}>
                  <button
                    onClick={loginHandler}
                    disabled={loading}
                  >
                    Log In
                  </button>
                </div>
              </>

            : <>
                <p className='card-action-or'>SIGN IN WITH</p>
                <button
                  className='sso-button'
                  type="button"
                  onClick={SSOAuth}
                >
                  SSO
                </button>
              </>
          }
          <div className='signup-action'>
            <span>
              Don’t have an account?&nbsp;
            </span>
            <NavLink
              to="/back_office/register"
              className="signin-navlink-auth"
            >
              Sign Up
            </NavLink>
          </div>
        </form>
      }

      {card === 'passwordChange' &&
        <ChangePassword
          authForm={true}
          authData={authData}
          setCard={setCard}
        />
      }
    </>
  )
};
