import React from 'react';
import cn from 'classnames';
import './Phishing.css';

export const PhishingMetrics = ({ phishingData, loading }) => {
  return (
    <div className='analytics-metrics phishing-metrics'>
      <h4>Phishing Metrics</h4>

      <div className='phishing-metrics-container'>
        <div className='analytics-metrics-item'>
        <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(phishingData.phishClickRate || '0')?.toString()?.replace('.00', '')}%</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': phishingData.diffPhishClickRate < 0,
                    'analytics-metrics-value-prev--negative': phishingData.diffPhishClickRate > 0
                  })}>
                    {phishingData.diffPhishClickRate > 0 && '+'}
                    {!!phishingData.diffPhishClickRate && phishingData.diffPhishClickRate?.toString()?.replace('.00', '') + '%'}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Phish Click Rate</div>
          </div>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(phishingData.phishClickCount || 0).toLocaleString('en-US')}</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': phishingData.diffPhishClickCount < 0,
                    'analytics-metrics-value-prev--negative': phishingData.diffPhishClickCount > 0
                  })}>
                    {phishingData.diffPhishClickCount > 0 && '+'}
                    {!!phishingData.diffPhishClickCount && phishingData.diffPhishClickCount}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Phish Click Count</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(phishingData.phishSimulationsReportRate || '0')?.toString()?.replace('.00', '')}%</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': phishingData.diffPhishSimulationsReportRate > 0,
                    'analytics-metrics-value-prev--negative': phishingData.diffPhishSimulationsReportRate < 0
                  })}>
                    {phishingData.diffPhishSimulationsReportRate > 0 && '+'}
                    {!!phishingData.diffPhishSimulationsReportRate && phishingData.diffPhishSimulationsReportRate?.toString()?.replace('.00', '') + '%'}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Phishing Simulations Report Rate</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(phishingData.pFReportedEmails || 0).toLocaleString('en-US')}</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': phishingData.diffPFReportedEmails > 0,
                    'analytics-metrics-value-prev--negative': phishingData.diffPFReportedEmails < 0
                  })}>
                    {phishingData.diffPFReportedEmails > 0 && '+'}
                    {!!phishingData.diffPFReportedEmails && phishingData.diffPFReportedEmails}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Phishing Simulations Reported</div>
          </div>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(phishingData.totalPhishingEmailSent || 0).toLocaleString('en-US')}</span>
                </div>
              }
            <div className='analytics-metrics-label'>Total Phishing Simulations</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className='analytics-metrics-item-general'>
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>
                    {(phishingData.totalUsersCount || 0).toLocaleString('en-US')}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Total users</div>
            {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(phishingData.recentUsersCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Total New Users</div>
          </div>
      </div>
      </div>
    </div>
  );
}
