/* eslint-disable no-unused-vars */
import React, {useContext, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import submit from '../.././images/icons/Check.svg'
import { CustomizedTooltip, CustomizedTooltipDescription } from '../../components/Tooltips/Tooltip';
import { useCrypto } from '../../hooks/crypto.hook';
import  Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';
import { SetSchedulerModal } from '../../components/SetSchedulerModal/SetSchedulerModal';
import './CreateUser.css';
import { languageOptions } from '../../config/config';

export const ADCreateUser = () => {
  const { token, showToastMessage, fetchUser } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [editForm, setEditForm] = useState({
    TenantId: '',
    tenantName: '',
    ClientId: '',
    ClientSecret: '',
    inclusionGroup: '',
    exclusionGroup: ''
  });
  const [lang, setLang] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(true);
  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editTenant, setEditTenant] = useState({
    tenantId: '',
    tenantName: '',
    type: '',
    scheduledLaunch: null,
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
    navigate('/back_office/user/users_database/ad');
  };

  const Option = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };
  const changeEditHandler = (event) => {
    setEditForm({ ...editForm, [event.target.name] : event.target.value.trim() })
  };
  
  const handleLangChange = (data) => {
    setLang(data);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  }
  
  const navigate = useNavigate();

  const updateSyncType = async () => {
    try {
      const syncType = 'syncAD';
      const res = await request('/back_office/api/user/update_sync_type', 'POST', { syncType }, {
        Authorization: `Bearer ${token}`
      })
      showToastMessage(res.error, res.message);
    } catch (error) {}
  }

  const editHandler = async (e) => {
    try {
      const data = encryptData({...editForm, filterUsers: selectedFilter, language: lang?.label});
      const response = await request('/back_office/api/user/azure_create', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(response.error, response.message, response.warning);

      if (response.addData) setEditTenant(decryptData(response.addData));
      if (response.message) {
        const res = await request('/back_office/api/user/azure/create_scheduler', 'POST', null, {
          Authorization: `Bearer ${token}`
        })
        
        showToastMessage(res.error, res.message, res.warning);

        if (res.message) {
          updateSyncType();
          fetchUser();
          openModal();
        }
      }
    } catch (error) {}
  }
  
  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
        <div>
          <p className="details-help">
            <span>Need help setting up Azure AD? </span>
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.phishfirewall.com/phishfirewall-onboarding-add-users-microsoft"
              >
                View our setup instructions.
              </a>
          </p>
          
          <div className='edit-main details-card'>
            <div className='details-action'>
              <div>
                <h3 className='details-action-title'>Azure Active Directory details</h3>
                <p className='details-action-subtitle'>Set up integration with Active Directory</p>
              </div>
            </div>

            <form className="edit-form" onSubmit={onSubmit}>
              <div className="edit-card">
                <div className="edit-content">
                  <div className="edit-list">
                    <div className="input-field">
                      <label htmlFor="tenantName" className="input-label input-label-required">Tenant Name</label>
                      <input
                        id="tenantName"
                        name="tenantName"
                        placeholder="Enter Tenant Name"
                        className="validate input-edit"
                        defaultValue={editForm?.tenantName}
                        onChange={changeEditHandler}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="TenantId" className="input-label input-label-required">Tenant ID</label>
                      <CustomizedTooltip
                        link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                        name={'Tenant ID'}
                      />
                      <input
                        id="TenantId"
                        name="TenantId"
                        placeholder="Enter Tenant Id"
                        className="validate input-edit"
                        defaultValue={editForm?.TenantId}
                        onChange={changeEditHandler}
                      />
                    </div>
                    <div className="input-field">
                        <label htmlFor="ClientId" className="input-label input-label-required">Application (client) ID</label>
                        <CustomizedTooltip
                          link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                          name={'Client ID'}
                        />
                        <input
                          id="ClientId"
                          name="ClientId"
                          placeholder="Enter Application (client) Id"
                          className="validate input-edit"
                          defaultValue={editForm?.ClientId}
                          onChange={changeEditHandler}
                        />
                    </div>
                    <div className="input-field">
                      <label htmlFor="ClientSecret" className="input-label input-label-required">Client secret “value”</label>
                      <CustomizedTooltip
                        link={'https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in'}
                        name={'Client secret'}
                      />
                      <input
                        id="ClientSecret"
                        name="ClientSecret"
                        placeholder="Enter Client Secret"
                        className="validate input-edit"
                        defaultValue={editForm?.ClientSecret}
                        onChange={changeEditHandler}
                      />
                    </div>
                  </div>

                  <div className="filter-checkbox-sync">
                    <div className='filter-checkbox-label'>
                      <label>
                        <input
                          type="checkbox"
                          className="filter-checkbox"
                          checked={selectedFilter}
                          onChange={() => setSelectedFilter( prevState => !prevState )}
                        />
                      </label>
                    </div>
                    <div>
                      <p className='input-label'>
                        Exclude users who have email address associated with <strong>@onmicrosoft.com</strong> domain.
                      </p>
                      <p className="input-description">
                        By toggling this option on, users with email addresses that end with "@onmicrosoft.com" will be included in 
                        <br /> the Users Database. Conversely, toggling it off will exclude such users from the sync.
                      </p>
                    </div>
                  </div>

                  <div className='input-field-tenant'>
                    <div className='userslist-select'>
                      <label className='userslist-select-label input-label-required'>
                      Phish email language by default
                      </label>
                        <p className="input-description">
                        Select a language for phishing emails. You can customize the language later or choose specific languages for individual users once your user list is synced.
                        </p>
                      <Select
                        id='select'
                        className='validate multiselect-azure'
                        options={languageList}
                        components={{ Option }}
                        placeholder="Select language"
                        value={lang}
                        onChange={handleLangChange}
                        isSearchable={true}
                        styles={colourStyles}
                      />
                    </div>
                  </div>

                  <p className="edit-list-description">You can fill in only one of the options below</p>
                  <div className="edit-list-groups">
                    <div className="input-field">
                      <label htmlFor="inclusionGroup" className="input-label">Inclusion Group(s)</label>
                      <CustomizedTooltip
                        link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-groups-view-azure-portal'}
                        name={'Inclusion Group'}
                      />
                      <p className="input-description">
                        If you want to add more than 1 group, separate their names with ", "
                      </p>
                      <input
                        id="inclusionGroup"
                        name="inclusionGroup"
                        placeholder="Enter Inclusion Group"
                        className="validate input-edit"
                        defaultValue={editForm?.inclusionGroup}
                        onChange={changeEditHandler}
                        disabled={editForm.exclusionGroup}
                      />
                    </div>
                    <div className="input-field-or">
                      <span>OR</span>
                    </div>
                    <div className="input-field">
                      <label htmlFor="exclusionGroup" className="input-label input-description">
                        Exclusion Group(s)
                      </label>
                      <CustomizedTooltip
                        link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-groups-view-azure-portal'}
                        name={'Exclusion Group'}
                      />
                      <p className="input-description">
                        If you want to add more than 1 group, separate their names with ", "
                      </p>
                      
                      <input
                        id="exclusionGroup"
                        name="exclusionGroup"
                        placeholder="Enter Exclusion Group"
                        className="validate input-edit"
                        defaultValue={editForm?.exclusionGroup}
                        onChange={changeEditHandler}
                        disabled={editForm.inclusionGroup}
                      />
                    </div>
                  </div>
                </div>

                <div className="edit-submit">
                  <button
                    className='button-submit'
                    onClick={editHandler}
                    disabled={loading || !editForm.TenantId || !editForm.ClientId || !editForm.ClientSecret || !editForm.tenantName}
                    type="submit"
                  >
                    <img src={submit} alt="submit"></img>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>

          <SetSchedulerModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            editTenant={editTenant}
            setEditTenant={setEditTenant}
            callback={null}
          />
        </div>
      }
    </>
  )
}
