import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from '../../context/AuthContext';
import { autoLogoutMinutes, activaTabTime } from '../../config/config';
import Modal from 'react-modal';
import './AutoLogout.css';

const LogoutTimeout = autoLogoutMinutes * 60 * 1000;
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    zIndex: 1000
  },
  content: {
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.2)',
    borderRadius: '4px'
  },
};

const AutoLogout = () => {
  const { logout, token } = useContext(AuthContext);

  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
    localStorage.removeItem(activaTabTime);
    
    if (token) {
      const channel = new BroadcastChannel("user_activity");
      channel.postMessage("logout");
    
      setTimeout(() => {
        logout();
      }, 200);
      
      return () => {
        channel.close();
      };
    }
  }

  const updateLastActivityTime = () => {
    setLastActivityTime(Date.now());
  };

  const logoutDueToInactivity = useCallback(() => {
    openModal();
  }, []);

  const handleActivity = useCallback(() => {
    if (token) {
      updateLastActivityTime();
      localStorage.setItem(activaTabTime, Date.now());
    }
  }, [token]);

  useEffect(() => {
    const lastActivity = localStorage.getItem(activaTabTime);

    if (lastActivity) {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - parseInt(lastActivity, 10);

      if (timeSinceLastActivity >= LogoutTimeout) {
        logoutDueToInactivity();
      }
    };

    const checkLogoutTimeout = () => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivityTime;

      if (timeSinceLastActivity >= LogoutTimeout) {
        logoutDueToInactivity();
      }
    };

    const intervalId = setInterval(checkLogoutTimeout, 1000);
    return () => clearInterval(intervalId);
  }, [lastActivityTime, logoutDueToInactivity]);

  useEffect(() => {
    const handleUnload = () => {
      if (modalIsOpen) logout();
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleActivity();
      }
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("beforeunload", handleUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (!document.hidden) {
      localStorage.setItem("activeTab", Date.now());
    }

    return () => {
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("beforeunload", handleUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleActivity, logout, modalIsOpen]);

  useEffect(() => {
    const channel = new BroadcastChannel("user_activity");
  
    channel.onmessage = (event) => {
      if (event.data === "activity") {
        handleActivity();
      } else if (event.data === "logout") {
        logout();
        if (event.timeStamp >= lastActivityTime) {
          logoutDueToInactivity();
        } else {
          channel.postMessage("activity");
        }
      }
    };
  
    channel.postMessage("activity");
  
    return () => {
      channel.close();
    };
  }, [handleActivity, lastActivityTime, logout, logoutDueToInactivity]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      closeTimeoutMS={400}
    >
      <div className="autologout-modal">
        <h4 className="autologout-modal-cancel-title">Session has expired!</h4>
        <div className="autologout-modal-gotit-button">
          <button
            onClick={closeModal}
          >
            Log In
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AutoLogout;
