import React, { useState, useCallback, useEffect, useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { ChartBarActions } from './ChartBarActions'
import { useCrypto } from '../../hooks/crypto.hook';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
import { AllUsersOption } from '../ReactSelect/ReactSelectOption';
import ReactSelect from '../ReactSelect/ReactSelect';
import { lastThirtyDaysChunk } from '../../config/config';
import './UsersActions.css';

export const ActionChart = () => {
  const { secondaryLoading, requestWithSecondaryLoading } = useHttp();
  const { token } = useAuth();
  const { encryptData, decryptData } = useCrypto();
  const { allUsers } = useContext(AuthContext);

  const [chartData, setChartData] = useState({labels: [], datasets: []});
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [startDate, setStartDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [minDate, setMinDate] = useState(moment('2022-01-01').format('YYYY-MM-DD'));
  const [rangeValue, setRangeValue] = useState(31);
  const [sumMetric, setSumMetric] = useState({'phishingOpens': 0, 'phishingClicks': 0, 'trainingOpens': 0, 'trainingClicks': 0});
  const [selectedUser, setSelectedUser] = useState(null);

  const maxDate = moment().format('YYYY-MM-DD');

  const fetchData = useCallback(async (startDate, endDate, selectedTenant, selectedUser) => {
    try {
      if (token) {
        const data = encryptData({ startDate, endDate, selectedTenant, selectedUser });
        const fetched = await requestWithSecondaryLoading(`/back_office/api/user/sync_users_actions`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })
        const decryptActions = decryptData(fetched);
        setChartData(decryptActions.chartData);
        setSumMetric(decryptActions.sumMetric);
        setMinDate(decryptActions.minDate);

        return decryptActions;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading]);

  useEffect(() => {
    const usersTenants = allUsers.map(el => ({
      tenantName: el.tenantName,
      syncType: el.syncType
    }));
    const uniqueObjects = [...new Set(usersTenants.map(JSON.stringify))].map(JSON.parse).sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    const selectTenantArray = [{tenantName: 'All', syncType: null}, ...uniqueObjects]
    const tenantList = selectTenantArray.map((v, i) => { return { value: v?.tenantName?.toLowerCase(), label: v?.tenantName, id: i, tenant: true, syncType: v?.syncType } })

    setTenants(tenantList)
  }, [allUsers])

  const Option = (props) => {
    const { label, value, caption, tenant, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn(`syncTypes-label-${value.toString().split(' ').join('-')} syncTypes-label-${syncType}`,{
            'users-list-tenant-label': tenant
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (startDate && endDate) fetchData(startDate, endDate, selectedTenant, selectedUser);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, fetchData, selectedTenant, selectedUser]);

  if (rangeValue < 1) setRangeValue(1);
  if (rangeValue > 31) setRangeValue(31);

  const allUsersFilteredByTenant = allUsers.filter(user => selectedTenant.label !== 'All'
    ? user.tenantName === selectedTenant.label && user.syncType === selectedTenant.syncType
    : user
  );

  const allUsersOptionList = allUsersFilteredByTenant?.map(user => (
    { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName }
  ))

  const handleUserChange = (data) => {
    setSelectedUser(data);
  };
  
  useEffect(() => {
    setRangeValue(moment(endDate).diff(moment(startDate), 'days') + 1);
  }, [startDate, endDate]);

  return (
    <div className={cn({'actions-chart-loading': secondaryLoading})}>
      <div className='actions-card'>
        <div className="input-container-user-actions-title">
          <h3>Actions</h3>
          <div className="input-container-actions">
            <p>Tenant:</p>
            <div className={`input-field-actions-tenant input-field-tenant-${selectedTenant.syncType}`}>
              <Select
                id='select'
                options={tenants}
                components={{ Option }}
                placeholder="Select tenant"
                className="select-edit-actions"
                value={selectedTenant}
                onChange={handleTenantChange}
                isSearchable={true}
                styles={colourStyles}
                isDisabled={secondaryLoading}
              />
            </div>
            <p>Select date range:</p>
            <div className="input-field-actions">
            <div className='input-field-actions-close--date'></div>
              <input
                required
                id="startDate"
                type="date"
                name="startDate"
                value={startDate}
                placeholder="Enter Start Date"
                className={cn("input-edit-actions", {"input-edit-actions-loading": secondaryLoading})}
                min={minDate}
                max={moment(endDate).format('YYYY-MM-DD')}
                onChange={(e) => {
                  if (e.target.value) {
                    setStartDate(e.target.value);
                    if (moment(endDate).diff(moment(e.target.value), 'days') > 30) {
                      setEndDate(moment(e.target.value).add(30, 'days').format('YYYY-MM-DD'))
                    }
                  }
                }}
                disabled={secondaryLoading}
              />
            </div>
            <div className="input-field-actions">
              <div className='input-field-actions-close--date'></div>
              <input
                required
                id="endDate"
                type="date"
                name="endDate"
                placeholder="Enter End Date"
                className={cn("input-edit-actions", {"input-edit-actions-loading": secondaryLoading})}
                value={endDate}
                min={moment(minDate).format('YYYY-MM-DD')}
                max={maxDate}
                onChange={(e) => {
                  if (e.target.value) {
                    setEndDate(e.target.value);
                    setStartDate(moment(e.target.value).subtract(rangeValue - 1, 'days').format('YYYY-MM-DD'));
                  }
                }}
                disabled={secondaryLoading}
              />
            </div>
            <p>Show days:</p>
            <div className="input-field-actions input-field-range-actions">
            <input
              required
              id="start-actions"
              type="number"
              min={1}
              max={31}
              step={1}
              value={rangeValue}
              className={cn("input-edit-actions", {"input-edit-actions-loading": secondaryLoading})}
                onChange={(e) => {
                  setRangeValue(e.target.value);
                  setTimeout(() => {
                    setStartDate(moment(endDate).subtract(e.target.value - 1, 'days').format('YYYY-MM-DD'))
                  }, 600)
                }}
                disabled={secondaryLoading}
            />
            </div>
          </div>
        </div>

        <div className='metric-container'>
          <div className='sum-metric'>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Phishing Opens
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.phishingOpens}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Phishing Clicks
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.phishingClicks}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Training Opens
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.trainingOpens}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Training Clicks
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.trainingClicks}</div>
            </div>
          </div>

          <div className={'all-users-select'}>
            <label className='userslist-select-label'>
              <ReactSelect
                classNames={'multiselect-user'}
                options={allUsersOptionList}
                optionComponent={AllUsersOption}
                placeholder={'User...'}
                onChange={handleUserChange}
                value={selectedUser}
                isSearchable={true}
                isClearable={true}
                isDisabled={secondaryLoading}
                optionHeight={50}
                rows={6}
              />
            </label>
          </div>
        </div>

        <div className={cn('chart-metric', {'chart-metric-download': secondaryLoading})}>
          <ChartBarActions chartData={chartData}/>
        </div>
      </div>
    </div>
  )
}
