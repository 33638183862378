import React, { useState, useEffect, useContext } from 'react';
import { useNavigate , useParams } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import { useHttp } from '../hooks/http.hook';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import eye from '.././images/icons/Eye.svg';
import closeEye from '.././images/icons/Eye Slash.svg';
import cn from 'classnames';

export const ResetPassword = () => {
  useTitle("PhishFirewall | Reset password");

  const { showToastMessage } = useContext(AuthContext);
  const { encryptData } = useCrypto();

  const { request, error, clearError } = useHttp();
  const { token } = useParams();
 
  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  });
 
  const [inputError, setError] = useState({
    password: '',
    confirmPassword: ''
  });

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
 
  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }
 
  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
 
      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter a New Password";
          } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{12,50}$/)) {
            stateObj[name] = "The password must contain lowercase letters, uppercase letters, numbers and be at least 12 characters long";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : inputError.confirmPassword;
          }
          break;
 
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match";
          }
          break;
 
        default:
          break;
      }
 
      return stateObj;
    });
  }

  useEffect(() => {
    clearError()
  }, [error, clearError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
  }
  
  const navigate = useNavigate();
  const editHandler = async (e) => {
    try {
      const data = encryptData(input);
      const res = await request(`/back_office/api/auth/reset_password/${token}`, 'POST', { data });
      showToastMessage(res.error, res.message);

      if (!res.error) navigate('/back_office/login');
    } catch (error) {}
  }
 
  return (
    <form className="form" onSubmit={handleSubmit}>
        <div className="title">
          <span className="card-title">Password recovery</span><br/>
          <span className="card-subtitle">
            Your new password must be different from previous used passwords.
          </span>
        </div>
        
        <div className="card-content">
          <div className="input-field">
            <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
            <label htmlFor="password">New Password</label>
            <input
              id="password"
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder='Password...'
              className={cn("input", "validate", {"err-input": inputError.password})}
              value={input.password}
              required
              onChange={onInputChange}
              onBlur={validateInput}></input>
            {inputError.password && <span className='err'>{inputError.password}</span>}
          </div>
  
          <div className="input-field">
            <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              type={passwordShown ? "text" : "password"}
              name="confirmPassword"
              placeholder='Confirm password...'
              className={cn("input", "validate", {"err-input": inputError.confirmPassword})}
              value={input.confirmPassword}
              required
              onChange={onInputChange}
              onBlur={validateInput}></input>
            {inputError.confirmPassword && <span className='err'>{inputError.confirmPassword}</span>}
          </div>
        </div>
        <div className="card-action">
          <button
              onClick={editHandler}
              disabled={
                !input.password || !input.confirmPassword ||
                inputError.password || inputError.confirmPassword
              }
              type="submit"
            >
              Save
          </button>
        </div>
    </form>
  );
}
