import { createContext } from 'react'

function noop() {}

export const AuthContext = createContext({
  token: null,
  userId: null,
  login: noop,
  logout: noop,
  isAuthenticated: false,
  showToastMessage: noop,
  fetchUser: noop,
  user: {},
  planPaused: false,
  dateExpired: false,
  phishReports: {},
  fetchPhishReports: noop,
  allUsers: [],
  groupsLogs: {},
  fetchAllUsers: noop,
  fetchAllTenants: noop
})
