import {useState, useCallback, useEffect} from 'react';
import { storageName, activaTabTime, lastVisitedUrl } from '../config/config';
import { useCrypto } from "../hooks/crypto.hook";
import { removeCookie } from '../common/cookieHandler';

export const useAuth = () => {
  const [token, setToken] = useState(null)
  const [ready, setReady] = useState(false)
  const [userId, setUserId] = useState(null)
  const { decryptData, encryptData } = useCrypto();

  const login = useCallback((jwtToken, id) => {
    setToken(jwtToken)
    setUserId(id)

    localStorage.setItem(storageName, JSON.stringify(encryptData({
      userId: id, token: jwtToken
    })))
  }, [encryptData])


  const logout = useCallback(() => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem(storageName)
    localStorage.removeItem(activaTabTime)
    sessionStorage.removeItem(storageName)
    removeCookie(storageName);
    const channel = new BroadcastChannel("user_activity");
    channel.postMessage("logout");
    sessionStorage.setItem(lastVisitedUrl, window.location.href);
    window.location.pathname = '/back_office/login';

    return () => {
      channel.close();
    };
  }, [])

  useEffect(() => {
    const user = localStorage.getItem(storageName) || sessionStorage.getItem(storageName);
    const userData = JSON.parse(user);

    if (user && typeof userData === "object") {
      logout();
    }

    const data = user ? decryptData(userData) : {};

    if (data && data.token) {
      login(data.token, data.userId)
    }
    setReady(true)
  }, [decryptData, login, logout])

  return { login, logout, token, userId, ready }
}
