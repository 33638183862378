/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import cn from 'classnames'
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { CustomizedTooltip } from '../../components/Tooltips/Tooltip';
import { useCrypto } from '../../hooks/crypto.hook';
import { SetSchedulerModal } from '../SetSchedulerModal/SetSchedulerModal';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
import submit from '../.././images/icons/Check.svg'
import './Google.css';
import { languageOptions } from '../../config/config';

export const GoogleCreateUser = () => {
  const { user, token, showToastMessage, fetchUser } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const navigate = useNavigate();

  const [googleVerified, setGoogleVerified] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);
  const [companyTenants, setCompanyTenants] = useState([]);
  const [input, setInput] = useState({
    tenantName: '',
    inclusionGroup: '',
    exclusionGroup: ''
  });
  const [lang, setLang] = useState('');

  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`langTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };
  const [inputError, setError] = useState({
    tenantName: '',
    inclusionGroup: '',
    exclusionGroup: ''
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editTenant, setEditTenant] = useState({
    tenantId: '',
    tenantName: '',
    type: '',
    scheduledLaunch: null,
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
    navigate('/back_office/user/users_database/google/details');
  };

  const handleLangChange = (data) => {
    setLang(data);
  };
  
  const getGoogleTenants = useCallback(async () => {
    try {
      if (token) {
        const tenants = await request('/back_office/api/user/google_tenants', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyTenants(tenants);
        
        return tenants;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    getGoogleTenants();
  }, [getGoogleTenants]);

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }
 
  const validateInput = e => {
    let { name, value } = e.target;
    const tenantNames = companyTenants.map(tenant =>(tenant.name))
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "tenantName":
          if (!value) {
            stateObj[name] = "Please enter an Tenant Name";
          }
          if(tenantNames.includes(value)){
            stateObj[name] = "This Tenant Name already exists";
          }
          break;
 
        default:
          break;
      }

      return stateObj;
    });
  }
  
  const onSubmit = (event) => {
    event.preventDefault();
  }

  const updateSyncType = async () => {
    try {
      const syncType = 'syncGoogle';
      const res = await request('/back_office/api/user/update_sync_type', 'POST', { syncType }, {
        Authorization: `Bearer ${token}`
      })
      showToastMessage(res.error, res.message);
    } catch (error) {}
  }

  const getGoogleVerified = useCallback(async (tenantName) => {
    try {
        const resTenant = await request(`/back_office/api/user/check_google_verified`, 'POST', { tenantName }, {
          Authorization: `Bearer ${token}`
        })

        setGoogleVerified(resTenant.googleVerified)
        return resTenant;
    } catch (error) {
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  let popup;

  const verifyHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await request('/back_office/api/user/google_verify', 'POST', {tenantName: input.tenantName}, {
        Authorization: `Bearer ${token}`
      })

      const width = 500;
      const height = 650;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 4;
      const title = 'Google Auth';
      const url = res.authUrl;
      let count = 0
      
      popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
      const checkPopup = setInterval(async () => {
        count++;
        if (popup?.closed || count > 600) {
          fetchUser();
          clearInterval(checkPopup);
          popup.close()
          setPopupStatus(true);
        }
        if (!popup?.closed) return;
      }, 1000);
    } catch (error) {console.log(error)}
  }

  useEffect(() => {}, [popup, user.googleVerified]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {if(input.tenantName) getGoogleVerified(input.tenantName)}, [popupStatus]);

  const createHandler = async (e) => {
    try {
      const data = encryptData({...input, language: lang?.label});
      const response = await request('/back_office/api/user/google_create', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      
      showToastMessage(response.error, response.message, response.warning);
      if (response.addData) setEditTenant(decryptData(response.addData));
      if (response.message) {
        const res = await request('/back_office/api/user/google/create_scheduler', 'POST', null, {
          Authorization: `Bearer ${token}`
        })

        showToastMessage(res.error, res.message, res.warning);
        if (res.message) {
          updateSyncType();
          fetchUser();
          openModal();
        }
      }
    } catch (error) {}
  }
  
  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div>
        <p className="details-help">
          <span>Need help setting up Google Workspace? View our setup instructions. </span>
          <a
              target="_blank"
              rel="noreferrer"
              href="https://www.phishfirewall.com/phishfirewall-onboarding-add-users-google-sync/"
            >
              View our setup instructions.
            </a>
        </p>
        
        <div className='edit-main details-card'>
          <div className='details-action'>
            <div>
              <h3 className='details-action-title'>Google workspace</h3>
              <p className='details-action-subtitle'>Set up integration with Google</p>
            </div>
          </div>
          
          <form className="edit-form" onSubmit={onSubmit}>
            <div className="edit-card">
              <div className="edit-content">
                <div className="input-field-tenant">
                  <div className='input-field'>
                    <label htmlFor="tenantName" className="input-label input-label-required">Tenant Name</label>
                    <input
                      id="tenantName"
                      name="tenantName"
                      placeholder="Enter Tenant Name"
                      className={cn("input-edit", "validate", {"err-input": inputError.tenantName})}
                      defaultValue={input?.tenantName}
                      onChange={onInputChange}
                      onBlur={validateInput}
                      disabled={googleVerified}
                    />
                     {inputError.tenantName && <span className='err'>{inputError.tenantName}</span>}
                  </div>
                  <div></div>
                  <div className="google-verify-link">
                    <button
                      className={cn({ 'btn-google': !googleVerified, 'btn-google-verified': googleVerified})}
                      type="button"
                      onClick={verifyHandler}
                      disabled={!input.tenantName || inputError.tenantName}
                    >
                      Verify with Google Account
                    </button>
                  </div>
                </div>

                <div className='input-field-tenant'>
                  <div className='userslist-select'>
                    <label className='userslist-select-label input-label-required'>
                    Phish email language by default
                    </label>
                      <p className="input-description">
                      Select a language for phishing emails. You can customize the language later or choose specific languages for individual users once your user list is synced.
                      </p>
                    <Select
                      id='select'
                      className='validate multiselect-azure'
                      options={languageList}
                      components={{ languageOption }}
                      placeholder="Select language"
                      value={lang}
                      onChange={handleLangChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </div>
                </div>

                <p className="edit-list-description">You can fill in only one of the options below</p>
                <div className="edit-list-groups">
                  <div className="input-field">
                    <label htmlFor="inclusionGroup" className="input-label">Inclusion Group(s)</label>
                    <CustomizedTooltip
                      link={''}
                      name={'Inclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    <input
                      id="inclusionGroup"
                      name="inclusionGroup"
                      placeholder="Enter Inclusion Group"
                      className="validate input-edit"
                      defaultValue={input?.inclusionGroup}
                      onChange={onInputChange}
                      disabled={input.exclusionGroup}
                    />
                  </div>
                  <div className="input-field-or">
                    <span>OR</span>
                  </div>
                  <div className="input-field">
                    <label htmlFor="exclusionGroup" className="input-label input-description">
                      Exclusion Group(s)
                    </label>
                    <CustomizedTooltip
                      link={''}
                      name={'Exclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    
                    <input
                      id="exclusionGroup"
                      name="exclusionGroup"
                      placeholder="Enter Exclusion Group"
                      className="validate input-edit"
                      defaultValue={input?.exclusionGroup}
                      onChange={onInputChange}
                      disabled={input.inclusionGroup}
                    />
                  </div>
                </div>
              </div>

              <div className="edit-submit">
                <button
                  className='button-submit'
                  onClick={createHandler}
                  disabled={!googleVerified}
                  type="submit"
                >
                  <img src={submit} alt="submit"></img>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

        <SetSchedulerModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          editTenant={editTenant}
          setEditTenant={setEditTenant}
          callback={null}
        />
      </div>
      }
    </>
  )
}
