/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import submit from '../.././images/icons/Check.svg'
import { CustomizedTooltip } from '../../components/Tooltips/Tooltip';
import { useCrypto } from '../../hooks/crypto.hook';
import cn from 'classnames'
import './Google.css';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
import { languageOptions } from '../../config/config';

export const GoogleEditUser = () => {
  const { user, token, showToastMessage, fetchUser } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData } = useCrypto();
  const navigate = useNavigate();
  const { id } = useParams();

  const [tenant, setTenant] = useState({});
  const [editForm, setEditForm] = useState({});
  const [googleVerified, setGoogleVerified] = useState(false);
  const [lang, setLang] = useState('');

  const getGoogleVerified = useCallback(async (tenant) => {
    try {
        const resTenant = await request(`/back_office/api/user/check_google_verified`, 'POST', { tenantName: tenant.name }, {
          Authorization: `Bearer ${token}`
        })

        setGoogleVerified(resTenant.googleVerified)
        return resTenant;
    } catch (error) {
      console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);
  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`langTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };
  const getTenant = useCallback(async () => {
    try {
      if (token) {
        const tenant = await request(`/back_office/api/user/google_tenant/${id}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setTenant(tenant);
        setLang(tenant.language ? { value: tenant.language?.toLowerCase(), label: tenant.language } : null);
        getGoogleVerified(tenant);
        setEditForm({
          tenantName: tenant.name,
          inclusionGroup: tenant.inclusionGroup?.activeGroups?.join(', '),
          exclusionGroup: tenant.exclusionGroup?.inactiveGroups?.join(', '),
        });

        return tenant;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const handleLangChange = (data) => {
    setLang(data);
  };

  useEffect(() => {
    getTenant();
  }, [getTenant]);

  const changeEditHandler = (event) => {
    setEditForm({ ...editForm, [event.target.name] : event.target.value.trim() })
  };
  
  const onSubmit = (event) => {
    event.preventDefault();
  }
  
  let popup;
  const verifyHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await request('/back_office/api/user/google_verify', 'POST', {tenantName: editForm.tenantName}, {
        Authorization: `Bearer ${token}`
      })

      const width = 500;
      const height = 650;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 4;
      const title = 'Google Auth';
      const url = res.authUrl;
      let count = 0
      
      popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
      const checkPopup = setInterval(async () => {
        count++;
        if (popup?.closed || count > 600) {
          fetchUser();
          clearInterval(checkPopup);
          popup.close()
        }
        if (!popup?.closed) return;
      }, 1000);
    } catch (error) {console.log(error)}
  }

  useEffect(() => {}, [popup, user.googleVerified]);
  const editHandler = async (e) => {
    try {
      const data = encryptData({...editForm, id: tenant?.id, language: lang?.label})
      const response = await request('/back_office/api/user/google_edit', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      
      showToastMessage(response.error, response.message, response.warning);
      const res = await request('/back_office/api/user/google/create_scheduler', 'POST', null, {
        Authorization: `Bearer ${token}`
      })
      
      showToastMessage(res.error, res.message, res.warning);
      if (res.message) {
        fetchUser();
        navigate('/back_office/user/users_database/google/details');
      }
    } catch (error) {}
  }
  
  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div>
        <p className="details-help">
          <span>Need help setting up Google Workspace? View our setup instructions. </span>
          <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.phishfirewall.com/onboarding-instructions/"
            >
              View our setup instructions.
            </a>
        </p>
        
        <div className='edit-main details-card'>
          <div className='details-action'>
            <div>
              <h3 className='details-action-title'>Google workspace</h3>
              <p className='details-action-subtitle'>Set up integration with Google</p>
            </div>
          </div>
          
          <form className="edit-form" onSubmit={onSubmit}>
            <div className="edit-card">
              <div className="edit-content">
                <div className=" input-field-tenant">
                  <div className='input-field'>
                    <label htmlFor="tenantName" className="input-label input-label-required">Tenant Name</label>
                    <input
                      id="tenantName"
                      name="tenantName"
                      placeholder="Enter Tenant Name"
                      className="validate input-edit"
                      defaultValue={tenant?.name}
                      onChange={changeEditHandler}
                    />
                  </div>
                  <div></div>
                  <div className="google-verify-link">
                    <button
                      className={cn({ 'btn-google': !googleVerified, 'btn-google-verified': googleVerified})}
                      type="button"
                      onClick={verifyHandler}
                      disabled
                    >
                      Verify with Google Account
                    </button>
                  </div>
                </div>

                <div className='input-field-tenant'>
                  <div className='userslist-select'>
                    <label className='userslist-select-label input-label-required'>
                    Phish email language by default
                    </label>
                      <p className="input-description">
                      Select a language for phishing emails. You can customize the language later or choose specific languages for individual users once your user list is synced.
                      </p>
                    <Select
                      id='select'
                      className='validate multiselect-azure'
                      options={languageList}
                      components={{ languageOption }}
                      placeholder="Select language"
                      value={lang}
                      onChange={handleLangChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </div>
                </div>

                <p className="edit-list-description">You can fill in only one of the options below</p>
                <div className="edit-list-groups">
                  <div className="input-field">
                    <label htmlFor="inclusionGroup" className="input-label">Inclusion Group(s)</label>
                    <CustomizedTooltip
                      link={''}
                      name={'Inclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    <input
                      id="inclusionGroup"
                      name="inclusionGroup"
                      placeholder="Enter Inclusion Group"
                      className="validate input-edit"
                      defaultValue={tenant?.inclusionGroup?.activeGroups.join(', ')}
                      onChange={changeEditHandler}
                      disabled={editForm.exclusionGroup}
                    />
                  </div>
                  <div className="input-field-or">
                    <span>OR</span>
                  </div>
                  <div className="input-field">
                    <label htmlFor="exclusionGroup" className="input-label input-description">
                      Exclusion Group(s)
                    </label>
                    <CustomizedTooltip
                      link={''}
                      name={'Exclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    
                    <input
                      id="exclusionGroup"
                      name="exclusionGroup"
                      placeholder="Enter Exclusion Group"
                      className="validate input-edit"
                      defaultValue={tenant?.exclusionGroup?.inactiveGroups.join(', ')}
                      onChange={changeEditHandler}
                      disabled={editForm.inclusionGroup}
                    />
                  </div>
                </div>
              </div>

              <div className="edit-submit">
                <button
                  className='button-submit'
                  onClick={editHandler}
                  disabled={!googleVerified}
                  type="submit"
                >
                  <img src={submit} alt="submit"></img>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      }
    </>
  )
}
