import React from 'react';
import cn from 'classnames';
import { CustomizedTooltipInfo } from '../../Tooltips/Tooltip';

export const EducationMetrics = ({ educationData, loading }) => {
  return (
    <div className='analytics-metrics analytics-metrics-container-education'>
      <div className='analytics-metrics-item'>
        <h4>Education Metrics</h4>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.engagementRate || '0')?.toString()?.replace('.00', '')}%</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': educationData.diffEngagementRate > 0,
                  'analytics-metrics-value-prev--negative': educationData.diffEngagementRate < 0
                })}>
                  {educationData.diffEngagementRate > 0 && '+'}
                  {!!educationData.diffEngagementRate && educationData.diffEngagementRate?.toString()?.replace('.00', '') + '%'}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>
            Engagement Rate
            <CustomizedTooltipInfo
              text={
                `Engagement Rate is calculated only for full completed months.`
              }
            />
          </div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.totalEducationalEmailsSent || 0).toLocaleString('en-US')}</span>
              </div>
          }
          <div className='analytics-metrics-label'>Educational Emails Sent</div>
        </div>

        {/* Temporary disabled Non Engaged Users */}
        {/* <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.nonEngagedUsersCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': educationData.diffNonEngagedUsersCount < 0,
                  'analytics-metrics-value-prev--negative': educationData.diffNonEngagedUsersCount > 0
                })}>
                  {educationData.diffNonEngagedUsersCount > 0 && '+'}
                  {!!educationData.diffNonEngagedUsersCount && educationData.diffNonEngagedUsersCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Non Engaged Users</div>
        </div> */}

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.underEngagedUsersCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': educationData.diffUnderEngagedUsersCount < 0,
                  'analytics-metrics-value-prev--negative': educationData.diffUnderEngagedUsersCount > 0
                })}>
                  {educationData.diffUnderEngagedUsersCount > 0 && '+'}
                  {!!educationData.diffUnderEngagedUsersCount && educationData.diffUnderEngagedUsersCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Under Engaged Users</div>
        </div>
      </div>

      <div className='analytics-metrics-item'>
        <h4>Compliance</h4>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.nonCompliantUserPercentage || '0')?.toString()?.replace('.00', '')}%</span>
              </div>
          }
          <div className='analytics-metrics-label'>Non-Compliant User Percentage</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(educationData.nonCompliantUsersCount || 0).toLocaleString('en-US')}</span>
              </div>
          }
          <div className='analytics-metrics-label'>Total Non-Compliant Users</div>
        </div>
      </div>

      <div className='analytics-metrics-item'>
        <h4>Core Metrics</h4>
        <div className='analytics-metrics-item-general'>
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(educationData.totalUsersCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Total Users</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(educationData.recentUsersCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Total New Users</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
              <span className='analytics-metrics-value-current'>
                {(educationData.totalUserGroups || 0).toLocaleString('en-US')}
              </span>
            </div>
          }
          <div className='analytics-metrics-label'>Total Groups Enabled</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(educationData.mandatoryTrainingFlowsCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Total Mandatory Training Flows Enabled</div>
        </div>
      </div>
    </div>
  )
}
