import React from 'react';
import cn from 'classnames';
import './Risk.css';

export const RiskMetrics = ({ riskData, loading }) => {
  return (
    <div className='analytics-metrics'>
      <h4>Risk Metrics</h4>

      <div className='analytics-metrics-container-risk'>
        <div className='analytics-metrics-item'>
          <div className='analytics-metrics-item-container'>
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(riskData.phishRisk || 0)?.toString()?.replace('.00', '')}%</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': riskData.diffPhishRisk < 0,
                    'analytics-metrics-value-prev--negative': riskData.diffPhishRisk > 0
                  })}>
                    {riskData.diffPhishRisk > 0 && '+'}
                    {!!riskData.diffPhishRisk && riskData.diffPhishRisk?.toString()?.replace('.00', '') + '%'}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Human Risk Rate</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className='analytics-metrics-item-container'>
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(riskData.highRiskUserCount || 0).toLocaleString('en-US')}</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': riskData.diffHighRiskUserCount < 0,
                    'analytics-metrics-value-prev--negative': riskData.diffHighRiskUserCount > 0
                  })}>
                    {riskData.diffHighRiskUserCount > 0 && '+'}
                    {!!riskData.diffHighRiskUserCount && riskData.diffHighRiskUserCount}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Total High Risk Users</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(riskData.moderateRiskUserCount || 0).toLocaleString('en-US')}</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': riskData.diffModerateRiskUserCount < 0,
                    'analytics-metrics-value-prev--negative': riskData.diffModerateRiskUserCount > 0
                  })}>
                    {riskData.diffModerateRiskUserCount > 0 && '+'}
                    {!!riskData.diffModerateRiskUserCount && riskData.diffModerateRiskUserCount}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Total Moderate Risk Users</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className="analytics-metrics-item-container">
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>{(riskData.realPhishReportedEmails || 0).toLocaleString('en-US')}</span>
                  <span className={cn('analytics-metrics-value-prev', {
                    'analytics-metrics-value-prev--positive': riskData.diffRealPhishReportedEmails > 0,
                    'analytics-metrics-value-prev--negative': riskData.diffRealPhishReportedEmails < 0
                  })}>
                    {riskData.diffRealPhishReportedEmails > 0 && '+'}
                    {!!riskData.diffRealPhishReportedEmails && riskData.diffRealPhishReportedEmails}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Real Phish Reported</div>
          </div>
        </div>

        <div className='analytics-metrics-item'>
          <div className='analytics-metrics-item-general'>
            {loading
              ? <div className='metrics-loader-container'>
                  <span className='metrics-loader'></span>
                </div>
              : <div className='analytics-metrics-value'>
                  <span className='analytics-metrics-value-current'>
                    {(riskData.totalUsersCount || 0).toLocaleString('en-US')}
                  </span>
                </div>
            }
            <div className='analytics-metrics-label'>Total users</div>
          </div>
      </div>
      </div>
    </div>
  );
}
