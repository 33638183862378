import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { useAuth } from '../../../hooks/auth.hook';
import { AuthContext } from '../../../context/AuthContext';
import { useSortableData } from '../../../hooks/sort.hook';
import { getClassNamesFor } from '../../../common/getClassNamesFor';
import colourStyles from '../../../styles/colour-style';
import button from '../../..//images/icons/Button.svg'
import Select, { components } from "react-select";
import { useCrypto } from '../../../hooks/crypto.hook';
import './Segments.css';

export const Segments = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const auth = useContext(AuthContext);

  const [allSegments, setAllSegments] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [card, setCard] = useState(1);
  const [addCard, setAddCard] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1);

  const { items, requestSort, sortConfig } = useSortableData(segments);
  
  function handleSelect(data) {
    setSelectedOptions([data]);
  }

  const fetchAllSegments = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_segments', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptSegments = decryptData(fetched);
        setAllSegments(decryptSegments);
  
        return decryptSegments;
      }
      
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);
  
  const fetchSegments = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/segments', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptSegments = decryptData(fetched);
        setSegments(decryptSegments);
  
        return decryptSegments;
      }
      
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const submitHandler = async (e) => {
    try {
      const data = encryptData(selectedOptions);
      const response = await request('/back_office/api/exclusions/segments', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setSelectedOptions([])
      setSelectedRow(-1)
      setCard(3);
      setAddCard(false);
      fetchSegments();
      fetchAllSegments();

    } catch (error) {}
  }

  useEffect(() => {
    fetchAllSegments();
  }, [fetchAllSegments]);
  
  useEffect(() => {
    fetchSegments()
  }, [fetchSegments]);

  useEffect(() => {
    if (!segments.length) setCard(1)
    else setCard(3)
  }, [segments.length])
  
  if (loading) {
    return <Loader />
  }

  const deleteSegment = async () => {
    try {
      const data = encryptData(selectedRow);
      const response = await request('/back_office/api/exclusions/segments', 'DELETE', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setCard(3);
      fetchSegments();
      fetchAllSegments();

    } catch (error) {}
  }

  const optionList = allSegments.map(segment => (
    { value: segment.id, label: segment.name, caption: segment.id }
  ))

  const Option = (props) => {
    const { label, caption } = props.data;
  
    return (
      <components.Option {...props}>
        <div className='segments-label'>{label}</div>
        <div className='caption'>ID: {caption}</div>
      </components.Option>
    );
  };
  
  return (
    <> {!loading &&
        <div className="segments-main">
          {card === 1 &&
            <div>
              <p className="segments-description">
                There are no exclusions yet.
              </p>
              <button
                className='segments-button'
                onClick={() => setCard(2)}
                disabled={loading}
                type="submit"
              >
                Add segments
              </button>
            </div>
          }
    
          {card === 2 &&
            <div>
              <p className="segments-add-title">
                Add segments
              </p>

              <div className='segments-select'>
                <Select
                  id='select'
                  className='multiselect'
                  options={optionList}
                  components={{ Option }}
                  placeholder="Select segments"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </div>
              <button
                className='segments-button segments-button-simple'
                onClick={submitHandler}
                disabled={!selectedOptions.length}
                type="submit"
              >
                Add segment
              </button>
            </div>
          }

          {card === 3 &&
            <div>
              {!addCard 
                ?
                  <div className='segments-actions'>
                    <p className="audit-desc">Exclusion list of email segments</p>
                    <button
                      className='segments-button'
                      onClick={() => {setAddCard(!addCard); setSelectedRow(-1)}}
                      type="submit"
                    >
                      Add segments
                    </button>
                  </div>
                :
                  <div className="segments-add-actions">
                    <p className="segments-add-title">
                      Add segments
                    </p>

                    <div className='segments-select'>
                      <Select
                        id='select'
                        className='multiselect'
                        options={optionList}
                        components={{ Option }}
                        placeholder="Select segments"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        styles={colourStyles}
                      />
                    </div>
                    <button
                      className='segments-button segments-button-simple'
                      onClick={submitHandler}
                      disabled={!selectedOptions.length}
                      type="submit"
                    >
                      Add segment
                    </button>

                    <p className='segments-add-subtitle'>Exclusion list of segments per company</p>
                  </div>
              }

              <div className="audit table">
                <table className="segments-table">
                  <thead
                    className="table-th"
                  >
                    <tr
                      className="segments-not-clickable segments-tr-grid"
                    >
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('name')}
                          className={getClassNamesFor('name', sortConfig)}
                        >
                          Segment
                        </button>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-body"
                  >
                    {items.map((s) => (
                      <tr
                        key={s.id}
                        onClick={() => setSelectedRow(s.id)}
                        className={"segments-tr-grid segments-clickable-row ".concat(selectedRow === s.id ? "segments-selected" : "")}
                      >
                        <td>{s?.name}</td>
                        <td>
                          <div className='delete-container'>
                            <button
                              type='button'
                              className='button-delete'
                              onClick={deleteSegment}
                              disabled={selectedRow !== s.id}
                            >
                              <img src={button} alt='btn'></img>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
            </div>
          }
        </div>
        }
    </>
  )
}
