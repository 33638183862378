import React, { useEffect, useRef } from 'react';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import colourStyles from '../../styles/colour-style';
import './ReactSelect.css';

const MenuList = ({
  options,
  children,
  getValue,
  optionHeight = 50,
  rows = 6
}) => {
  const [value] = getValue();
  const initialOffset =
    options.indexOf(value) !== -1
      ? Array.isArray(children) && children.length >= rows
        ? options.indexOf(value) >= rows
          ? options.indexOf(value) * optionHeight - optionHeight * (rows - 1)
          : 0
        : 0
      : 0;

  return Array.isArray(children) ? (
    <List
      height={
        children.length >= rows ? optionHeight * rows : children.length * optionHeight
      }
      itemCount={children.length}
      itemSize={optionHeight}
      initialScrollOffset={initialOffset}
    >
      {({ style, index }) => {
        return (
          <div style={{ ...style, overflowX: 'hidden' }} title={children[index]?.props?.data?.label}>
            {children[index]}
          </div>
        );
      }}
    </List>
  ) : (
    <div>{children}</div>
  );
};

const ReactSelect = ({
  options,
  value,
  onChange,
  placeholder,
  optionComponent,
  optionHeight,
  isSearchable,
  isClearable,
  isDisabled,
  isLoading,
  isMulti,
  rows,
  classNames = '',
  closeMenuOnSelect = true,
  menuPlacement = 'bottom'
}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const setDynamicTitle = () => {
      const singleValueElement = selectRef.current?.controlRef.querySelector(
        '.react-select__single-value'
      );

      if (singleValueElement) {
        const containerWidth = singleValueElement.offsetWidth;
        const textWidth = singleValueElement.scrollWidth;

        if (textWidth > containerWidth) {
          singleValueElement.setAttribute('title', singleValueElement.textContent);
        } else {
          singleValueElement.removeAttribute('title');
        }
      }
    };

    setDynamicTitle();
    window.addEventListener('resize', setDynamicTitle);

    return () => window.removeEventListener('resize', setDynamicTitle);
  }, [value]);

  return (
    <Select
      ref={selectRef}
      options={options}
      styles={colourStyles}
      value={value}
      onChange={onChange}
      className={classNames}
      classNamePrefix="react-select"
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      isDisabled={isDisabled}
      filterOption={createFilter({ ignoreAccents: false })}
      menuPlacement={menuPlacement}
      components={{
        MenuList: (props) => (
          <MenuList
            {...props}
            optionHeight={optionHeight}
            rows={rows}
          />
        ),
        Option: optionComponent
      }}
    />
  );
};

export default ReactSelect;
