import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useHttp } from '../../../hooks/http.hook';
import { useCrypto } from '../../../hooks/crypto.hook';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../../context/AuthContext';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { UserAnalyticsFilterBar } from '../UserAnalyticsFilterBar/UserAnalyticsFilterBar';
import { SummaryMetrics } from './SummaryMetrics';
import { TrendGraph } from '../TrendGraph/TrendGraph';
import { ProneTable } from '../ProneTable/ProneTable';
import { Loader } from '../../Loader/Loader';
import './ExecutiveSummary.css';

export const ExecutiveSummary = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations
  } = useContext(AnalyticsGeneralContext);

  const { token } = useContext(AuthContext);
  const { request, loading } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [maxMailingDate, setMaxMailingDate] = useState(moment().format('YYYY-MM-DD'));
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptionsAnalytics[0]);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
  }, [contextDepartments, contextLocations, contextTenants]);

  const fetchSummaryData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const responseSummaryData = await request('/back_office/api/analytics/summary', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptSummaryData = decryptData(responseSummaryData);
        setSummaryData(decryptSummaryData);

        setFilteredMinMailingDate(decryptSummaryData.filteredMinMailingDate);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptSummaryData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptSummaryData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptSummaryData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptSummaryData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, minMailingDate, maxMailingDate]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  return (
    <>
      <UserAnalyticsFilterBar
        loading={loading}
        tenants={tenants}
        departments={departments}
        locations={locations}
        minMailingDate={minMailingDate}
        setMinMailingDate={setMinMailingDate}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateRangeOptionsList={dateRangeOptionsList}
        filteredMinMailingDate={filteredMinMailingDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setDateRangeOptionsList={setDateRangeOptionsList}
        maxMailingDate={maxMailingDate}
        setMaxMailingDate={setMaxMailingDate}
      />

      <SummaryMetrics
        summaryData={summaryData}
        loading={loading}
      />

      <div className='trend-graphs-container'>
        <TrendGraph
          title='Phish Click Trend Graph'
          loading={loading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={summaryData?.phishClickTrendGraphData || {}}
          currValue={summaryData?.phishClickRate || '0'}
          diffValue={summaryData?.diffPhishClickRate || 0}
          tooltipName={'Phish Click Rate'}
          percentage={true}
          plusIsPositive={false}
          stepSizeY={10}
        />
        <TrendGraph
          highlited={true}
          title='Human Risk Rate Graph'
          loading={loading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={summaryData?.breachRiskTrendGraphData || {}}
          currValue={summaryData?.phishRisk || '0'}
          diffValue={summaryData?.diffPhishRisk || 0}
          tooltipName={'Human Risk Rate'}
          percentage={true}
          plusIsPositive={false}
          stepSizeY={10}
          percentageTicks={true}
        />
      </div>

      <div className={cn('trend-graphs-container', {
        'trend-graphs-container--loading': loading
      })}>
        <TrendGraph
          highlited={true}
          title='Educational Engagement Trend Graph'
          loading={loading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={summaryData?.engagementTrendGraphData || {}}
          currValue={summaryData?.engagementRate || '0'}
          diffValue={summaryData?.diffEngagementRate || 0}
          tooltipName={'Engagement Rate'}
          percentage={true}
          plusIsPositive={true}
        />
        <TrendGraph
          title='Reporting Trend Graph'
          loading={loading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={summaryData?.reportingTrendGraphData || {}}
          currValue={summaryData?.phishSimulationsReportRate || '0'}
          diffValue={summaryData?.diffPhishSimulationsReportRate || 0}
          tooltipName={'Reporting Rate'}
          percentage={true}
          plusIsPositive={true}
        />
      </div>

      {!loading
        ? <div className='prone-tables-container'>
            <ProneTable tablesData={summaryData?.tablesData}/>
          </div>
        : <Loader custom={true} />
      }
    </>
  );
};
