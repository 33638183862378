import React, { useState, useId, useRef, useContext, useEffect, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { Loader } from '../../components/Loader/Loader';
import './CustomerSupport.css';

const options = [ 'High', 'Normal', 'Low'];

export const CustomerSupport = () => {
  useTitle("PhishFirewall | CustomerSupport");
  
  const textAreaId = useId();
  const inputRef = useRef(null);
  const { showToastMessage, token } = useContext(AuthContext);
  const { request } = useHttp();
  const { decryptData, encryptData } = useCrypto();
  const [card, setCard] = useState(1);
  const [selectedOption, setSelectedOption] = useState({ value: 'Normal', label: 'Normal' });
  const [requestContent, setRequestContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [supportTickets, setSupportTickets] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchSupportTickets = useCallback(async () => {
    try {
      if (token) {
        setFetchLoading(true);
        const fetched = await request('/back_office/api/support', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptTickets = decryptData(fetched);
        setSupportTickets(decryptTickets);

        if (decryptTickets.length) {
          showToastMessage(fetched.error, fetched.message)
        }
        setFetchLoading(false);
        return decryptTickets;
      }
    } catch (error) { setFetchLoading(false) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchSupportTickets();
  }, [fetchSupportTickets]);

  const createTicket = async (description, priority, file) => {
    try {
      if (token) {
        setRequestLoading(true);
        const data = encryptData({ description, priority });
        const res = await request(`/back_office/api/support/new`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })
        
        if (file && res.data) {
          const decryptedData = decryptData(res.data)
          const { newIssueId } = decryptedData;
          
          const formData = new FormData();
          formData.append('file', file);
          formData.append('issueId', newIssueId);

          const url = `/back_office/api/support/new/attachment`
          const response = await axios.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).catch(err => console.log(err));
          showToastMessage(response.error);
        }
        showToastMessage(res.error, res.message, res.warning);
        setRequestLoading(false);
        
        if (res.message) {
          setCard(1);
          setRequestContent('');
          setSelectedOption({ value: 'Normal', label: 'Normal' });
          setSelectedFile(null);
          fetchSupportTickets();
        }

        return res;
      }
    } catch (error) { setRequestLoading(false) }
  }

  const handleUploadClick = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  useEffect(() => {}, [inputRef]);

  const handleFileChange = (event) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    const file = event.target.files[0];
    if (file && file.size > maxSizeInBytes) {
      showToastMessage('The file exceeds the maximum size. Please select another file.')
      event.target.value = null;
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };
  
  const optionList = options.map((template) => (
    { value: template, label: template }
  ))

  const handleSelect = (data) => {
    setSelectedOption(data);
  }

  const Option = (props) => {
    const { value } = props.data;
  
    return (
      <components.Option {...props}>
        <div className='support-label'>{value}</div>
      </components.Option>
    );
  };

  const toggleExpand = (rowIndex) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[rowIndex] = !newExpandedRows[rowIndex];
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className="support-main">
      <h2 className="support-main-title">
        Customer support
      </h2>
      <div className='support-card'>
        <h4>
          {card === 1
            ? 'Need some help?'
            : 'Support request'
          }
        </h4>
        <p>Please start by sending a message to our customer support team.</p>
        {card === 1
          ? <button
              className='support-button'
              type='button'
              onClick={() => setCard(2)}
            >
              Write a request
            </button>
          : <form>
              <span className='support-label-span'>Priority</span>
              <Select
                id='select'
                className='support-select'
                options={optionList}
                components={{ Option }}
                placeholder="Select..."
                defaultValue={{ value: 'Medium', label: 'Medium' }}
                value={selectedOption}
                onChange={handleSelect}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={colourStyles}
              />

              <label htmlFor={textAreaId}>
                Reason for Support Request
              </label><br/>
              <textarea
                id={textAreaId}
                name='requestContent'
                placeholder='Please type your request here...'
                value={requestContent}
                onChange={e => setRequestContent(e.target.value)}
              />

              <div>
                <button className='attach-button-support' onClick={handleUploadClick}>
                  {selectedFile ? `${selectedFile.name}` : 'Attach a file'}
                </button>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .docx, .csv, .xls, .xlsx"
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />

                {selectedFile && <button onClick={() => setSelectedFile(null)} type='button' className='attach-button-support-cross'></button>}
              </div>
              <div className='support-actions'>
                <div className='button-loader'>
                  {requestLoading && <span className="loader-small"></span>}
                  <button
                    type='button'
                    onClick={() => createTicket(requestContent, selectedOption.value, selectedFile)}
                    className={cn("support-button-submit", { "support-button-submit-loading": requestLoading })}
                    disabled={!requestContent || requestLoading}
                  >
                    Submit
                  </button>
                </div>
                <button
                  type='button'
                  className="support-button-cancel"
                  onClick={() => setCard(1)}
                  disabled={requestLoading}
                >
                  Cancel
                </button>
              </div>
            </form>
        }
      </div>

      <div className='support-card-table'>
        <h3 className='support-card-title'>Support requests</h3>
        {!fetchLoading
          ? !!supportTickets.length
            ? <table className="support-table">
                <thead className="table-th-support">
                  <tr className="not-clickable-support tr-grid-support tr-support">
                    <th>Sent</th>
                    <th>Priority</th>
                    <th>Reason</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody
                  className="table-body table-body-support"
                >
                  {supportTickets.sort((a, b) => new Date(b.sent) - new Date(a.sent)).map((row, rowIndex) => {
                    const isExpanded = expandedRows[rowIndex];
                    return (
                      <tr key={rowIndex} className={"tr-grid-support clickable-row-support tr-support"}>
                        <td>{moment(row.sent).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                          <div>{row.priority}</div>
                        </td>
                        <td className="text-cell">
                          <p className={cn('text-content', {
                            'expanded': isExpanded
                          })}>
                            {row.reason.split('\n').map(r => (
                              <span>{r}<br/></span>
                            ))}
                          </p>
                          <br/>
                          {row.reason && (row.reason.length > 216 || row.reason.split('\n').length > 2) &&
                            <button
                              className={cn('expand-button', {
                                'expanded': isExpanded
                              })}
                              onClick={() => toggleExpand(rowIndex)}
                            >
                              {!isExpanded ? 'View details' : 'Hide details'}
                            </button>
                          }
                        </td>
                        <td>
                          <div className={cn('support-status', {
                            'support-status-pending': row.status === 'Pending',
                            'support-status-in-progres': row.status === 'In progress',
                            'support-status-completed': row.status === 'Completed',
                          })}>{row.status}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            : <span>You don't have a support ticket yet!</span>
          : <Loader />
        }
      </div>
    </div>
  )
}
