import './EmailSignatures.css';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const URLsEdit = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const [companyMetadata, setCompanyMetadata] = useState({});
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  const getMetadata = useCallback(async () => {
    try {
      if (token) {
        const metadata = await request('/back_office/api/user/company_metadata', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyMetadata(metadata);

        return metadata;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const handleOnSubmit = async () => {
    try {
      if (token) {
        await request(`/back_office/api/user/company_metadata_edit`, 'POST', companyMetadata, {
          Authorization: `Bearer ${token}`
        })
      }
      return navigate(`/back_office/email_settings/urls`)
    } catch (error) { }
  }

  const getInnertext = (html) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, "text/html");

    return document?.getElementsByTagName('a')[0]?.innerText.replace('{{Recipient.Company}}', user.companyName?.split(' ').join(''))
  }

  const handleLinkChange = (id, value) => {
    const parser = new DOMParser();
    let document = parser.parseFromString(companyMetadata[id], "text/html");
    const links = document.getElementsByTagName('a');
    if (links.length > 0) {
      links[0].innerText = value;
    } else {
      return setCompanyMetadata((prevState) => { return { ...prevState, [id]: value } })
    }

    return setCompanyMetadata((prevState) => { return { ...prevState, [id]: document.documentElement.getElementsByTagName('a')[0].outerHTML } })
  }

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);
  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details">
            <div className='details-card-phish'>
              <div className="details-account-list-phish">
                <form>
                  <div className="input-field">
                    <label htmlFor="microsoftUrl" className="input-label">IT-Microsoft Migration URL</label>
                    <input
                      id="microsoftUrl"
                      name="microsoftUrl"
                      placeholder="Enter a URL link"
                      className="validate input-edit"
                      defaultValue={getInnertext(companyMetadata['114'])}
                      onChange={(e) => handleLinkChange('114', e.target.value)}
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="updateUrl" className="input-label">Software Update Email URL</label>

                    <input
                      id="updateUrl"
                      name="updateUrl"
                      placeholder="Enter a URL link"
                      className="validate input-edit"
                      defaultValue={getInnertext(companyMetadata['563'])}
                      onChange={(e) => handleLinkChange('563', e.target.value)}
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="followUpUrl" className="input-label">Software Update FollowUp URL</label>

                    <input
                      id="followUpUrl"
                      name="followUpUrl"
                      placeholder="Enter a URL link"
                      className="validate input-edit"
                      defaultValue={getInnertext(companyMetadata['565'])}
                      onChange={(e) => handleLinkChange('565', e.target.value)}
                    />

                  </div>
                  <div className="input-field">
                    <label htmlFor="tenantName" className="input-label">Subdomain</label>

                    <input
                      id="tenantName"
                      name="tenantName"
                      placeholder="Enter Tenant Name"
                      className="validate input-edit"
                      defaultValue={companyMetadata['294']}
                      onChange={(e) => handleLinkChange('294', e.target.value)}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleOnSubmit()
                    }}
                    className="button-submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
