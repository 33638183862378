import React from 'react';
import cn from 'classnames';
import './Loader.css';

export const Loader = React.memo(({ custom }) => (
  <div className={cn('lds-roller', {
    'lds-roller-custom': custom
  })}>
    <div className='lds-spinner'>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
));
