import React, { useCallback, useEffect, useState, useContext } from 'react';
import Select, { components } from "react-select";
import cn from 'classnames';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { useAuth } from '../../../hooks/auth.hook';
import { AuthContext } from '../../../context/AuthContext';
import { useSortableData } from '../../../hooks/sort.hook';
import { getClassNamesFor } from '../../../common/getClassNamesFor';
import { useCrypto } from '../../../hooks/crypto.hook';
import colourStyles from '../../../styles/colour-style';
import button from '../../..//images/icons/Button.svg'
import ReactSelect from '../../../components/ReactSelect/ReactSelect';
import { AllUsersOption } from '../../../components/ReactSelect/ReactSelectOption';
import './SegmentsPerUser.css';

export const SegmentsPerUser = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const auth = useContext(AuthContext);

  const [allSegments, setAllSegments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [segmentSelectedOptions, setSegmentSelectedOptions] = useState([]);
  const [userSelectedOptions, setUserSelectedOptions] = useState([]);
  const [segments, setSegments] = useState([]);
  const [card, setCard] = useState(1);
  const [addCard, setAddCard] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1);

  const { items, requestSort, sortConfig } = useSortableData(segments);

  function segmentHandleSelect(data) {
    setSegmentSelectedOptions([data]);
  }
  function userHandleSelect(data) {
    setUserSelectedOptions([data]);
  }

  const fetchAllSegments = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_segments_per_user', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptSegments = decryptData(fetched);
        setAllSegments(decryptSegments);

        return decryptSegments;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_users_per_segment', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        
        const decryptUsers = decryptData(fetched);
        setAllUsers(decryptUsers);

        return decryptUsers;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchSegments = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/segments_per_user', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptSegments = decryptData(fetched);
        setSegments(decryptSegments);

        return decryptSegments;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const submitHandler = async (e) => {
    try {
      const preparedData = {
        segmentSelectedOptions,
        userSelectedOptions
      }
      const data = encryptData(preparedData);
      const response = await request('/back_office/api/exclusions/segments_per_user', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setSegmentSelectedOptions([])
      setUserSelectedOptions([])
      setSelectedRow(-1)
      setCard(3);
      setAddCard(false);
      fetchSegments()

    } catch (error) {}
  }

  useEffect(() => {
    fetchSegments()
  }, [fetchSegments]);

  useEffect(() => {
    fetchAllSegments();
    fetchAllUsers()
  }, [fetchAllSegments, fetchAllUsers]);

  useEffect(() => {
    if (!segments.length) setCard(1)
    else setCard(3)
  }, [segments.length])

  if (loading) {
    return <Loader />
  }

  const deleteSegment = async () => {
    try {
      const data = encryptData(selectedRow);
      const response = await request('/back_office/api/exclusions/segments_per_user', 'DELETE', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setCard(3)
      fetchSegments()

    } catch (error) {}
  }

  const segmentsOptionList = allSegments.map(segment => ({
    value: segment.id,
    valueSegment: segment.id,
    label: segment.name,
    labelSegment: segment.name,
    captionSegment: segment.id
  }))
  
  const usersOptionList = allUsers.map(user => ({
    value: user.id,
    label: user.email,
    caption: user.firstName + ' ' + user.secondName
  }))

  const Option = (props) => {
    const { labelSegment, captionSegment } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn({'segments-label': labelSegment})}>
          {labelSegment}
        </div>
        <div className='caption'>
          {captionSegment && <p>ID: {captionSegment}</p>}
        </div>
      </components.Option>
    );
  };

  return (
    <> {!loading &&
        <div className="segments-main">
          {card === 1 &&
            <div>
              <p className="segments-description">
                There are no exclusions yet.
              </p>
              <button
                className='segments-button'
                onClick={() => setCard(2)}
                disabled={loading}
                type="submit"
              >
                Add segments
              </button>
            </div>
          }

          {card === 2 &&
            <div>
              <p className="segments-add-title">
                Add segment
              </p>

              <p className="segments-add-select">Select segment</p>
              <div className='segments-select'>
                <Select
                  id='select'
                  className='multiselect'
                  options={segmentsOptionList}
                  components={{ Option }}
                  placeholder="Select segment"
                  value={segmentSelectedOptions}
                  onChange={segmentHandleSelect}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </div>

              <p className="segments-add-and">and</p>

              <p className="segments-add-select">Select user</p>
              <div className='all-users-select-multi'>
                <label className='userslist-select-label'>
                  <ReactSelect
                    classNames={'multiselect-user'}
                    options={usersOptionList}
                    optionComponent={AllUsersOption}
                    placeholder={'Select user'}
                    value={userSelectedOptions}
                    onChange={userHandleSelect}
                    isSearchable={true}
                    optionHeight={50}
                    rows={6}
                  />
                </label>
              </div>
              <button
                className='segments-button segments-button-simple'
                onClick={submitHandler}
                disabled={!userSelectedOptions.length || !segmentSelectedOptions.length}
                type="submit"
              >
                Add segment
              </button>

            </div>
          }

          {card === 3 &&
            <div>
              {!addCard 
                ?
                  <div className='segments-actions'>
                    <p className="audit-desc">Exclusion list of email segments</p>
                    <button
                      className='segments-button'
                      onClick={() => {setAddCard(!addCard); setSelectedRow(-1)}}
                      type="submit"
                    >
                      Add segments
                    </button>
                  </div>
                :
                  <div className="segments-add-actions">
                    <p className="segments-add-title">
                      Add segment
                    </p>

                    <p className="segments-add-select">Select segment</p>
                    <div className='segments-select'>
                      <Select
                        id='select'
                        className='multiselect'
                        options={segmentsOptionList}
                        components={{ Option }}
                        placeholder="Select segment"
                        value={segmentSelectedOptions}
                        onChange={segmentHandleSelect}
                        isSearchable={true}
                        styles={colourStyles}
                      />
                    </div>

                    <p className="segments-add-and">and</p>

                    <p className="segments-add-select">Select user</p>
                    <div className='all-users-select-multi'>
                      <label className='userslist-select-label'>
                        <ReactSelect
                          classNames={'multiselect-user'}
                          options={usersOptionList}
                          optionComponent={AllUsersOption}
                          placeholder={'Select user'}
                          value={userSelectedOptions}
                          onChange={userHandleSelect}
                          isSearchable={true}
                          optionHeight={50}
                          rows={6}
                        />
                      </label>
                    </div>
                    <button
                      className='segments-button segments-button-simple'
                      onClick={submitHandler}
                      disabled={!userSelectedOptions.length || !segmentSelectedOptions.length}
                      type="submit"
                    >
                      Add segment
                    </button>

                    <p className='segments-add-subtitle'>Exclusion list of segments per user</p>
                  </div>
              }

              <div className="audit table">
                <table className="segments-table">
                  <thead
                    className="table-th"
                  >
                    <tr
                      className="segments-not-clickable tr-grid-spu"
                    >
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          User Name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          User email
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('segmentName')}
                          className={getClassNamesFor('segmentName', sortConfig)}
                        >
                          Segment
                        </button>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-body"
                  >
                    {items.map((s) => (
                      <tr
                        key={s.id}
                        onClick={() => setSelectedRow(s.id)}
                        className={"tr-grid-spu segments-clickable-row ".concat(selectedRow === s.id ? "segments-selected" : "")}
                      >
                        <td>{s?.firstName} {s?.secondName}</td>
                        <td>{s?.email}</td>
                        <td>{s?.segmentName}</td>
                        <td>
                          <div className='delete-container'>
                            <button
                              type='button'
                              className='button-delete'
                              onClick={deleteSegment}
                              disabled={selectedRow !== s.id}
                            >
                              <img src={button} alt='btn'></img>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
            </div>
          }
        </div>
        }
    </>
  )
}
