
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import copy from '../../images/icons/copy.svg';
import cn from 'classnames';
import { customStyles } from '../../styles/customStyles';
import './APIManagment.css';

export const APIManagment = () => {
  useTitle("PhishFirewall | API managment")

  const { token } = useAuth();
  const { loading, request } = useHttp();
  const { showToastMessage } = useContext(AuthContext);

  const [apiKey, setApiKey] = useState('');
  const [card, setCard] = useState(1);

  const [showFullKey, setShowFullKey] = useState(false);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleButtonClick = () => {
    setShowFullKey(!showFullKey);
  };

  let firstFourChars, lastFourChars, middleStars, displayKey = '';
  if (apiKey) {
    firstFourChars = apiKey.substring(0, 4);
    lastFourChars = apiKey.substring(apiKey.length - 4);
    middleStars = '*'.repeat(apiKey.length - 8);

    displayKey = showFullKey ? apiKey : `${firstFourChars}${middleStars}${lastFourChars}`;
  };

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setShowFullKey(false);
      setIsTextSelected(false);
      showToastMessage(null, 'API key copied to the clipboard');
    } catch (error) {
      showToastMessage('Could not copy the key');
    }
  }, [apiKey, showToastMessage]);

  const handleCopy = (event) => {
    const selectedText = window.getSelection().toString();

    if (selectedText !== displayKey) {
      event.preventDefault();
      showToastMessage('You cannot copy only a part of the API key!');
    }
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      setIsTextSelected(selection && selection.toString().length === displayKey.length);
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [displayKey]);

  useEffect(() => {
    isTextSelected && showFullKey && document.addEventListener('copy', handleCopyClick);
    return () => {
      document.removeEventListener('copy', handleCopyClick);
    };
  }, [handleCopyClick, isTextSelected, showFullKey]);


  const fetchApiKey = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/user/api_key', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptApiKey = fetched.apiKey;

        if (decryptApiKey) setCard(2);
        setApiKey(decryptApiKey);
        
        return decryptApiKey;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchApiKey()
  }, [fetchApiKey]);

  const createApiKey = async (e) => {
    e.preventDefault();
    try {
      const res = await request('/back_office/api/user/api_key', 'POST', null, {
        Authorization: `Bearer ${token}`
      });

      const decryptApiKey = res.apiKey;

      if (decryptApiKey) setCard(2);
      setApiKey(decryptApiKey);
      
      showToastMessage(res.error, res.message);
      return decryptApiKey;
    } catch (error) {
      console.log(error);
    }
  };
  
  const deleteApiKey = async (e) => {
    try {
      closeRemoveModal();
      setSuccess(true);

      setTimeout(async () => {
        const res = await request('/back_office/api/user/api_key', 'DELETE', null, {
          Authorization: `Bearer ${token}`
        });
        setSuccess(false);
        fetchApiKey();
        setCard(1);
        showToastMessage(res.error, res.message);
      }, 1000)
    } catch (error) {
      console.log(error);
    }
  };

  const openModalRemove = () => {
    setModalRemoveIsOpen(true);
  }
  const closeRemoveModal = () => {
    setModalRemoveIsOpen(false);
  }

  return (
    <>
      <div className="api-mamagment-main">
        <h2 className="api-mamagment-main-title">
          API management
        </h2>
        <div className='api-mamagment-card no-select'>
          <h3 className='api-mamagment-card-title'>API key</h3>
          {!loading
            ? <div className='api-mamagment-card-action'>
                {card === 2 &&
                  <div className='api-mamagment-container'>
                    <p>Your key to use in PhishFirewall API</p>
                    <div onCopy={handleCopy} className={cn('api-mamagment-key-background', { 'allow-select': showFullKey })}>
                      {displayKey}
                      {showFullKey &&
                        <img onClick={handleCopyClick} src={copy} alt='copy'></img>
                      }
                    </div>

                    <div className='api-mamagment-card-actions'>
                      <button onClick={handleButtonClick}>
                        {showFullKey ? 'Hide Key' : 'Show Key'}
                      </button>

                      <div
                        className={cn("api-mamagment-button-remove", {
                          'success': success
                        })}
                        role="button"
                        onClick={() => {
                          openModalRemove()
                        }}
                      >
                        <span className="remove">remove</span>
                        <div className="icon-remove">
                          <i className="fa fa-remove"></i>
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {card === 1 &&
                  <>
                    <span>
                      To use the PhishFirewall API, you need to have your own API key.<br/>
                      Would you like to create one?
                    </span>
                    <button onClick={createApiKey}>Create API key</button>
                  </>
                }
              </div>
            : <Loader />}
        </div>
      </div>

      <Modal
          isOpen={modalRemoveIsOpen}
          onRequestClose={closeRemoveModal}
          style={customStyles}
          contentLabel="Example Modal"
          closeTimeoutMS={200}
        >
          <button onClick={closeRemoveModal} className="modal-close"></button>
          <form className="form">
            <div className="title">
              <span className="card-title ">
                Delete API key
              </span><br/>
            </div>

            <div className='remove-form'>
              <p>
                This action is irreversible!<br/>
              </p>
              <p>
                Are you sure you want to delete your API key to PhishFirewall API?
              </p>
            </div>
            
            <div className="card-action-modal-remove">
              <button
                type='button'
                onClick={() => deleteApiKey()}
              >
                Yes, delete!
              </button>
              <button
                type='button'
                onClick={closeRemoveModal}
              >
                No, I'll think about it
              </button>
            </div>
          </form>
        </Modal>
    </>
  )
}
