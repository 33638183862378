import React from "react";
import { NavLink } from "react-router-dom";
import alert from '../../../images/icons/Alert triangle Large.svg'
import './CheckoutSession.css'

export const CheckoutSessionCancel = () => {
  return (
    <div className="billing-page">
      <NavLink
        className="billing-back-link"
        to={'/back_office/user/membership_plan/plans'}
      >
        Back to Membership plans
      </NavLink>
      <div className="billing-cards">
        <div className="billing-cards-wrap">
          <h2>Payment checkout</h2>
          <div>
            <div className="cancel-result-card">
              <div className="cancel-result-card-alert">
                <img src={alert} alt='alert'></img>
                <h4>Something went wrong...</h4>
                <span>There were some issues encountered during the payment processing.</span>
                <p>
                  {`Click `} 
                  <NavLink
                    className="cancel-alert-link"
                    to={'/back_office/user/membership_plan/plans'}
                  > 
                    here to try again
                  </NavLink> 
                  {` or `}
                  <a href='mailto:sales@phishfirewall.com' className="cancel-alert-link">
                    contact sales.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}