import React from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useTitle } from '../../hooks/title.hook';
import cn from 'classnames';
import './PhishEmails.css';

export const PhishEmails = () => {
  useTitle("PhishFirewall | Spear Phish Setup");
  const { loading } = useHttp();

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div className="phish-main">
        <h2 className="phish-main-title">
          Spear Phish Setup
        </h2>

        <nav className="phish-nav">
          <NavLink
            to="/back_office/email_settings/logo"
            className={({ isActive }) => cn('phish-item',
              { 'phish-item--active': isActive })}
          >
            Company Logo
          </NavLink>

          <NavLink
            to="/back_office/email_settings/email_signatures"
            className={({ isActive }) => cn('phish-item',
              { 'phish-item--active': isActive })}
          >
            Email signature
          </NavLink>
          <NavLink
            to="/back_office/email_settings/urls"
            className={({ isActive }) => cn('phish-item',
              { 'phish-item--active': isActive })}
          >
            URLs
          </NavLink>
        </nav>

        <Outlet />
      </div>
    }
    </>
  )
}
