import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { Loader } from "../Loader/Loader";
import { useCrypto } from "../../hooks/crypto.hook";
import { itemsOnPage } from '../../config/config';
import cn from 'classnames';
import './SyncLog.css';

export const SyncLog = ({ activeDirectory, csv, syncType, google }) => {
  const { loading, request, secondaryLoading, requestWithSecondaryLoading } = useHttp();
  const { showToastMessage, user } = useContext(AuthContext);
  const { decryptData } = useCrypto();

  const { token } = useAuth();

  const [page, setPage] = useState(1);
  const [logs, setlogs] = useState([]);
  const [logsCount, setlogsCount] = useState(0);
  const [newDetailsVisible, setNewDetailsVisible] = useState(false);
  const [errorDetailsVisible, setErrorDetailsVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [tenants, setTenants] = useState([]);

  const firstFetchLogs = useCallback(async (page) => {
    try {
      if (token) {
        const fetched = await request(`/back_office/api/user/sync_logs/${syncType}/${page}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const {logsListCount, logsList} = decryptData(fetched);
        setlogs((prev) => ([...prev, ...logsList]));
        setlogsCount(logsListCount);

        return;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  
  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/user/get_all_tenants`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptUsers = decryptData(fetched);

        setTenants(decryptUsers);

        return decryptUsers;
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchLogs = useCallback(async (page) => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading(`/back_office/api/user/sync_logs/${syncType}/${page}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const {logsListCount, logsList} = decryptData(fetched);
        setlogs((prev) => ([...prev, ...logsList]));
        setlogsCount(logsListCount);

        return;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const syncLogsAzure = useCallback(async () => {
    try {
      if (token) {
        const synced = await request('/back_office/api/user/sync_logs_azure', 'POST', { syncType }, {
          Authorization: `Bearer ${token}`
        })

        showToastMessage(synced.error, synced.message, synced.warning);
        firstFetchLogs(page);
        return synced
      }

    } catch (error) { }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, syncType, firstFetchLogs]);

  const syncLogsGoogle = useCallback(async () => {
    try {
      if (token) {
        const synced = await request('/back_office/api/user/sync_logs_google', 'POST', null, {
          Authorization: `Bearer ${token}`
        })

        showToastMessage(synced.error, synced.message, synced.warning);
        firstFetchLogs(page);
        return synced
      }

    } catch (error) { }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, firstFetchLogs]);

  useEffect(() => {
    firstFetchLogs(page)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstFetchLogs]);

  useEffect(() => {
    fetchAllTenants()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllTenants]);

  return (
    <>
      <div className='logs-card'>
        <div className='logs-table-title'>
          <h3>Sync log</h3>
          {activeDirectory && !google && !!user.syncAD &&
            <button
              className='logs-sync-button'
              type="button"
              onClick={syncLogsAzure}
              disabled={!tenants.find(t => t.type === 'azure')}>
              Sync users
            </button>
          }
          {activeDirectory && google && !!user.syncGoogle &&
            <button
              className='logs-sync-button'
              type="button"
              onClick={syncLogsGoogle}
              disabled={!tenants.find(t => t.type === 'google')}
            >
              Sync users
            </button>
          }
        </div>
        {!loading
          ? logs.length > 0
            ? ((activeDirectory || google) &&
            <div> 
              <table className="logs-table">
                <thead className="table-th-logs">
                  <tr className="not-clickable-logs tr-grid-logs tr-logs">
                    <th>Tenant Name</th>
                    <th>Sync start date</th>
                    <th>Records</th>
                    <th>Outcome</th>
                    <th>Sync details</th>
                  </tr>
                </thead>
                <tbody
                  className="table-body-logs"
                >
                  {logs
                    .filter(log => !log.csvFileName)
                    .map((log, i) => {
                      const allUsers = log.allUsers + log.createdUsers;
                      const createdUsers = log.createdUsers;
                      const updatedUsers = log.updatedUsers;
                      const noChangedUsers = log.allUsers - updatedUsers;

                      if (i < page * itemsOnPage) {
                        return (
                          <>
                            <tr
                              key={log.id}
                              className={cn("tr-grid-logs clickable-row-logs tr-logs-csv")}
                              onClick={() => setSelectedRow(log.id)}
                            >
                              <td className={cn({"td-summary": !log.tenantName})}>
                                {!log.tenantName ? <b>All Tenants</b> : log.tenantName }</td>
                              <td className={cn({"td-summary": !log.tenantName})}>
                                <div>
                                  <span className="log-date">{moment(log.syncStartDate).format('MMM DD')}</span>
                                  <span className="log-year">{moment(log.syncStartDate).format(', YYYY h:mma')}</span>
                                </div>
                              </td>
                              <td className={cn({"td-summary": !log.tenantName})}>{allUsers}</td>
                              <td className={cn({"td-summary": !log.tenantName})}>
                                <span>
                                  {noChangedUsers ? <div>{noChangedUsers}</div> : null}
                                  {noChangedUsers ? <div>no change required;</div> : null} 
                                </span> 
                                <span>
                                  {createdUsers ? <div>{createdUsers}</div> : null}
                                  {createdUsers ? <div>records created;</div> : null}
                                </span>
                                <span>
                                  {updatedUsers ? <div>{updatedUsers}</div> : null}
                                  {updatedUsers ? <div>records updated;</div> : null}
                                </span>
                              </td>
                              <td className={cn({"td-summary": !log.tenantName})}>
                                <div className="table-button-container">
                                  <button
                                    type='button'
                                    className='logs-table-option logs-table-option-new-records'
                                    onClick={() => {
                                      setErrorDetailsVisible(false)
                                      if (selectedRow === log.id) {
                                        setNewDetailsVisible(!newDetailsVisible)
                                      } else {
                                        setNewDetailsVisible(true)
                                      }
                                      setSelectedRow(log.id)
                                    }}
                                    disabled={!log.userDetails?.created || log.userDetails?.created?.length === 0}
                                  >
                                    <div className={newDetailsVisible && selectedRow === log.id ? "sync-log-button-new active-list" : "sync-log-button-new"}>New records</div>
                                  </button>
                                  <div className='logs-table-option-divider'></div>
                                  <button
                                    type='button'
                                    className='logs-table-option'
                                    onClick={() => {
                                      setNewDetailsVisible(false)
                                      if (selectedRow === log.id) {
                                        setErrorDetailsVisible(!errorDetailsVisible)
                                      } else {
                                        setErrorDetailsVisible(true)
                                      }
                                      setSelectedRow(log.id)
                                    }}
                                    disabled={log.incorrectUsers?.length === 0}
                                  >
                                    <div className={errorDetailsVisible && selectedRow === log.id ? "sync-log-button-error active-list" : "sync-log-button-error"}>Errors</div>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            {!!log.userDetails?.created?.length && newDetailsVisible && selectedRow === log.id &&
                              <>
                                <tr className="logs-details-created-description">
                                  <td colSpan={10}>Created users</td>
                                </tr>
                                <tr>
                                  <td colSpan={10}>
                                    <table className="logs-details-table not-clickable-logs-details">
                                      <thead>
                                        <tr className="not-clickable-logs tr-grid-logs tr-logs">
                                          <th style={{ 'width': '30%' }}>User principal name</th>
                                          <th style={{ 'width': '22,5%' }}>First name</th>
                                          <th style={{ 'width': '22,5%' }}>Last name</th>
                                          <th style={{ 'width': '25%' }}>Email address</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {log.userDetails?.created?.map(user => {
                                          return (
                                            <tr>
                                              <td>{user.userPrincipalName}</td>
                                              <td>{user.first_name || user.firstName}</td>
                                              <td>{user.last_name || user.secondName}</td>
                                              <td>{user.email}</td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </>
                            }
                            {errorDetailsVisible && log.incorrectUsers?.length > 0 && selectedRow === log.id &&
                              <>
                                {log.incorrectUsers?.length && (
                                  <>
                                    <tr className="logs-details-error-description">
                                      <td colSpan={10}>Some of the users have uncompleted fields.</td></tr>
                                    <tr>
                                      <td colSpan={10}>
                                        <table className="logs-details-table not-clickable-logs-details">
                                          <thead>
                                            <tr className="logs-details-table-tr">
                                              <th style={{ 'width': '40%' }}>User full name (email)</th>
                                              <th style={{ 'width': '15%' }}>First name</th>
                                              <th style={{ 'width': '15%' }}>Last name</th>
                                              <th style={{ 'width': '20%' }}>Email address</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {log.incorrectUsers?.map(user => {
                                              return (
                                                <tr>
                                                  <td>{user.displayName ? user.displayName : user.mail}</td>
                                                  <td>{user.givenName ? <span className="logs-details-table-check">&#10003;</span> : <span className="logs-details-table-cross">&#10007;</span>}</td>
                                                  <td>{user.surname ? <span className="logs-details-table-check">&#10003;</span> : <span className="logs-details-table-cross">&#10007;</span>}</td>
                                                  <td>{user.mail ? <span className="logs-details-table-check">&#10003;</span> : <span className="logs-details-table-cross">&#10007;</span>}</td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </>)}
                              </>
                            }
                          </>
                        )
                      }
                      return null;
                    }
                    )}
                </tbody>
              </table>

              {page * itemsOnPage < logsCount && !secondaryLoading &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    className='list-button'
                    onClick={() => {
                      setPage((prev) => prev + 1)
                      fetchLogs(page + 1)
                    }}
                    type="submit"
                    >
                    Download more
                  </button>
                </div>
              }

              {secondaryLoading &&
                <div style={{ display: 'flex', justifyContent: 'center',   height: '40px'}}>
                  <span className="mini-loader-csv"></span>
                </div>
              }
            </div>)
            || (csv &&
              <div>
                <table className="logs-table">
                  <thead className="table-th-logs">
                    <tr className="not-clickable-logs tr-grid-logs tr-logs">
                      <th>Tenant Name</th>
                      <th>File name</th>
                      <th>Sync start date</th>
                      <th>Records</th>
                      <th>Outcome</th>
                      <th>Sync details</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-body-logs"
                  >
                    {logs
                      .filter(log => log.csvFileName)
                      .map((log, i) => {
                        const allUsers = log.allUsers + log.createdUsers;
                        const createdUsers = log.createdUsers;
                        const updatedUsers = log.updatedUsers;
                        const noChangedUsers = log.allUsers - updatedUsers;

                        if (i < page * itemsOnPage) {
                          return (
                            <>
                              <tr
                                key={log.id}
                                className={cn("tr-grid-logs clickable-row-logs tr-logs-csv-for-csv")}
                                onClick={() => setSelectedRow(log.id)}
                              >
                                <td>{log.tenantName}</td>
                                <td>{log?.csvFileName}</td>
                                <td>
                                  <div>
                                    <span className="log-date">{moment(log.syncStartDate).format('MMM DD')}</span>
                                    <span className="log-year">{moment(log.syncStartDate).format(', YYYY h:mma')}</span>
                                  </div>
                                </td>
                                <td>{allUsers}</td>
                                <td>
                                  <span>
                                    {noChangedUsers ? <div>{noChangedUsers}</div> : null}
                                    {noChangedUsers ? <div>no change required;</div> : null} 
                                  </span> 
                                  <span>
                                    {createdUsers ? <div>{createdUsers}</div> : null}
                                    {createdUsers ? <div>records created;</div> : null}
                                  </span>
                                </td>
                                <td>
                                  <div className="table-button-container">
                                    <button
                                      type='button'
                                      className='logs-table-option'
                                      onClick={() => {
                                        setErrorDetailsVisible(false)
                                        if (selectedRow === log.id) {
                                          setNewDetailsVisible(!newDetailsVisible)
                                        } else {
                                          setNewDetailsVisible(true)
                                        }
                                        setSelectedRow(log.id)
                                      }}
                                      disabled={!log.userDetails?.created || log.userDetails?.created?.length === 0}
                                    >
                                      <div className={newDetailsVisible && selectedRow === log.id ? "sync-log-button-new active-list" : "sync-log-button-new"}>New records</div>
                                    </button>
                                    <button
                                      type='button'
                                      className='logs-table-option'
                                      onClick={() => {
                                        setNewDetailsVisible(false)
                                        if (selectedRow === log.id) {
                                          setErrorDetailsVisible(!errorDetailsVisible)
                                        } else {
                                          setErrorDetailsVisible(true)
                                        }
                                        setSelectedRow(log.id)
                                      }}
                                      disabled={log.incorrectUsers?.length === 0}
                                    >
                                      <div className={errorDetailsVisible && selectedRow === log.id ? "sync-log-button-error active-list" : "sync-log-button-error"}>Errors</div>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              {!!log.userDetails?.created?.length && newDetailsVisible && selectedRow === log.id &&
                                <>
                                  <tr className="logs-details-created-description">
                                    <td colSpan={10}>Created users</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={10}>
                                      <table className="logs-details-table not-clickable-logs-details">
                                        <thead>
                                          <tr className="not-clickable-logs tr-grid-logs tr-logs">
                                            <th style={{ 'width': '30%' }}>User principal name</th>
                                            <th style={{ 'width': '22,5%' }}>First name</th>
                                            <th style={{ 'width': '22,5%' }}>Last name</th>
                                            <th style={{ 'width': '25%' }}>Email address</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {log.userDetails?.created?.map(user => {
                                            return (
                                              <tr>
                                                <td>{user.userPrincipalName}</td>
                                                <td>{user.first_name || user.firstName}</td>
                                                <td>{user.last_name || user.secondName}</td>
                                                <td>{user.email}</td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                              }
                              {errorDetailsVisible && log.incorrectUsers?.length > 0 && selectedRow === log.id &&
                                <>
                                  {!!log.incorrectUsers?.length && <>
                                    <tr className="logs-details-error-description">
                                      <td colSpan={10}>Some of the users have uncompleted fields.</td></tr>
                                    <tr>
                                      <td colSpan={10}>
                                        <table className="logs-details-table not-clickable-logs-details">
                                          <thead>
                                            <tr className="logs-details-table-tr">
                                              <th style={{ 'width': '40%' }}>User full name (email)</th>
                                              <th style={{ 'width': '15%' }}>First name</th>
                                              <th style={{ 'width': '15%' }}>Last name</th>
                                              <th style={{ 'width': '20%' }}>Email address</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {log.incorrectUsers?.map(user => {
                                              return (
                                                <tr>
                                                  <td>{user.displayName ? user.displayName : user.mail}</td>
                                                  <td>{user.givenName ? <span>&#10003;</span> : <span>&#10007;</span>}</td>
                                                  <td>{user.surname ? <span>&#10003;</span> : <span>&#10007;</span>}</td>
                                                  <td>{user.mail ? <span>&#10003;</span> : <span>&#10007;</span>}</td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </>}
                                </>
                              }
                            </>
                          )
                        }
                        return null;
                      }
                      )}
                  </tbody>
                </table>

                {page * itemsOnPage < logsCount && !secondaryLoading &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='list-button'
                      onClick={() => {
                        setPage((prev) => prev + 1)
                        fetchLogs(page + 1)
                      }}
                      type="submit"
                    >
                      Download more
                    </button>
                  </div>
                }

                {secondaryLoading &&
                  <div style={{ display: 'flex', justifyContent: 'center',   height: '40px'}}>
                    <span className="mini-loader-csv"></span>
                  </div>
                }
              </div>)
            : <div>
              <p className="actions-description">
                There are no logs yet.
              </p>
            </div>
          : <Loader />}
      </div>
    </>
  )
}
