import './EmailSignatures.css';
import edit from '../.././images/icons/Pen.svg';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import './URL.css';

export const URLs = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const [companyMetadata, setCompanyMetadata] = useState([]);
  const { user } = useContext(AuthContext);

  const getMetadata = useCallback(async () => {
    try {
      if (token) {
        const metadata = await request('/back_office/api/user/company_metadata', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyMetadata(metadata);
        
        return metadata;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);
  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details-phish">
            <div className='details-card-phish'>
                <ul className='details-account-list-phish'>
                  <li className='details-item-phish'>
                  IT-Microsoft Migration URL
                    <div className='details-value-phish'>
                    <div dangerouslySetInnerHTML={{__html: companyMetadata['114']?.replace('{{Recipient.Company}}', user.companyName?.split(' ')?.join(''))}}></div>
                    </div>
                  </li>
                  <li className='details-item-phish'>
                  Software Update Email URL
                     <div className='details-value-phish'>
                     <div dangerouslySetInnerHTML={{__html: companyMetadata['563']?.replace('{{Recipient.Company}}', user.companyName?.split(' ')?.join(''))}}></div>
                      </div>
                  </li>
                  <li className='details-item-phish'>
                  Software Update FollowUp URL
                     <div className='details-value-phish'>
                     <div dangerouslySetInnerHTML={{__html: companyMetadata['565']?.replace('{{Recipient.Company}}', user.companyName?.split(' ')?.join(''))}}></div>
                      </div>
                  </li>
                  <li className='details-item-phish'>
                  Subdomain
                     <div className='details-value-phish'>
                     {companyMetadata['294']}
                      </div>
                  </li>

                </ul>
                <div className='details-item-phish details-item-button-phish'>
                     <NavLink to={`/back_office/email_settings/urls/edit`} className="button-link-phish">
                        <button>
                          <img src={edit} alt="edit"></img>
                            Edit
                        </button>
                      </NavLink>
                  </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
