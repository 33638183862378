import React from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useTitle } from '../../hooks/title.hook';
import cn from 'classnames';
import './PhishEmails.css';

export const EmailTemplatesVariations = () => {
  useTitle("PhishFirewall | Email templates");
  const { loading } = useHttp();

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div className="phish-main">
        <h2 className="phish-main-title">
          Email Templates
        </h2>

        <nav className="phish-nav">
          <NavLink
            to="/back_office/email_templates/educational_emails"
            className={({ isActive }) => cn('phish-item',
              { 'phish-item--active': isActive })}
          >
            Educational emails
          </NavLink>

          <NavLink
            to="/back_office/email_templates/phishing_emails"
            className={({ isActive }) => cn('phish-item',
              { 'phish-item--active': isActive })}
          >
            Phishing emails
          </NavLink>
        </nav>

        <Outlet />
      </div>
    }
    </>
  )
}
