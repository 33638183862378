import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { customModalStyles, maxPhishEmailPerDays, minPhishEmailPerDays } from '../../config/config';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import './SetFrequencyModal.css';

export const SetFrequencyModal = ({
  isOpen,
  onRequestClose,
  editTenant,
  callback,
  requestWithSecondaryLoading,
  secondaryLoading
}) => {
  const { encryptData } = useCrypto();
  const { token, showToastMessage } = useContext(AuthContext);
  const [lowRiskEmailPerDays, setLowRiskEmailPerDays] = useState(minPhishEmailPerDays);
  const [mediumRiskEmailPerDays, setMediumRiskEmailPerDays] = useState(minPhishEmailPerDays);
  const [highRiskEmailPerDays, setHighRiskEmailPerDays] = useState(minPhishEmailPerDays);
  const [newTenantPhishFrequency, setNewTenantPhishFrequency] = useState({});
  const [timeoutIdLow, setTimeoutIdLow] = useState(null);
  const [timeoutIdMedium, setTimeoutIdMedium] = useState(null);
  const [timeoutIdHigh, setTimeoutIdHigh] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if(editTenant.frequencyEmailConfig && !Object.keys(editTenant.frequencyEmailConfig).length) return;
    setLowRiskEmailPerDays(parseInt(editTenant?.frequencyEmailConfig?.['low risk']?.mean * 2));
    setMediumRiskEmailPerDays(parseInt(editTenant?.frequencyEmailConfig?.['medium risk']?.mean * 2));
    setHighRiskEmailPerDays(parseInt(editTenant?.frequencyEmailConfig?.['high risk']?.mean * 2));
    setNewTenantPhishFrequency(editTenant?.frequencyEmailConfig);
  }, [editTenant]);
 
  const handleLowRiskChange = (e) => {
    setDisabled(true);
    const newLowRiskEmailPerDays = e.target.value;
    setLowRiskEmailPerDays(newLowRiskEmailPerDays);

    if (timeoutIdLow) {
      clearTimeout(timeoutIdLow);
    }

    const id = setTimeout(() => {
      let checkedLowRiskEmailPerDays = newLowRiskEmailPerDays;
      if(parseInt(newLowRiskEmailPerDays).toString() !== newLowRiskEmailPerDays){
        checkedLowRiskEmailPerDays = parseInt(newLowRiskEmailPerDays).toString()
      }
      if (newLowRiskEmailPerDays < minPhishEmailPerDays) {
        checkedLowRiskEmailPerDays = minPhishEmailPerDays;
      } else if (newLowRiskEmailPerDays > maxPhishEmailPerDays) {
        checkedLowRiskEmailPerDays = maxPhishEmailPerDays;
      }
      setLowRiskEmailPerDays(checkedLowRiskEmailPerDays);
      setNewTenantPhishFrequency((prevConfig) => ({
        ...prevConfig,
        'low risk': {
          ...prevConfig['low risk'],
          mean: parseFloat(checkedLowRiskEmailPerDays / 2),
          deviation: parseFloat(checkedLowRiskEmailPerDays / 4),
        },
      }));
      setDisabled(false);
    }, 1000);
    setTimeoutIdLow(id);
  };
  
  const handleMediumRiskChange = (e) => {
    setDisabled(true);
    const newMediumRiskEmailPerDays = e.target.value;
    setMediumRiskEmailPerDays(newMediumRiskEmailPerDays);

    if (timeoutIdMedium) {
      clearTimeout(timeoutIdMedium);
    }

    const id = setTimeout(() => {
      let checkedMediumRiskEmailPerDays = newMediumRiskEmailPerDays;
      if(parseInt(newMediumRiskEmailPerDays).toString() !== newMediumRiskEmailPerDays){
        checkedMediumRiskEmailPerDays = parseInt(newMediumRiskEmailPerDays).toString()
      }
      if (newMediumRiskEmailPerDays < minPhishEmailPerDays) {
        checkedMediumRiskEmailPerDays = minPhishEmailPerDays;
      } else if (newMediumRiskEmailPerDays > maxPhishEmailPerDays) {
        checkedMediumRiskEmailPerDays = maxPhishEmailPerDays;
      }
      setMediumRiskEmailPerDays(checkedMediumRiskEmailPerDays);
      setNewTenantPhishFrequency((prevConfig) => ({
        ...prevConfig,
        'medium risk': {
          ...prevConfig['medium risk'],
          mean: parseFloat(checkedMediumRiskEmailPerDays / 2),
          deviation: parseFloat(checkedMediumRiskEmailPerDays / 4),
        },
      }));
      setDisabled(false);
    }, 1000);
    setTimeoutIdMedium(id);
  };
  
  const handleHighRiskChange = (e) => {
    setDisabled(true);
    const newHighRiskEmailPerDays = e.target.value;
    setHighRiskEmailPerDays(newHighRiskEmailPerDays);

    if (timeoutIdHigh) {
      clearTimeout(timeoutIdHigh);
    }

    const id = setTimeout(() => {
      let checkedHighRiskEmailPerDays = newHighRiskEmailPerDays;
      if(parseInt(newHighRiskEmailPerDays).toString() !== newHighRiskEmailPerDays){
        checkedHighRiskEmailPerDays = parseInt(newHighRiskEmailPerDays).toString()
      }
      if (newHighRiskEmailPerDays < minPhishEmailPerDays) {
        checkedHighRiskEmailPerDays = minPhishEmailPerDays;
      } else if (newHighRiskEmailPerDays > maxPhishEmailPerDays) {
        checkedHighRiskEmailPerDays = maxPhishEmailPerDays;
      }
      setHighRiskEmailPerDays(checkedHighRiskEmailPerDays);
      setNewTenantPhishFrequency((prevConfig) => ({
        ...prevConfig,
        'high risk': {
          ...prevConfig['high risk'],
          mean: parseFloat(checkedHighRiskEmailPerDays / 2),
          deviation: parseFloat(checkedHighRiskEmailPerDays / 4),
        },
      }));
      setDisabled(false);
    }, 1000);
    setTimeoutIdHigh(id);
  };

  const updateTenant = async (tenantId, newTenantPhishFrequency) => {
    try {
      onRequestClose();
      const data = encryptData({ tenantId, newTenantPhishFrequency });
      const res = await requestWithSecondaryLoading('/back_office/api/user/update_tenant_phish_frequency', 'POST', { data }, {
        Authorization: `Bearer ${token}`,
      });

      showToastMessage(res.error, res.message);

      if (callback) callback();
    } catch (error) { }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Frequency Modal'
      style={customModalStyles}
    >
      <div className='frequency-modal'>
        <div className='frequency-content'>
          <span className='card-title'>Set the Phish Frequency</span>

          <span className='card-subtitle'>Low Risk</span>
          <div className='frequency-input-div'>
            Send Phish email per
            <input
              type="number"
              className='frequency-input'
              value={lowRiskEmailPerDays}
              onChange={handleLowRiskChange}
              min={minPhishEmailPerDays}
              max={maxPhishEmailPerDays}
            /> days
          </div>

          <span className='card-subtitle'>Medium Risk</span>
          <div className='frequency-input-div'>
            Send Phish email per
            <input
              type="number"
              className='frequency-input'
              value={mediumRiskEmailPerDays}
              onChange={handleMediumRiskChange}
              min={minPhishEmailPerDays}
              max={maxPhishEmailPerDays}
            /> days
          </div>

          <span className='card-subtitle'>High Risk</span>
          <div className='frequency-input-div'>
            Send Phish email per
            <input
              type="number"
              className='frequency-input'
              value={highRiskEmailPerDays}
              onChange={handleHighRiskChange}
              min={minPhishEmailPerDays}
              max={maxPhishEmailPerDays}
            /> days
          </div>

          <div className='frequency-modal-buttons'>
            <button
              onClick={() => updateTenant(editTenant.tenantId, newTenantPhishFrequency)}
              className='modal-button-update'
              disabled={secondaryLoading || disabled}
            >
              Save Changes
            </button>
            <button onClick={onRequestClose} className='modal-button-cancel'>Cancel</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
