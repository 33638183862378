import { useMemo } from "react";

const rangeArray = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, idx) => idx + start);
};

export const dots = '...';

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return rangeArray(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftdots = leftSiblingIndex > 2;
    const shouldShowRightdots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftdots && shouldShowRightdots) {
      return [...rangeArray(1, 3 + 2 * siblingCount), dots, totalPageCount];
    }

    if (shouldShowLeftdots && !shouldShowRightdots) {
      return [firstPageIndex, dots, ...rangeArray(totalPageCount - (3 + 2 * siblingCount) + 1, totalPageCount)];
    }

    if (shouldShowLeftdots && shouldShowRightdots) {
      return [firstPageIndex, dots, ...rangeArray(leftSiblingIndex, rightSiblingIndex), dots, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
