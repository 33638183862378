import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink, useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useTitle } from '../../hooks/title.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { useAuth } from '../../hooks/auth.hook';
import './Categories.css';

export const Categories = () => {
  useTitle("PhishFirewall | Categories");

  const { loading, request } = useHttp();
  const { decryptData } = useCrypto();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [categories, setCategories] = useState([]);
  const [systemGroupsOptionList, setSystemGrousOptionList] = useState([]);

  useEffect(() => {
    navigate('/back_office/categories/summary');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategories = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/categories', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptCategories = decryptData(fetched);
        setCategories(decryptCategories);

        return decryptCategories;
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories]);

  const fetchGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/groups/all_groups', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptGroups = decryptData(fetched);
        setSystemGrousOptionList(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  return (
    <div className="categories-main">
      <h2 className="categories-main-title">
        Mandatory Training
      </h2>

      {!loading
        ? <>
            <nav className="categories-nav">
              <NavLink
                to={`/back_office/categories/summary`}
                className={({ isActive }) => cn('categories-item',
                  { 'categories-item--active': isActive })}
              >
                SUMMARY
              </NavLink>
              {categories.map((c, i) => {
                const category = c.name.split(' ').join('-');
                return (
                  <NavLink
                    key={i}
                    to={`/back_office/categories/${category}`}
                    state={{
                      category: c,
                      groups: systemGroupsOptionList
                    }}
                    className={({ isActive }) => cn('categories-item',
                      { 'categories-item--active': isActive })}
                  >
                    {category}
                  </NavLink>
                )})
              }
            </nav>

            <Outlet context={categories}/>
          </>
          : <Loader />
        }
    </div>
  )
};
