import React, { useContext, useEffect } from "react";
import { storageName, lastVisitedUrl } from '../../config/config';
import { useCrypto } from "../../hooks/crypto.hook";
import { AuthContext } from "../../context/AuthContext";

const AutoLogin = () => {
  const { decryptData } = useCrypto();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const checkLoginStorage = () => {
      const localUser = localStorage.getItem(storageName);
      const userData = JSON.parse(localUser);
      
      if (localUser && typeof userData === "object") {
        logout();
      }

      const data = localUser ? decryptData(userData) : {};

      if (data && data.token && data.userId) {
        const lastVisitedUrlPath = sessionStorage.getItem(lastVisitedUrl, window.location.href);
        window.location.href = lastVisitedUrlPath || '/';
      }
    };

    const intervalId = setInterval(checkLoginStorage, 1000);
    return () => clearInterval(intervalId);
  }, [decryptData, logout]);

  return (
    <div></div>
  );
};

export default AutoLogin;
