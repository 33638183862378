import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { UserPage } from './pages/UserPage/UserPage';
import { ADEditUser } from './pages/ADEditUser/ADEditUser';
import { ADCreateUser } from './pages/ADCreateUser/ADCreateUser';
import { AuthPage } from './pages/AuthPage';
import { DetailsPage } from './pages/DetailsPage/DetailsPage';
import { ChangePassword } from './pages/ChangePassword';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { MembershipPlanMain } from './pages/MembershipPlan/MembershipPlan';
import { Plans } from './pages/MembershipPlan/Plans';
import { Navbar } from './components/Navbar/Navbar';
import { UsersActions } from './components/UsersActions/UsersActions';
import { ActionChart } from './components/UsersActions/ActionChart';
import { MailingHistory } from './components/MailingHistory/MailingHistory';
import { MailingHistoryChart } from './components/MailingHistory/MailingHistoryChart';
import { SideBar } from './components/SideBar/SideBar';
import { Users } from './pages/ExclusionsLists/UsersExclusions/Users';
import { EmailTemplates } from './pages/ExclusionsLists/EmailTemplatesExclusions/EmailTemplates';
import { SegmentsPerUser } from './pages/ExclusionsLists/SegmentsPerUserExclusions/SegmentsPerUser';
import { Segments } from './pages/ExclusionsLists/SegmentsExclusions/Segments';
import { Exclusions } from './pages/ExclusionsLists/Exclusions/Exclusions';

import { NewUsersGroup } from './pages/UsersGroups/NewUsersGroup';
import { EditUserGroup } from './pages/UsersGroups/EditUserGroup';
import { UsersGroup } from './pages/UsersGroups/UsersGroup';
import { UsersGroups } from './pages/UsersGroups/UsersGroups';
import { GroupsMapping } from './pages/UsersGroups/GroupsMapping';

import { useAuth } from './hooks/auth.hook';
import logo from './images/logo.png';
import AuthVerify from './common/auth-verify';
import { UsersDatabase } from './pages/UsersDatabases/UsersDatabase/UsersDatabase';
import { ADDetails } from './pages/DetailsPage/ADDetails';
import { CsvUploadDetails } from './components/CSV/CsvUploadDetails';
import { CsvUploadCreate } from './components/CSV/CsvUploadCreate';
import { CsvUploadEdit } from './components/CSV/CsvUploadEdit';
import { Register } from './pages/RegisterPage';
import { RegisterPageByInvation } from './pages/RegisterPageByInvation';
import { GoogleEditUser } from './components/Google/GoogleEditUser';
import { GoogleCreateUser } from './components/Google/GoogleCreateUser';
import { GoogleDetails } from './components/Google/GoogleDetails';
import { UsersList } from './pages/UsersList/UsersList';
import { CheckoutSession } from './components/Billing/Stripe/CheckoutSession';
import { CheckoutSessionSuccess } from './components/Billing/Stripe/CheckoutSessionSuccess';
import { CheckoutSessionCancel } from './components/Billing/Stripe/CheckoutSessionCancel';
import { BillingHistory } from './components/Billing/BillingHistory/BillingHistory';
import { APIManagment } from './pages/APIManagment/APIManagment';
import { ReportedPhish } from './pages/ReportsPhish/ReportedPhish';
import { NewReports } from './pages/ReportsPhish/NewReports';
import { PhishReports } from './pages/ReportsPhish/PhishReports';
import { CustomerSupport } from './pages/CustomerSupport/CustomerSupport';

import { UserAnalyticsMain } from './components/UserAnalytics/UserAnalyticsMain';
import { ExecutiveSummary } from './components/UserAnalytics/ExecutiveSummary/ExecutiveSummary';
import { UserReportCard } from './components/UserAnalytics/UserReportCard/UserReportCard';

import { PhishEmails } from './pages/PhishEmails/PhishEmails';
import { CompanyLogo } from './pages/PhishEmails/CompanyLogo';
import { EmailSignatures } from './pages/PhishEmails/emailSignatures';
import { EmailSignaturesEdit } from './pages/PhishEmails/EmailSignaturesEdit';
import { URLs } from './pages/PhishEmails/URLs';
import { URLsEdit } from './pages/PhishEmails/URLsEdit';
import AutoLogout from './components/AutoLogout/AutoLogout';
import AutoLogin from './components/AutoLogin/AutoLogin';
import { Invoices } from './components/Billing/BillingHistory/Invoices';
import { Categories } from './pages/MandatoryTraining/Categories';
import { Category } from './pages/MandatoryTraining/Category';
import { Loader } from './components/Loader/Loader';
import { TenantsList } from './pages/TenantsList/TenantsList';
import { EducationalEmails } from './pages/PhishEmails/EducationalEmails';
import { PhishingEmails } from './pages/PhishEmails/PhishingEmails';
import { EmailTemplatesVariations } from './pages/PhishEmails/EmailTemplatesVariations';
import { MailingAndActionsMain } from './components/MailingAndActions/MailingAndActionsMain';
import { MailingAndActionsMainChartList } from './components/MailingAndActions/MailingAndActionsMainChartList';
import { CategoriesSummary } from './pages/MandatoryTraining/CategoriesSummary';
import { Risk } from './components/UserAnalytics/Risk/Risk';
import { Phishing } from './components/UserAnalytics/Phishing/Phishing';
import { Education } from './components/UserAnalytics/Education/Education';
import { sysAdminAccessCompanyIds } from './config/config';
import { SysAdminPage } from './pages/SysAdminEnvironment/SysAdminPage';
import { BulkEmailing } from './pages/SysAdminEnvironment/BulkEmailing';

export const useRoutes = (
  isAuthenticated,
  user,
  planPaused,
  dateExpired,
  allUsers,
  tenants
) => {
  const { logout } = useAuth();
  const [defaultAuthenticatedPath, setDefaultAuthenticatedPath] = useState(
    '/back_office/register'
  );

  useEffect(() => {
    if (isAuthenticated) setDefaultAuthenticatedPath('/back_office/login');
  }, [isAuthenticated]);

  const usersEsclusionsDefaultPath =
    !planPaused && !dateExpired
      ? '/back_office/exclusions/users'
      : '/back_office/user/membership_plan';

  const mailingAndActionsDefaultPath =
    !planPaused && !dateExpired
      ? '/back_office/activity/mailing_and_actions/chart'
      : '/back_office/user/membership_plan';

  const userAnalyticsDefaultPath =
    !planPaused && !dateExpired
      ? '/back_office/activity/user_analytics/executive_summary'
      : '/back_office/user/membership_plan';

  const membershipPlanDefaultPath =
    user?.role === 'owner'
      ? '/back_office/user/membership_plan/plans'
      : '/back_office/user/membership_plan/invoices';

  const [usersDatabaseNavigatePath, setUsersDatabaseNavigatePath] = useState(
    '/back_office/user/users_database'
  );

  useEffect(() => {
    if (allUsers.length) {
      setUsersDatabaseNavigatePath(
        '/back_office/user/users_database/all_users'
      );
    } else if (user.syncAD) {
      setUsersDatabaseNavigatePath('/back_office/user/users_database/ad');
    } else if (user.syncGoogle) {
      setUsersDatabaseNavigatePath('/back_office/user/users_database/google');
    } else if (user.syncCSV) {
      setUsersDatabaseNavigatePath('/back_office/user/users_database/csv');
    } else {
      setUsersDatabaseNavigatePath('/back_office//back_office/user/sync');
    }
  }, [
    allUsers,
    user.syncAD,
    user.syncCSV,
    user.syncGoogle,
    usersDatabaseNavigatePath,
  ]);

  if (isAuthenticated) {
    return (
      <div className="page">
        <Navbar />
        {user?.id
          ? <div className="main">
            <SideBar />

            <div className="component">
              <Routes>
                <Route
                  exact
                  path="/back_office/user/sync"
                  element={<UserPage />}
                />
                <Route
                  exact
                  path="/back_office/user/change_password"
                  element={
                    <ChangePassword
                      authForm={false}
                      authData={{}}
                      setCard={() => { }}
                    />
                  }
                />
                <Route
                  exact
                  path="/back_office/user/details"
                  element={<DetailsPage />}
                />
                <Route
                  exact
                  path="/back_office/user/customer_support"
                  element={<CustomerSupport />}
                />
                <Route
                  exact
                  path="/back_office/user/buy_plan"
                  element={<CheckoutSession />}
                />
                <Route
                  exact
                  path="/back_office/user/buy_success"
                  element={<CheckoutSessionSuccess />}
                />
                <Route
                  exact
                  path="/back_office/user/buy_cancel"
                  element={<CheckoutSessionCancel />}
                />
                {(user?.role === 'owner' || user?.role === 'tenant-admin') && (
                  <Route
                    exact
                    path="/back_office/user/membership_plan"
                    element={<MembershipPlanMain />}
                  >
                    <Route
                      path="/back_office/user/membership_plan"
                      element={<Navigate to={membershipPlanDefaultPath} />}
                    />
                    {user?.role === 'owner' && (
                      <Route exact path="plans" element={<Plans />} />
                    )}
                    {user?.role === 'owner' && (
                      <Route
                        exact
                        path="billing_history"
                        element={<BillingHistory />}
                      />
                    )}
                    <Route exact path="invoices" element={<Invoices />} />
                  </Route>
                )}
                <Route
                  path="/back_office/user/users_database"
                  element={<UsersDatabase />}
                >
                  <Route
                    path="/back_office/user/users_database"
                    element={<Navigate to={usersDatabaseNavigatePath} />}
                  />
                  <>
                    <Route exact path="ad">
                      {((user?.syncAD && !!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'azure')) || user?.role === 'owner'  || user.tenants.allTenantsAccess) &&
                        <>
                          <Route
                            path="/back_office/user/users_database/ad"
                            element={
                              <Navigate to="/back_office/user/users_database/ad/details" />
                            }
                          />
                          <Route exact path="details" element={<ADDetails />} />
                          <Route exact path="edit">
                            <Route exact path=":id" element={<ADEditUser />} />
                          </Route>
                        </>}
                      {(user.tenants.syncAccess || user?.role === 'owner') &&
                        <Route exact path="create" element={<ADCreateUser />} />
                      }
                    </Route>
                    <Route exact path="google">
                      {((user?.syncGoogle && !!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'google')) || user?.role === 'owner' || user.tenants.allTenantsAccess) &&
                        <>
                          <Route
                            path="/back_office/user/users_database/google"
                            element={
                              <Navigate to="/back_office/user/users_database/google/details" />
                            }
                          />
                          <Route exact path="details" element={<GoogleDetails />} />

                          <Route exact path="edit">
                            <Route exact path=":id" element={<GoogleEditUser />} />
                          </Route>
                        </>}
                      {(user.tenants.syncAccess || user?.role === 'owner') &&
                        <Route
                          exact
                          path="create"
                          element={<GoogleCreateUser />}
                        />
                      }
                    </Route>
                    <Route exact path="csv">
                      {((user?.syncCSV && !!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'csv')) || user?.role === 'owner' || user?.tenants?.allTenantsAccess) &&
                        <>
                          <Route
                            path="/back_office/user/users_database/csv"
                            element={
                              <Navigate to="/back_office/user/users_database/csv/details" />
                            }
                          />
                          <Route
                            exact
                            path="details"
                            element={<CsvUploadDetails />}
                          />
                          <Route exact path="edit">
                            <Route exact path=":id" element={<CsvUploadEdit />} />
                          </Route>
                        </>
                      }
                      {(user.tenants.syncAccess || user?.role === 'owner') && <Route
                        exact
                        path="create"
                        element={<CsvUploadCreate />}
                      />}
                    </Route>

                  </>
                  <Route exact path="all_users" element={<UsersList />} />
                  <Route exact path="all_tenants" element={<TenantsList />} />
                </Route>

                {!planPaused && !dateExpired && user.paymentPlanId === 4 && (
                  <Route path="/back_office/groups" element={<UsersGroups />}>
                    <Route exact path="create" element={<NewUsersGroup />} />
                    <Route exact path="mapping" element={<GroupsMapping />} />
                    <Route exact path=":group" element={<UsersGroup />} />
                    <Route exact path=":group/edit" element={<EditUserGroup />} />
                    <Route
                      path="*"
                      element={<Navigate to="/back_office/groups" />}
                    />
                  </Route>
                )}

                {!planPaused && !dateExpired && (user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                  <Route path="/back_office/categories" element={<Categories />}>
                    <Route exact path="summary" element={<CategoriesSummary />} />
                    <Route exact path=":category" element={<Category />} />
                    <Route
                      path="*"
                      element={<Navigate to="/back_office/categories" />}
                    />
                  </Route>
                )}

                <Route path="/back_office/exclusions" element={<Exclusions />}>
                  <Route
                    path="/back_office/exclusions"
                    element={<Navigate to={usersEsclusionsDefaultPath} />}
                  />
                  {!planPaused && !dateExpired && (
                    <Route exact path="users" element={<Users />} />
                  )}
                  {!planPaused && !dateExpired && (
                    <Route exact path="templates" element={<EmailTemplates />} />
                  )}
                  {(user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                    <Route
                      exact
                      path="segments_per_user"
                      element={<SegmentsPerUser />}
                    />
                  )}
                  {(user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                    <Route exact path="segments" element={<Segments />} />
                  )}
                  <Route
                    path="*"
                    element={<Navigate to="/back_office/exclusions" />}
                  />
                </Route>

                <Route
                  path="/back_office/activity/mailing_and_actions"
                  element={<MailingAndActionsMain />}
                >
                  <Route
                    path="/back_office/activity/mailing_and_actions"
                    element={<Navigate to={mailingAndActionsDefaultPath} />}
                  />
                  {!planPaused && !dateExpired && (
                    <>
                      <Route
                        exact
                        path="chart_list"
                        element={<MailingAndActionsMainChartList />}
                      />
                      <Route
                        exact
                        path="mailing_list"
                        element={<MailingHistory />}
                      />
                      <Route
                        exact
                        path="mailing_chart"
                        element={<MailingHistoryChart />}
                      />
                      <Route
                        exact
                        path="actions_list"
                        element={<UsersActions />}
                      />
                      <Route
                        exact
                        path="actions_chart"
                        element={<ActionChart />}
                      />
                    </>
                  )}
                  <Route
                    path="*"
                    element={<Navigate to="/back_office/activity/mailing_and_actions" />}
                  />
                </Route>

                {!dateExpired && !planPaused &&
                  (user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                    <Route
                      path="/back_office/activity/user_analytics"
                      element={<UserAnalyticsMain />}
                    >
                      <Route
                        path="/back_office/activity/user_analytics"
                        element={<Navigate to={userAnalyticsDefaultPath} />}
                      />
                      {!planPaused && !dateExpired && (
                        <Route
                          exact
                          path="executive_summary"
                          element={<ExecutiveSummary />}
                        />
                      )}
                      {!planPaused && !dateExpired && (
                        <Route
                          exact
                          path="risk"
                          element={<Risk />}
                        />
                      )}
                      {!planPaused && !dateExpired && (
                        <Route
                          exact
                          path="phishing"
                          element={<Phishing />}
                        />
                      )}
                      {!planPaused && !dateExpired && (
                        <Route
                          exact
                          path="education"
                          element={<Education />}
                        />
                      )}
                      {!planPaused && !dateExpired && (
                        <Route
                          exact
                          path="user_report_card"
                          element={<UserReportCard />}
                        />
                      )}
                      <Route
                        path="*"
                        element={<Navigate to="/back_office/activity" />}
                      />
                    </Route>
                  )
                }
                {!planPaused &&
                  !dateExpired &&
                  (user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                    <Route
                      path="/back_office/phish_reports"
                      element={<ReportedPhish />}
                    >
                      <Route
                        path="/back_office/phish_reports"
                        element={<Navigate to="/back_office/phish_reports/new" />}
                      />
                      <Route exact path="new" element={<NewReports />} />
                      <Route
                        exact
                        path="phish"
                        element={<PhishReports type={'phish'} />}
                      />
                      <Route
                        exact
                        path="not_phish"
                        element={<PhishReports type={'not-phish'} />}
                      />
                      <Route
                        exact
                        path="spam"
                        element={<PhishReports type={'spam'} />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to="/back_office/phish_reports" />}
                      />
                    </Route>
                  )}
                {!planPaused &&
                  !dateExpired &&
                  user.paymentPlanId === 4 &&
                  user?.role === 'owner' && (
                    <Route
                      path="/back_office/api_management"
                      element={<APIManagment />}
                    />
                  )}

                {!planPaused &&
                  !dateExpired &&
                  (user.paymentPlanId === 3 || user.paymentPlanId === 4) && (
                    <Route
                      path="/back_office/email_settings"
                      element={<PhishEmails />}
                    >
                      <Route
                        path="/back_office/email_settings"
                        element={
                          <Navigate to="/back_office/email_settings/logo" />
                        }
                      />
                      <Route exact path="logo" element={<CompanyLogo />} />
                      <Route
                        exact
                        path="email_signatures"
                        element={<EmailSignatures />}
                      />
                      <Route
                        exact
                        path="email_signatures/edit"
                        element={<EmailSignaturesEdit />}
                      />
                      <Route exact path="urls" element={<URLs />} />
                      <Route exact path="urls/edit" element={<URLsEdit />} />
                      <Route
                        path="*"
                        element={<Navigate to="/back_office/email_settings" />}
                      />
                    </Route>
                  )}
                <Route path="/back_office/email_templates" element={<EmailTemplatesVariations />}>
                  <Route path="/back_office/email_templates" element={<Navigate to="/back_office/email_templates/educational_emails" />} />
                  <Route exact path="educational_emails" element={<EducationalEmails />} />
                  <Route exact path="phishing_emails" element={<PhishingEmails />} />
                  <Route path="*" element={<Navigate to="/back_office/email_templates" />} />
                </Route>

                {sysAdminAccessCompanyIds?.includes(user?.companyId) && user?.sysAdminAccess &&
                  (<Route
                    path="/back_office/sys_admin"
                    element={<SysAdminPage />}
                  >
                    <Route
                      path="/back_office/sys_admin"
                      element={<Navigate to="/back_office/sys_admin/bulk_emailing" />}
                    />
                    <Route exact path="bulk_emailing" element={<BulkEmailing />} />
                    <Route
                      path="*"
                      element={<Navigate to="/back_office/sys_admin" />}
                    />
                  </Route>)
                }

                <Route
                  path="*"
                  element={<Navigate to="/back_office/user/sync" />}
                />
              </Routes>

              <AutoLogout />
              <AuthVerify logout={logout} />
            </div>
          </div>
          : <Loader />
        }
      </div>
    );
  } else {
    return (
      <div className="unauthorized">
        <div className="card">
          <img src={logo} alt="Logo" className="logo"></img>

          <Routes>
            <Route
              path="/back_office"
              element={<Navigate to={defaultAuthenticatedPath} />}
            />
            <Route path="/back_office/register" element={<Register />} />
            <Route
              path="/back_office/register/:companyName"
              element={<Register />}
            />
            <Route
              path="/back_office/register_by_invation/:token"
              element={<RegisterPageByInvation />}
            />
            <Route path="/back_office/login" element={<AuthPage />} />
            <Route
              path="/back_office/login/:companyName"
              element={<AuthPage />}
            />
            <Route
              path="/back_office/forgot_password"
              element={<ForgotPassword />}
            />
            <Route
              path="/back_office/reset_password/:token"
              element={<ResetPassword />}
            />
            <Route
              path="*"
              element={<Navigate to={defaultAuthenticatedPath} />}
            />
          </Routes>

          <AutoLogin />
        </div>
      </div>
    );
  }
};
