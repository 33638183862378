import cn from 'classnames';
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export const ChartPieMandatoryTraining = (
  { chartData, loading, title }
) => {
  const sum = chartData?.datasets?.[0]?.data?.reduce((a, b) => a + b, 0);
  let preparedData = chartData;

  if (!sum) {
    preparedData = {
      labels: ['No data available'],
      datasets: [
        {
          data: ['1'],
          backgroundColor: ['#F3F3F3'],
          hoverBackgroundColor: ['#F3F3F3'],
          hoverOffset: 0,
          borderWidth: 0,
          hoverRadius: 0,
          hoverBorderWidth: 0
        }
      ]
    };
  };

  return (
    <div className={cn('chart-container-mandatory-training-pie chart-metric-mandatory-training', {
      'chart-metric-mandatory-training-download': loading,
    })}>
      <Pie
        data={preparedData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              onHover: e => {
                !!sum ? e.native.target.style.cursor = 'pointer' : e.native.target.style.cursor = 'default';
              },
              onLeave: e => {
                e.native.target.style.cursor = 'default';
              },
              position: 'top',
            },
            title: {
              display: true,
              text: title,
              font: {
                size: 18,
                weight: 700,
                color: '#343232'
              }
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: () => null
              }
            },
          },
          onHover: e => {
            return e.native.target.style.cursor = 'default';
          },
        }}
      />
    </div>
  );
};
