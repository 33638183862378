import React, { useState, useCallback, useEffect, useContext } from 'react';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { itemsOnPage } from '../../config/config';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import {
  AllUsersOption, DepartmentOption, LocationOption
} from '../../components/ReactSelect/ReactSelectOption';
import { CustomizedTooltipSendMail } from '../../components/Tooltips/Tooltip';
import './Categories.css';

export const MandatoryTrainingUsers = ({ categoryId, selectedTenant }) => {
  const { secondaryLoading, requestWithSecondaryLoading, request } = useHttp();
  const { token } = useAuth();
  const { showToastMessage, allUsers } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [mandatoryTrainingUsers, setMandatoryTrainingUsers] = useState({});
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  const [firstFetchLoading, setFirstFetchLoading] = useState(false);
  const [resendInProgress, setResendInProgress] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const firstFetchMandatoryTrainingUsers = useCallback(async (categoryId, page, selectedTenant, selectedUser, selectedDepartment, selectedLocation) => {
    try {
      if (token) {
        const selectedParams = {
          selectedTenant,
          selectedUser: selectedUser?.label,
          selectedDepartment: selectedDepartment?.label,
          selectedLocation: selectedLocation?.label,
        };

        const data = encryptData(selectedParams);
        setFirstFetchLoading(true);
        const fetched = await request(`/back_office/api/categories/mandatory_training_users/${categoryId}/${page}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptMandatoryTrainingUsers = decryptData(fetched);
        decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length
          ? setMandatoryTrainingUsers(decryptMandatoryTrainingUsers)
          : setMandatoryTrainingUsers({});
        setItemsCount(decryptMandatoryTrainingUsers.allUsersListCount);
        setFirstFetchLoading(false);

        return decryptMandatoryTrainingUsers;
      }
    } catch (error) {
      setFirstFetchLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchMandatoryTrainingUsers = useCallback(async (categoryId, page, selectedTenant, selectedUser, selectedDepartment, selectedLocation) => {
    try {
      if (token) {
        const selectedParams = {
          selectedTenant,
          selectedUser: selectedUser?.label,
          selectedDepartment: selectedDepartment?.label,
          selectedLocation: selectedLocation?.label,
        };

        const data = encryptData(selectedParams);
        const fetched = await requestWithSecondaryLoading(`/back_office/api/categories/mandatory_training_users/${categoryId}/${page + 1}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptMandatoryTrainingUsers = decryptData(fetched);
        decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length
          ? setMandatoryTrainingUsers(prev => ({ ...prev, uniqueMandatoryTrainingUsers: [...prev.uniqueMandatoryTrainingUsers, ...decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers] }))
          : setMandatoryTrainingUsers(prev => prev);

        return decryptMandatoryTrainingUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!!categoryId) firstFetchMandatoryTrainingUsers(categoryId, 1, selectedTenant, selectedUser, selectedDepartment, selectedLocation);
  }, [categoryId, firstFetchMandatoryTrainingUsers, selectedDepartment, selectedLocation, selectedTenant, selectedUser]);

  useEffect(() => {
    setPage(1)
  }, [selectedTenant, selectedUser, selectedDepartment, selectedLocation, categoryId]);

  const getSyncType = (user) => {
    return allUsers.find(u => user.email === u.email)?.syncType;
  }

  const resendHandler = async (userId, segmentId, emailTemplateId) => {
    const emailParams = {
      userId,
      segmentId,
      emailTemplateId
    };

    try {
      if (token) {
        setSelectedTemplate(emailTemplateId);
        setResendInProgress(true);
        const data = encryptData(emailParams);

        await requestWithSecondaryLoading(`/back_office/api/categories/resend_email`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const userIndex = mandatoryTrainingUsers.uniqueMandatoryTrainingUsers.findIndex(user => user.userId === userId);
        const pardotEmail = mandatoryTrainingUsers.uniqueMandatoryTrainingUsers[userIndex].notViewedMandatoryTrainingEmails
          .filter(user => user.emailTemplateId === emailTemplateId)[0];
        pardotEmail.isResend = true;

        setMandatoryTrainingUsers(mandatoryTrainingUsers);
        showToastMessage(null, 'Email has been sent');
        setSelectedTemplate(null);
        setResendInProgress(false);
      }
    } catch (error) {
      console.log(error);
      showToastMessage(error);
      setSelectedTemplate(null);
      setResendInProgress(false);
    }
  };

  const sendEmailWithLink = async (userId, segmentId) => {
    const emailParams = {
      userId,
      segmentId
    };

    try {
      if (token) {
        const data = encryptData(emailParams);

          const response = await requestWithSecondaryLoading(`/back_office/api/categories/resend_email_link`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const userIndex = mandatoryTrainingUsers.uniqueMandatoryTrainingUsers.findIndex(user => user.userId === userId);
        mandatoryTrainingUsers.uniqueMandatoryTrainingUsers[userIndex].isResend = true;

        setMandatoryTrainingUsers(mandatoryTrainingUsers);
        showToastMessage(response.error, response.message);
        setSelectedRow(-1);
      }
    } catch (error) {
      console.log(error);
      showToastMessage(error);
      setSelectedTemplate(null);
      setResendInProgress(false);
    }
  };

  const handleUserChange = (data) => {
    setSelectedUser(data);
    setDetailsVisible(false);
    setSelectedDepartment(null);
    setSelectedLocation(null);
  };
  const handleDeparmentChange = (data) => {
    setSelectedDepartment(data);
    setDetailsVisible(false);
  };
  const handleLocationChange = (data) => {
    setSelectedLocation(data);
    setDetailsVisible(false);
  };

  const filteredByTenantUsers = mandatoryTrainingUsers?.allUsersList
    ?.filter(user => (
      selectedTenant
        ? (user.tenantName === selectedTenant.label)
        : user
    ));

  const allUsersOptionList = filteredByTenantUsers
    ?.map(user => (
      {
        value: user.userId,
        label: user.email,
        caption: user.firstName + ' ' + user.secondName,
        sync: getSyncType(user),
        department: user.department,
        location: user.location
      }
    ))
    ?.filter(user => selectedTenant
      ? (user.sync === selectedTenant.syncType)
      : user
    )

  const departmentsOptionList = [...new Set(allUsersOptionList
    ?.filter(user => selectedLocation
      ? user?.location === selectedLocation?.label
      : user
    )
    ?.map(user => user.department)
  )]
    ?.filter(Boolean)
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(department => ({ value: department, label: department}))
  
  const locationsOptionList = [...new Set(allUsersOptionList
    ?.filter(user => selectedDepartment
      ? user?.department === selectedDepartment?.label
      : user
    )
    ?.map(user => user.location)
  )]
    ?.filter(Boolean)
    ?.sort((a, b) => a.localeCompare(b))
    ?.map(location => ({ value: location, label: location}))

  useEffect(() => {
    setSelectedUser(null);
    setSelectedDepartment(null);
    setSelectedLocation(null);
    setDetailsVisible(false);
  }, [selectedTenant, categoryId]);

  const exportNotComplientUsersCsv = async (categoryId, selectedTenant, selectedUser, selectedDepartment, selectedLocation) => {
    try {
      if (token) {
        setDownloadLoading(true);

        const selectedParams = {
          selectedTenant,
          selectedUser: selectedUser?.label,
          selectedDepartment: selectedDepartment?.label,
          selectedLocation: selectedLocation?.label,
        };

        const data = encryptData(selectedParams);
        const response = await fetch(`/back_office/api/categories/mandatory_training_users_csv/${categoryId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          setDownloadLoading(false);
          throw new Error('Download failed');
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        const tenantName = selectedTenant?.label ? `${selectedTenant?.label}(${selectedTenant?.syncType})_` : '';
        const department = selectedDepartment?.label ? `${selectedDepartment?.label}_` : '';
        const location = selectedLocation?.label ? `${selectedLocation?.label}_` : '';

        const fileName = selectedUser
          ? `not_complient_UserID-${selectedUser?.value}__${new Date().toISOString().split('T')[0]}.csv`
          : `not_complient_users_${tenantName}${department}${location}${new Date().toISOString().split('T')[0]}.csv`;

        a.download = fileName;

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();
        
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Not Compliant User(s) downloaded successfully!');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage('Failed to export CSV file!');
    }
  };

  return (
    <>
      {!firstFetchLoading
        ? !!mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length &&
          !!mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.filter(user => selectedTenant
              ? (selectedTenant?.label === user.tenantName && selectedTenant.syncType === getSyncType(user))
              : user
            && selectedUser
              ? selectedUser?.value === user?.userId
              : user
          )?.length &&
          <div className="category-main-compliance">
            <div className="category-main-description category-main-description-compliance">
              <h5 className="category-main-name">User's Details and Compliance Scores</h5>
              <div className="category-main-compliance-actions">
                <p>Number of non-compliant users: <b>{mandatoryTrainingUsers?.notComplientUsersCount}</b></p>
                {!downloadLoading
                  ? <button
                      className='actions-download'
                      type="button"
                      onClick={() => exportNotComplientUsersCsv(categoryId, selectedTenant, selectedUser, selectedDepartment, selectedLocation)}
                    >
                      Export csv
                    </button>
                  : <button
                      className='csv-download-loading'
                      type="button"
                    >
                      <span className="mini-loader-csv"></span>
                    </button>
                }
              </div>
            </div>

            <div className={'all-users-select-mandatory'}>
              <label className='userslist-select-label-mandatory'>
                <ReactSelect
                  classNames={'multiselect-user'}
                  options={allUsersOptionList}
                  optionComponent={AllUsersOption}
                  value={selectedUser}
                  placeholder={'User...'}
                  onChange={handleUserChange}
                  isSearchable={true}
                  isClearable={true}
                  optionHeight={50}
                  isDisabled={secondaryLoading || firstFetchLoading}
                  rows={6}
                />
              </label>

              <div className='all-users-select-mandatory-additional'>
                <label className={cn(
                  'userslist-select-label-mandatory-additional department-select',
                  { 'userslist-select-label-mandatory--disabled': selectedUser })
                }>
                  <ReactSelect
                    classNames={'option-select-default'}
                    options={departmentsOptionList}
                    optionComponent={DepartmentOption}
                    value={selectedDepartment}
                    placeholder={'Department...'}
                    onChange={handleDeparmentChange}
                    isSearchable={true}
                    isClearable={true}
                    optionHeight={32}
                    rows={10}
                  />
                </label>
                <label className={cn(
                  'userslist-select-label-mandatory-additional location-select',
                  { 'userslist-select-label-mandatory--disabled': selectedUser })
                }>
                  <ReactSelect
                    classNames={'option-select-default'}
                    options={locationsOptionList}
                    optionComponent={LocationOption}
                    value={selectedLocation}
                    placeholder={'Location...'}
                    onChange={handleLocationChange}
                    isSearchable={true}
                    isClearable={true}
                    optionHeight={32}
                    rows={10}
                  />
                </label>
              </div>
            </div>

            <div>
              <table className="category-table mandatory-users-table">
                <thead className="table-th-category">
                  <tr className="not-clickable-category tr-grid-mandatory-users tr-category">
                    <th>
                      &#8470;
                    </th>
                    <th>
                      Name
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Tenant
                    </th>
                    <th>
                      Department
                    </th>
                    <th>
                      Location
                    </th>
                    <th>
                      Compliance Score
                    </th>
                    <th>
                      Expedited
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body table-body-category">
                  {mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers
                    ?.filter(user => selectedTenant && !selectedUser
                        ? (selectedTenant?.label === user.tenantName && selectedTenant.syncType === getSyncType(user))
                        : selectedUser
                          ? selectedUser?.value === user?.userId
                          : user
                    )
                    ?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                    ?.sort((a, b) => {
                      const viewedMandatoryTrainingEmailsA = a.viewedMandatoryTrainingEmails >= 0 ? a.viewedMandatoryTrainingEmails : 0;
                      const viewedMandatoryTrainingEmailsB = b.viewedMandatoryTrainingEmails >= 0 ? b.viewedMandatoryTrainingEmails : 0;
                      const complianceScoreA = Math.round(viewedMandatoryTrainingEmailsA / (a.allMandatoryTrainingEmails || 1) * 100);
                      const complianceScoreB = Math.round(viewedMandatoryTrainingEmailsB / (b.allMandatoryTrainingEmails || 1) * 100);

                      return complianceScoreA - complianceScoreB;
                    })
                    ?.map((user, index) => {
                      const viewedMandatoryTrainingEmails = user.viewedMandatoryTrainingEmails >= 0 ? user.viewedMandatoryTrainingEmails : 0;
                      const allMandatoryTrainingEmails = user.allMandatoryTrainingEmails;
                      const complianceScore = Math.round(viewedMandatoryTrainingEmails / (allMandatoryTrainingEmails || 1) * 100);
                      const isVisible = selectedRow === user.userId && detailsVisible;

                      return (
                        <tr
                          key={index}
                          className={cn("tr-grid-mandatory-users clickable-row-category tr-category", {
                            "tr-grid-mandatory-users--open": isVisible
                          })}
                          onClick={() => setSelectedRow(user.userId)}
                          >
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{index + 1}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{user.firstName} {user.secondName}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{user.email}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{user.tenantName}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{user.department}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>{user.location}</td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>
                              <span>{`${viewedMandatoryTrainingEmails} of ${allMandatoryTrainingEmails}`}</span>
                              &nbsp;
                              (
                                <span
                                  className={cn({
                                    'list-td-category--low-compliance': complianceScore < 50,
                                    'list-td-category--moderate-compliance': complianceScore >= 50,
                                    'list-td-category--high-compliance': complianceScore > 99,
                                  })}
                                >
                                  {`${complianceScore}%`}
                                </span>
                              )
                            </td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>
                              {user.fastTrackCompliance
                                ? <span className='list-td-status-fast-tack-compliance'>Yes</span>
                                : <span className='list-td-status-not-defined'>No</span>
                              }
                            </td>
                            <td className={cn('list-td-category', {'list-td-category--open': isVisible})}>
                              {!user.fastTrackCompliance ? user.viewedMandatoryTrainingEmails < user.allMandatoryTrainingEmails &&
                                <button
                                  type='button'
                                  className={cn('table-option-mandatory', { 'table-option-mandatory--active': isVisible })}
                                  onClick={() => {
                                    isVisible
                                      ? setDetailsVisible(!detailsVisible)
                                      : setDetailsVisible(true)
                                    setSelectedRow(user.userId)
                                  }}
                                >
                                  <span className={cn("mandatory-flow-list", {
                                    "mandatory-flow-active-list": isVisible
                                  })}>
                                    {isVisible ? 'Hide details' : 'View details'}
                                  </span>
                                </button>
                                : complianceScore < 100 &&
                                    (secondaryLoading && selectedRow === user.userId
                                      ? <div className='mini-loader-container'>
                                          <span className='mini-loader-send-email'></span>
                                        </div>
                                      : <CustomizedTooltipSendMail
                                        text={!user.isResend
                                          ? 'Send an email with a link to the Landing page to watch the video'
                                          : `An email with a link to the Landing page has already been sent to this user today
                                             or is scheduled to be sent later today`
                                        }
                                        placement={'left'}
                                        component={
                                          <button
                                            type='button'
                                            disabled={user.isResend}
                                            className={'table-option-mandatory-sent-email'}
                                            onClick={() => {
                                              setSelectedRow(user.userId);
                                              sendEmailWithLink(user.userId, user.segmentId);
                                            }}
                                          >
                                            <svg viewBox="0 0 122.88 49.21">
                                              <path d="M54.58,0,86.24,27.52,120.63,0ZM35.37,37.6a2.9,2.9,0,0,1,0,5.79H23a2.9,2.9,0,0,1,0-5.79Zm2.89-15.89a2.9,2.9,0,0,1,0,5.79H12.89a2.9,2.9,0,0,1,0-5.79Zm59.92.45L119.54,46.5l3.34-44.11L98.18,22.16Zm-20.94,2.2L50.58,49.21h66.65L95.42,24.37,87.11,31h0a1.72,1.72,0,0,1-2.18,0l-7.69-6.69Zm-28.35,22,25.87-24.1L52.2,2.61l-3.31,43.7ZM38.26,5.82a2.89,2.89,0,1,1,0,5.78H2.89a2.89,2.89,0,0,1,0-5.78Z"/>
                                            </svg>
                                          </button>
                                        }
                                      />
                                    )
                              }
                            </td>
                          {isVisible && detailsVisible &&
                            <>
                              <td
                                className={cn('mandatory-flow-title-td', {'mandatory-flow-title-td--open': selectedRow === user.userId && detailsVisible})}
                                onClick={() => {
                                  setDetailsVisible(!detailsVisible)
                                  setSelectedRow(-1)
                                }}
                              >
                                <span className='mandatory-flow-title'>Unviewed user emails</span>
                              </td>
                              <td className={cn("mandatory-users-table-additional", {
                                "tr-grid-mandatory-users-last": user?.userId === mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.at(-1)?.userId,
                                "mandatory-users-table-additional--open": selectedRow === user.userId && detailsVisible
                              })}>
                                <ul>
                                  <li className='mandatory-flow-li mandatory-flow-ul-title' key={user?.email}>
                                    <span>Segment Name</span>
                                    <span>Template Name</span>
                                    <span>Template Subject</span>
                                    <span></span>
                                  </li>
                                    {user?.notViewedMandatoryTrainingEmails?.map(mail => {
                                      return (
                                        <li className='mandatory-flow-li' key={mail?.emailTemplateId}>
                                          <span>{mail?.segmentName}</span>
                                          <span>{mail?.template}</span>
                                          <span>{mail?.subject}</span>
                                          {mail.emailTemplateId === selectedTemplate
                                            ? <div style={{ display: 'flex', justifyContent: 'center', height: '28px', alignItems: 'center'}}>
                                                <span className="mini-loader-csv"></span>
                                              </div>
                                            : <button
                                                className='mandatory-flow-resend-button'
                                                disabled = {mail.isResend || secondaryLoading}
                                                onClick={() => resendHandler(user.userId, user.segmentId, mail.emailTemplateId)}
                                              >
                                                Resend
                                              </button>
                                          }
                                        </li>
                                      )
                                    })}
                                </ul>
                              </td>
                            </>
                          }
                        </tr>
                      )})
                  }
                </tbody>
              </table>

              {(
                  (page * itemsOnPage < itemsCount && !secondaryLoading && !selectedUser)
                  || (page * itemsOnPage < itemsCount && secondaryLoading && !selectedUser && resendInProgress)
               ) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    className='download-more-list-button'
                    onClick={() => {
                      setPage((prev) => prev + 1)
                      fetchMandatoryTrainingUsers(categoryId, page, selectedTenant, selectedUser, selectedDepartment, selectedLocation)
                    }}
                    type="submit"
                    disabled={resendInProgress}
                  />
                </div>
              }

              {secondaryLoading && !resendInProgress &&
                <div style={{ display: 'flex', justifyContent: 'center', height: '40px'}}>
                  <span className="mini-loader-csv"></span>
                </div>
              }
            </div>
          </div>
        : <Loader />
      }
    </>
  )
};
