import React, { useEffect, useContext } from "react";
import { withRouter } from "./with-router";
import { useNavigate } from 'react-router-dom';
import { storageName } from '../config/config';
import { AuthContext } from "../context/AuthContext";
import { useCrypto } from "../hooks/crypto.hook";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = ({ logout, router }) => {
  let location = router.location;
  const { showToastMessage } = useContext(AuthContext);
  const { decryptData } = useCrypto();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const userItem = localStorage.getItem(storageName);
      const userData = JSON.parse(userItem);
      
      if (userItem && typeof userData === "object") {
        logout();
      }
      
      const user = userItem ? decryptData(userData) : {};

      if (user.token) {
        const decodedJwt = parseJwt(user.token);
  
        if (decodedJwt.exp * 1000 < Date.now()) {
          logout();
          showToastMessage('Session has expired!');
          setTimeout(() => {
            window.location.reload();
          }, 10000)
        }
      }
    } catch (error) {
      console.log('[AuthVerify] Error:', error)
      logout();
    }
  }, [decryptData, location, logout, navigate, showToastMessage]);

  return <div></div>;
};

export default withRouter(AuthVerify);