import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export const ChartBarMailingHistory = ({ chartData }) => {
  return (
    <div className="chart-container-history">
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
              },
              onLeave: function (e) {
                e.native.target.style.cursor = 'default';
              },
              position: 'bottom',
            },
            title: {
              display: true,
            },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 2,
              },
            },
          },
        }}
      />
    </div>
  );
};
