import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import './TrendGraph.css';

export const TrendGraph = ({
  highlited, title, minMailingDate, maxMailingDate,
  graphData, loading, currValue, diffValue,
  tooltipName, percentage, plusIsPositive,
  percentageTicks = true, titleTextX, titleTextY
}) => {
  const { labels, data, minDate, maxDate } = graphData || {};
  const chartData = {
    labels: labels || [],
    datasets: [
      {
        label: title,
        data: data || [],
        fill: false,
        backgroundColor: '#C42D28',
        borderColor: '#C42D28',
        tension: 0.3,
        pointBorderWidth: 1,
      }
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        onClick: null
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipName}: ${tooltipItem.raw}${percentage ? '%' : ''}`,
          beforeLabel: () => '',
        },
        displayColors: false,
      }
    },
    scales: {
      x: {
        display: true,
        position: 'right',
        title: {
          display: !!titleTextX,
          text: titleTextX,
          font: {
            size: 12,
            weight: 'bold',
            color: '#000'
          },
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 10,
            weight: 500,
            color: '#000'
          },
        },
        grid: {
          display: false,
        }
      },
      y: {
        display: true,
        position: 'right',
        title: {
          display: !!titleTextY,
          text: titleTextY,
          font: {
            size: 11,
            weight: 'bold',
            color: '#000'
          },
        },
        border: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
            weight: 500,
            color: '#000'
          },
          callback: (value) => `${value}${percentageTicks ? '%' : ''}`,
        },
        grid: {
          display: true,
          color: '#EDBEBC',
          lineWidth: 1,
          drawTicks: true,
        }
      },
    },
  };

  return (
    <div className={cn('trend-graph-container', {
      'trend-graph-container--highlited': highlited,
      'trend-graph-container--loading': loading
    })}>
      <div>
        <h4>{title}</h4>
        <div className='trend-graph'></div>
      </div>

      {currValue
        ? loading
            ? <div className='metrics-loader-container metrics-loader-container-graph-value'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='trend-graph-curr-value'>
                <span>{currValue?.toString()?.replace('.00', '')}{`${percentage ? '%' : ''}`}</span>
                <span className={cn('trend-graph-value-prev', {
                  'trend-graph-value-prev--positive': (diffValue > 0 && plusIsPositive) || (diffValue < 0 && !plusIsPositive),
                  'trend-graph-value-prev--negative': (diffValue < 0 && plusIsPositive) || (diffValue > 0 && !plusIsPositive),
                })}>
                  {diffValue > 0 && '↑'}
                  {diffValue < 0 && '↓'}
                  {!!diffValue && diffValue?.toString()?.replace('.00', '')?.replace('-', '') + `${percentage ? '%' : ''}`}
                </span>
              </div>
        : null
      }
      <div className='trend-graph-line'>
        <Line
          data={chartData}
          options={chartOptions}
        />
      </div>

      <div className="trend-graph-dates">
      {loading
        ? <div className='metrics-loader-container metrics-loader-container-graph-dates'>
            <span className='metrics-loader'></span>
          </div>
        : <p>
          {
            minDate
              ? minDate
              : moment(minMailingDate).format('MMM YYYY')
          } - {
            minDate
              ? maxDate
              : moment(maxMailingDate).format('MMM YYYY')
          }
        </p>
      }
      </div>
    </div>
  )
};
