import React, { useState, useEffect, useContext } from 'react';
import { useNavigate  } from "react-router-dom";
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import eye from '.././images/icons/Eye.svg';
import closeEye from '.././images/icons/Eye Slash.svg';
import submit from '.././images/icons/Check.svg'
import cn from 'classnames';

export const ChangePassword = ({ authForm, authData, setCard }) => {
  useTitle("PhishFirewall | Change password");

  const { login, token, showToastMessage } = useContext(AuthContext);
  const { request, error, clearError } = useHttp();
  const { encryptData } = useCrypto();
 
  const [input, setInput] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  });
 
  const [inputError, setError] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  });

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  
  const [oldPasswordShown, setOldPasswordShown] = useState(false);

  const toggleOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
 
  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }
 
  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
 
      switch (name) {
        case "oldPassword":
          if (!value) {
            stateObj[name] = "Please enter an Old Password";
          }
          break;
 
        case "password":
          if (!value) {
            stateObj[name] = "Please enter a New Password";
          } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{12,50}$/)) {
            stateObj[name] = "The password must contain lowercase letters, uppercase letters, numbers and be at least 12 characters long";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : inputError.confirmPassword;
          }
          break;
 
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match";
          }
          break;
 
        default:
          break;
      }
 
      return stateObj;
    });
  }

  useEffect(() => {
    clearError()
  }, [error, clearError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
  }

  const navigate = useNavigate ();

  const editHandler = async (e) => {
    try {
      const data = encryptData(input);
      const res = await request('/back_office/api/user/change_password', 'POST', { data }, {
        Authorization: `Bearer ${authData?.token || token}`
      })

      showToastMessage(res.error, res.message);

      if (!res.error && !authForm) navigate('/back_office/user/details');
      else if (!res.error && authForm) {
        login(authData.token, authData.userId);
        showToastMessage(authData.error, authData.message);
      }
    } catch (error) {}
  };

  return (
    <>
      {authForm
        ? <form className="form" onSubmit={handleSubmit}>
            <div className="title">
              <span className="card-title">Change Password</span><br/>
              <span className="card-subtitle">
                Your password has expired. Please create a new one that you have not used before to log in to your account.
              </span>
            </div>
            <div className='back-register'>
              <button
                className='back-register-button'
                onClick={() => setCard('login')}
                type="button"
              >
                Back
              </button>
            </div>
            <div className="edit-card">
              <div className="edit-list-change">
              <div className="input-field">
                <img src={!oldPasswordShown ? eye : closeEye} alt='Eye' className='eye' onClick={toggleOldPassword}></img>
                <label htmlFor="oldPassword">Old Password</label><br />
                <input
                  id="oldPassword"
                  type={oldPasswordShown ? "" : "password"}
                  name="oldPassword"
                  className={cn("input", "validate", {"err-input": inputError.oldPassword})}
                  value={input.oldPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                  {inputError.oldPassword && <span className='err'>{inputError.oldPassword}</span>}
                </div>
                
                <div className="input-field">
                  <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
                  <label htmlFor="password">New Password</label><br />
                  <input
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    className={cn("input", "validate", {"err-input": inputError.password})}
                    value={input.password}
                    onChange={onInputChange}
                    onBlur={validateInput}></input>
                  {inputError.password && <span className='err'>{inputError.password}</span>}
                </div>
        
                <div className="input-field">
                  <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
                  <label htmlFor="confirmPassword">Confirm Password</label><br />
                  <input
                    id="confirmPassword"
                    type={passwordShown ? "text" : "password"}
                    name="confirmPassword"
                    className={cn("input", "validate", {"err-input": inputError.confirmPassword})}
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}></input>
                  {inputError.confirmPassword && <span className='err'>{inputError.confirmPassword}</span>}
                </div>
              </div>
          
              <div className="card-action card-action-auth">
                  <button
                    className='button-submit'
                    onClick={editHandler}
                    disabled={
                      !input.password || !input.confirmPassword || !input.oldPassword ||
                      inputError.password || inputError.confirmPassword || inputError.oldPassword
                    }
                    type="submit"
                  >
                    <img src={submit} alt="submit"></img>
                    Submit
                  </button>
                </div>
            </div>
          </form>
        : <div className='change-password-card'>
            <div className='edit-main details-card'>
              <div className='details-action'>
                  <div>
                    <h3 className='details-action-title'>Change Password</h3>
                    <p className='details-action-subtitle'>Please enter a password that you have not used before</p>
                  </div>
              </div>
              
              <form className="edit-form" onSubmit={handleSubmit}>
                <div className="edit-card">
                  <div className="edit-list-change">
                  <div className="input-field">
                    <img src={!oldPasswordShown ? eye : closeEye} alt='Eye' className='eye' onClick={toggleOldPassword}></img>
                    <label htmlFor="oldPassword">Old Password</label><br />
                    <input
                      id="oldPassword"
                      type={oldPasswordShown ? "" : "password"}
                      name="oldPassword"
                      className={cn("input", "validate", {"err-input": inputError.oldPassword})}
                      value={input.oldPassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                      {inputError.oldPassword && <span className='err'>{inputError.oldPassword}</span>}
                    </div>
                    
                    <div className="input-field">
                      <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
                      <label htmlFor="password">New Password</label><br />
                      <input
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        className={cn("input", "validate", {"err-input": inputError.password})}
                        value={input.password}
                        onChange={onInputChange}
                        onBlur={validateInput}></input>
                      {inputError.password && <span className='err'>{inputError.password}</span>}
                    </div>
            
                    <div className="input-field">
                      <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
                      <label htmlFor="confirmPassword">Confirm Password</label><br />
                      <input
                        id="confirmPassword"
                        type={passwordShown ? "text" : "password"}
                        name="confirmPassword"
                        className={cn("input", "validate", {"err-input": inputError.confirmPassword})}
                        value={input.confirmPassword}
                        onChange={onInputChange}
                        onBlur={validateInput}></input>
                      {inputError.confirmPassword && <span className='err'>{inputError.confirmPassword}</span>}
                    </div>
                  </div>
              
                  <div className="edit-submit">
                      <button
                        className='button-submit'
                        onClick={editHandler}
                        disabled={
                          !input.password || !input.confirmPassword || !input.oldPassword ||
                          inputError.password || inputError.confirmPassword || inputError.oldPassword
                        }
                        type="submit"
                      >
                        <img src={submit} alt="submit"></img>
                        Submit
                      </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
      }
    </>
  );
}
