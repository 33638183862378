import './EmailSignatures.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useNavigate } from 'react-router-dom';

export const EmailSignaturesEdit = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const [companyMetadata, setCompanyMetadata] = useState([]);
  const navigate = useNavigate()

  const getMetadata = useCallback(async () => {
    try {
      if (token) {
        const metadata = await request('/back_office/api/user/company_metadata', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setCompanyMetadata(metadata);

        return metadata;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const handleOnSubmit = async () => {
    try {
      if (token) {
        await request(`/back_office/api/user/company_metadata_edit`, 'POST', companyMetadata, {
          Authorization: `Bearer ${token}`
        })
      }

      return navigate('/back_office/email_settings/email_signatures')
    } catch (error) { }
  }
  const handleLinkChange = (id, value) => {
    const parser = new DOMParser();
    const newPart = value.split('\n').map(e => `${e}<br/>`).join('')
    let document = parser.parseFromString(`<div>${companyMetadata[id]}</div>`, "text/html");

    const links = document.getElementsByTagName('div');

    if (links) {
      links[0].innerHTML = newPart;
    } else {
      return setCompanyMetadata((prevState) => { return { ...prevState, [id]: value } })
    }

    return setCompanyMetadata((prevState) => { return { ...prevState, [id]: document.documentElement.getElementsByTagName('div')[0].outerHTML } })
  }

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);
  if (loading) {
    return <Loader />
  }
  const getInnertext = (html) => {
    const parser = new DOMParser();
    const string = `<div id='test'>${html}</div>`.replace('font', 'div').replace("<br>", "\n")
    return parser.parseFromString(string, "text/html")?.getElementById('test')?.innerText !== 'undefined' ? parser.parseFromString(string, "text/html")?.getElementById('test')?.innerText : '';
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details">
            <div className='signature-card-phish'>
              <div className="signature-account-list-phish">
                <form>
                  <div className="input-field">
                    <label htmlFor="tenantName" className="input-label">Security contact</label>
                    {/* <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                      name={'Tenant ID'}
                    /> */}
                    <textarea
                      id="tenantName"
                      name="tenantName"
                      style={{ resize: 'vertical' }}
                      // placeholder="Enter Tenant Name"
                      className="validate input-edit"
                      defaultValue={getInnertext(companyMetadata['1609'])}
                      onChange={(e) => handleLinkChange('1609', e.target.value)}
                    />
                  </div>
                  <div className="input-field">
                    <label htmlFor="tenantName" className="input-label">HR Contact</label>
                    {/* <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                      name={'Tenant ID'}
                    /> */}
                    <textarea
                      id="tenantName"
                      name="tenantName"
                      style={{ resize: 'vertical' }}
                      // placeholder="Enter Tenant Name"
                      className="validate input-edit"
                      defaultValue={getInnertext(companyMetadata['1611']) || ''}
                      onChange={(e) => handleLinkChange('1611', e.target.value)}
                    />
                  </div>

                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleOnSubmit()
                    }}
                    className="button-submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
