import cn from 'classnames';
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';
Chart.register(...registerables);

export const ChartPieMailingHistory = ({
  chartData, loading, type, startDate, endDate, tenantId, userId,
  selectedDateRange, department, location
  }
) => {
  const navigate = useNavigate();
  const { generalLoading } = useContext(AnalyticsGeneralContext);
  const sum = chartData?.datasets[0]?.data?.reduce((a, b) => a + b, 0);
  let preparedData = chartData;
  let options = {
    plugins: {
      legend: {}
    }
  };

  if (!sum) {
    preparedData = {
      labels: ['No data available'],
      datasets: [
        {
          data: ['1'],
          backgroundColor: ['#F3F3F3'],
          hoverBackgroundColor: ['#F3F3F3'],
          hoverOffset: 0,
          borderWidth: 0,
          hoverRadius: 0,
          hoverBorderWidth: 0
        }
      ]
    };

    options.plugins.tooltip = {
      enabled: false
    };

    options.plugins.legend = {
      onClick: () => {},
    }
  };

  const handleClick = (_, elements) => {
    if (elements.length > 0 && sum && !generalLoading) {
      const index = elements[0].index;

      const typeForQuery = chartData.values[index];
      let queryTypes = `?start_date=${startDate}&end_date=${endDate}&date_range=${selectedDateRange?.value}`;

      if (type === 'actions') queryTypes += `&action_type=${typeForQuery}`;
      if (type === 'emails') queryTypes += `&email_type=${typeForQuery}`;
      if (tenantId) queryTypes += `&tenant_id=${tenantId}`;
      if (userId) queryTypes += `&user_id=${userId}`;
      if (department) queryTypes += `&department=${department}`;
      if (location) queryTypes += `&location=${location}`;

      navigate({
        pathname: window.location.pathname,
        search: queryTypes,
      }, { replace: true });
    }
  };

  return (
    <div className={cn('chart-container-history-pie chart-metric-mailing', {
      'chart-metric-mailing-download': loading || generalLoading,
      'chart-metric-mailing-actions': type === 'actions'
    })}>
      <Pie
        data={preparedData}
        options={{
          responsive: true,
          plugins: {
            ...options.plugins,
            legend: {
              onHover: e => {
                !!sum ? e.native.target.style.cursor = 'pointer' : e.native.target.style.cursor = 'default';
              },
              onLeave: e => {
                e.native.target.style.cursor = 'default';
              },
              position: 'bottom',
              labels: {
                font: {
                  size: 11,
                },
              },
              ...options.plugins.legend
            },
            title: {
              display: false,
            },
          },
          onHover: e => {
            return !!sum && !generalLoading ? e.native.target.style.cursor = 'pointer' : e.native.target.style.cursor = 'default';
          },
          onClick: handleClick,
        }}
      />
      {!sum &&
        <div className='no-data-text'>
          {loading
           ? <span className="mini-loader-csv"></span>
           : `No ${type}`
          }
        </div>
      }
    </div>
  );
};
