import React, { useContext, useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import edit from '../.././images/icons/Pen.svg';
import { SyncLog } from '../../components/SyncLog/SyncLog';
import { SyncGroups } from '../SyncGroups/SyncGroups';
import './Google.css';
import { CustomizedTooltipDisabled } from '../Tooltips/Tooltip';

const customStyles = {
  content: {
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.15)',
    borderRadius: '4px',
  },
};

export const GoogleDetails = () => {
  const { loading, request } = useHttp();
  const { token, user } = useContext(AuthContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [tenants, setTenants] = useState([]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getGoogleTenants = useCallback(async () => {
    try {
      if (token) {
        const tenants = await request(
          '/back_office/api/user/google_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        setTenants(tenants);

        return tenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    getGoogleTenants();
  }, [getGoogleTenants]);
  if (loading) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <>
          <div className="main-details">
            <div className="details-card">
              <div className="details-action-google">
                <h3 className="details-action-title">
                  Google workspace details
                </h3>
              </div>
              {tenants?.map((tenant) => (
                <ul className="details-account-list" key={tenant.id}>
                  <li className="details-wide-item-google">
                    Tenant name
                    <p className="details-value">{tenant.name}</p>
                  </li>
                  <li className="details-wide-item-google">
                    Inclusion Group(s)
                    <p className="details-value">
                      {tenant.inclusionGroup?.activeGroups?.join(', ') || '-'}
                    </p>
                  </li>
                  <li className="details-wide-item-google">
                    Exclusion Group(s)
                    <p className="details-value">
                      {tenant.exclusionGroup?.inactiveGroups?.join(', ') || '-'}
                    </p>
                  </li>
                  {tenant.language && <li className="details-item-google">
                    Language
                    <p className="details-value">{tenant.language}</p>
                  </li>}
                  <li className="details-item-google details-item-button">
                    <NavLink
                      to={`/back_office/user/users_database/google/edit/${tenant.id}`}
                      className="button-link"
                    >
                      <button >
                        <img src={edit} alt="edit"></img>
                        Edit
                      </button>
                    </NavLink>
                  </li>
                </ul>
              ))}
              {(!user?.syncGoogle || (!user?.tenants?.syncAccess && user?.role !== 'owner')) ?
                <CustomizedTooltipDisabled
                  position={'left'}
                  text1={'The Owner did not'}
                  text2={'give you permission'}
                  text3={'to set up a synchronization'}
                  button={<button className="button-submit" onClick={openModal}
                    disabled={!user?.syncGoogle || (!user?.tenants?.syncAccess && user?.role !== 'owner')}
                  >
                    + Add one more google workspace
                  </button>}
                />
                : <button className="button-submit" onClick={openModal}
              >
                + Add one more google workspace
              </button>
              }
            </div>

            <SyncGroups syncType={'google'} />

            {!loading ? (
              <SyncLog
                activeDirectory={true}
                csv={false}
                syncType={'google'}
                google={true}
              />
            ) : (
              <Loader />
            )}
          </div>

          <div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <form className="form">
                <div className="title">
                  <span className="card-title">Google Account</span>
                  <br />
                  <br />
                  <span className="card-subtitle">
                    Before adding a new Google Workspace account, please ensure
                    that you have signed out of all your Google accounts in your
                    browser.
                  </span>
                </div>
                <div className="card-content">
                  <NavLink
                    to="/back_office/user/users_database/google/create"
                    className="edit-submit"
                  >
                    <button className="button-submit" onClick={closeModal}>
                      Got it!
                    </button>
                  </NavLink>
                </div>
              </form>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};
