/* eslint-disable no-unused-vars */
import React, {useContext, useState, useCallback, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import submit from '../.././images/icons/Check.svg'
import { CustomizedTooltip } from '../../components/Tooltips/Tooltip';
import { useCrypto } from '../../hooks/crypto.hook';
import './EditUser.css';
import Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';
import { languageOptions } from '../../config/config';
import eye from '../../images/icons/Eye.svg';
import closeEye from '../../images/icons/Eye Slash.svg';

export const ADEditUser = () => {
  const { token, showToastMessage, fetchUser, user } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const { id } = useParams();

  const [tenant, setTenant] = useState({});
  const [editForm, setEditForm] = useState({});
  const [lang, setLang] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(true);
  const [tenantIdHide, setTenantIdHide] = useState(true);
  const [clientIdHide, setClientIdHide] = useState(true);
  const [clientSecretHide, setClientSecretHide] = useState(true);
  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const Option = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };
  
  const getTenant = useCallback(async () => {
    try {
      if (token) {
        const tenant = await request(`/back_office/api/user/tenant/${id}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        });

        const formatedSecretId = user.companyId.replaceAll('-', '_');

        const secretRes = await request(`/back_office/api/user/get_azure_creds/${formatedSecretId}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        });
        const secret = decryptData(secretRes)
        setTenant(tenant);
        setLang(tenant.language ? { value: tenant.language?.toLowerCase(), label: tenant.language } : null);
        setSelectedFilter(tenant.filterUsers);
        const tenantSecret = secret[tenant.id]

        setEditForm({
          tenantName: tenant.name,
          TenantId: tenantSecret.tenant_id,
          ClientId: tenantSecret.client_id,
          ClientSecret: tenantSecret.client_secret,
          inclusionGroup: tenant.inclusionGroup?.activeGroups?.join(', '),
          exclusionGroup: tenant.exclusionGroup?.inactiveGroups?.join(', '),
        });

        return tenant;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getTenant();
  }, [getTenant]);

  const changeEditHandler = (event) => {
    setEditForm({ ...editForm, [event.target.name] : event.target.value.trim() })
  };
  
  const onSubmit = (event) => {
    event.preventDefault();
  }
  
  const handleLangChange = (data) => {
    setLang(data);
  };

  const navigate = useNavigate();

  const editHandler = async (e) => {
    try {
      const data = encryptData({...editForm, id: tenant?.id, language: lang?.label, filterUsers: selectedFilter})
      const res = await request('/back_office/api/user/azure_edit', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      
      showToastMessage(res.error, res.message, res.warning);
      
      if (res.message) {
        fetchUser();
        navigate('/back_office/user/users_database/ad');
      }
    } catch (error) {}
  }

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div>
        <p className="details-help">
          <span>Need help setting up Azure AD? </span>
          <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.phishfirewall.com/onboarding-instructions/step-two/"
            >
              View our setup instructions.
            </a>
        </p>

        <div className='edit-main details-card'>
          <div className='details-action'>
            <div>
              <h3 className='details-action-title'>Azure Active Directory details</h3>
              <p className='details-action-subtitle'>Set up integration with Active Directory</p>
            </div>
          </div>

          
          <form className="edit-form" onSubmit={onSubmit}>
            <div className="edit-card">
              <div className="edit-content">
                <div className="edit-list">
                  <div className="input-field">
                    <label htmlFor="tenantName" className="input-label input-label-required">Tenant Name</label>
                    <input
                      id="tenantName"
                      name="tenantName"
                      placeholder="Enter Tenant Name"
                      className="validate input-edit"
                      defaultValue={editForm?.tenantName}
                      onChange={changeEditHandler}
                    />
                  </div>
                  <div className="input-field input-field-azure">
                    <label htmlFor="TenantId" className="input-label input-label-required">Tenant ID</label>
                    <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                      name={'Tenant ID'}
                    />
                    <input
                      id="TenantId"
                      name="TenantId"
                      placeholder="Enter Tenant Id"
                      className="validate input-edit"
                      defaultValue={editForm?.TenantId}
                      onChange={changeEditHandler}
                      type={tenantIdHide ? 'password' : 'text'}
                    />
                    <img src={tenantIdHide ? eye : closeEye} alt='Eye' className='azure-eye' onClick={() => setTenantIdHide(!tenantIdHide)}></img>
                  </div>
                  <div className="input-field input-field-azure">
                      <label htmlFor="ClientId" className="input-label input-label-required">Application (client) ID</label>
                      <CustomizedTooltip
                        link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant'}
                        name={'Client ID'}
                      />
                      <input
                        id="ClientId"
                        name="ClientId"
                        placeholder="Enter Application (client) Id"
                        className="validate input-edit"
                        defaultValue={editForm?.ClientId}
                        onChange={changeEditHandler}
                        type={clientIdHide ? 'password' : 'text'}
                      />
                      <img src={clientIdHide ? eye : closeEye} alt='Eye' className='azure-eye' onClick={() => setClientIdHide(!clientIdHide)}></img>
                  </div>
                  <div className="input-field input-field-azure">
                    <label htmlFor="ClientSecret" className="input-label input-label-required">Client secret “value”</label>
                    <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in'}
                      name={'Client secret'}
                    />
                    <input
                      id="ClientSecret"
                      name="ClientSecret"
                      placeholder="Enter Client Secret"
                      className="validate input-edit"
                      defaultValue={editForm?.ClientSecret}
                      onChange={changeEditHandler}
                      type={clientSecretHide ? 'password' : 'text'}
                    />
                    <img src={clientSecretHide ? eye : closeEye} alt='Eye' className='azure-eye' onClick={() => setClientSecretHide(!clientSecretHide)}></img>
                  </div>
                </div>

                <div className="filter-checkbox-sync">
                  <div className='filter-checkbox-label'>
                    <label>
                      <input
                        type="checkbox"
                        className="filter-checkbox"
                        checked={selectedFilter}
                        onChange={() => setSelectedFilter( prevState => !prevState )}
                      />
                    </label>
                  </div>
                  <div>
                    <p className='input-label'>
                      Exclude users who have email address associated with <strong>@onmicrosoft.com</strong> domain.
                    </p>
                    <p className="input-description">
                      By toggling this option on, users with email addresses that end with "@onmicrosoft.com" will be included in 
                      <br /> the User Database. Conversely, toggling it off will exclude such users from the sync.
                    </p>
                  </div>
                </div>

                <div className='input-field-tenant'>
                  <div className='userslist-select'>
                    <label className='userslist-select-label input-label-required'>
                    Phish email language by default
                    </label>
                      <p className="input-description">
                      Select a language for phishing emails. You can customize the language later or choose specific languages for individual users once your user list is synced.
                      </p>
                    <Select
                      id='select'
                      className='validate multiselect-azure'
                      options={languageList}
                      components={{ Option }}
                      placeholder="Select language"
                      value={lang}
                      onChange={handleLangChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </div>
                </div>

                <p className="edit-list-description">You can fill in only one of the options below</p>
                <div className="edit-list-groups">
                  <div className="input-field">
                    <label htmlFor="inclusionGroup" className="input-label">Inclusion Group(s)</label>
                    <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-groups-view-azure-portal'}
                      name={'Inclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    <input
                      id="inclusionGroup"
                      name="inclusionGroup"
                      placeholder="Enter Inclusion Group"
                      className="validate input-edit"
                      defaultValue={editForm?.inclusionGroup}
                      onChange={changeEditHandler}
                      disabled={editForm.exclusionGroup}
                    />
                  </div>
                  <div className="input-field-or">
                    <span>OR</span>
                  </div>
                  <div className="input-field">
                    <label htmlFor="exclusionGroup" className="input-label input-description">
                      Exclusion Group(s)
                    </label>
                    <CustomizedTooltip
                      link={'https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-groups-view-azure-portal'}
                      name={'Exclusion Group'}
                    />
                    <p className="input-description">
                      If you want to add more than 1 group, separate their names with ", "
                    </p>
                    
                    <input
                      id="exclusionGroup"
                      name="exclusionGroup"
                      placeholder="Enter Exclusion Group"
                      className="validate input-edit"
                      defaultValue={editForm?.exclusionGroup}
                      onChange={changeEditHandler}
                      disabled={editForm.inclusionGroup}
                    />
                  </div>
                </div>
              </div>

              <div className="edit-submit">
                <button
                  className='button-submit'
                  onClick={editHandler}
                  disabled={loading || !editForm.TenantId || !editForm.ClientId || !editForm.ClientSecret || !editForm.tenantName}
                  type="submit"
                >
                  <img src={submit} alt="submit"></img>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      }
    </>
  )
}
