import React, { useContext } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import { CustomizedTooltipDescription } from '../../../components/Tooltips/Tooltip';
import { useTitle } from '../../../hooks/title.hook';
import cn from 'classnames';
import './Exclusions.css';

export const Exclusions = () => {
  useTitle("PhishFirewall | Exclusions");

  const { loading } = useHttp();
  const { user } = useContext(AuthContext);

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
        <div className="exclusions-main">
          <h2 className="exclusions-main-title">
            Exclusions
          </h2>

          <nav className="exclusions-nav">
            <NavLink
              to="/back_office/exclusions/users"
              className={({ isActive }) => cn('exclusions-item',
                { 'exclusions-item--active': isActive })}
            >
              Users
            </NavLink>

            <NavLink
              to="/back_office/exclusions/templates"
              className={({ isActive }) => cn('exclusions-item',
                { 'exclusions-item--active': isActive })}
            >
              Email templates
            </NavLink>

            {false && (user.paymentPlanId === 3 || user.paymentPlanId === 4)
              ? <NavLink
                  to="/back_office/exclusions/segments_per_user"
                  className={({ isActive }) => cn('exclusions-item',
                    { 'exclusions-item--active': isActive })}
                >
                  Segments per user
                </NavLink>
              : <CustomizedTooltipDescription
                  title={'Segments per user'}
                  text1={'Not available yet'}
                  // text1={'Available in'}
                  // text2={'PROFESSIONAL and ENTERPRISE'}
                  // text3={'Membership plan'}
                />
            }

            {false && (user.paymentPlanId === 3 || user.paymentPlanId === 4)
              ? <NavLink
                  to="/back_office/exclusions/segments"
                  className={({ isActive }) => cn('exclusions-item',
                    { 'exclusions-item--active': isActive })}
                >
                  Segments per company
                </NavLink>
              : <CustomizedTooltipDescription
                  title={'Segments per company'}
                  text1={'Not available yet'}
                  // text1={'Available in'}
                  // text2={'PROFESSIONAL and ENTERPRISE'}
                  // text3={'Membership plan'}
                />
            }
          </nav>

          <Outlet />
        </div>
      }
    </>
  )
}
