import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import Slider from 'rc-slider';
import { CustomizedTooltipHighlitedText } from '../../Tooltips/Tooltip';
import 'rc-slider/assets/index.css';
import './TrendGraph.css';

const maxBars = 25;

export const TrendGraphBar = (props) => {
  const {
    highlited,
    title,
    minMailingDate,
    maxMailingDate,
    graphData,
    loading,
    currValue,
    diffValue,
    tooltipName,
    percentage,
    plusIsPositive,
    percentageTicks = true,
    titleTextX,
    titleTextY,
    highlitedText
  } = props;

  const { labels = [], data = [] } = graphData || {};

  const [rangeStart, setRangeStart] = useState(0);
  const [leftValue, setLeftValue] = useState('0%');
  const [highlitedTextIndex, setHighlitedTextIndex] = useState(-1);

  const sliderContainerRef = useRef(null);

  const handleSliderChange = (value) => {
    setRangeStart(value);
  };

  useEffect(() => {
    if (labels.length) {
      setRangeStart(Math.max(0, labels.length - maxBars));
      setHighlitedTextIndex(labels.indexOf(highlitedText) + 1)
    }
    if (highlitedTextIndex) {
      setRangeStart(Math.max(0, highlitedTextIndex - Math.round(maxBars / 2)));
    }
  }, [highlitedText, highlitedTextIndex, labels]);

  const backgroundColor = data.map((_, index) => labels[index] === highlitedText ? '#4ca3e4' : '#C42D28');
  const color = data.map((_, index) => labels[index] === highlitedText ? '#4ca3e4' : '#303030');

  const chartData = {
    labels: labels.slice(rangeStart, rangeStart + maxBars) || [],
    datasets: [
      {
        label: title,
        data: data.slice(rangeStart, rangeStart + maxBars) || [],
        fill: false,
        backgroundColor: backgroundColor.slice(rangeStart, rangeStart + maxBars),
        borderColor: backgroundColor.slice(rangeStart, rangeStart + maxBars),
      },
    ],
  };

  useEffect(() => {
    const effectiveMaxValue = Math.max(0, labels.length - maxBars);
    const calculatedLeftValue = Math.min(((rangeStart / effectiveMaxValue) * 100), 100) || 0;

    if (rangeStart === Math.max(0, highlitedTextIndex - Math.round(maxBars / 2)))
      setLeftValue(`${calculatedLeftValue}%`);
  }, [rangeStart, labels.length, highlitedTextIndex]);

  const handleWheelScroll = (event) => {
    if (event.shiftKey || Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      event.preventDefault();

      const delta = event.shiftKey ? Math.sign(event.deltaY) : Math.sign(event.deltaX);

      setRangeStart(prevRangeStart => Math.min(
          Math.max(0, prevRangeStart + delta),
          labels.length - maxBars
        )
      );
    }
  };

  useEffect(() => {
    const sliderElement = sliderContainerRef.current;

    if (sliderElement) {
      sliderElement.addEventListener('wheel', handleWheelScroll);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener('wheel', handleWheelScroll);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels.length, maxBars]);

  return (
    <div className={cn('trend-graph-container', {
      'trend-graph-container--highlited': highlited,
      'trend-graph-container--loading': loading,
    })}>
      <div>
        <h4>{title}</h4>
        <div className='trend-graph'></div>
      </div>

      {currValue
        ? loading
          ? <div className='metrics-loader-container metrics-loader-container-graph-value'>
              <span className='metrics-loader'></span>
            </div>
          : <div className='trend-graph-curr-value'>
              <span>{currValue?.toString()?.replace('.00', '')}{`${percentage ? '%' : ''}`}</span>
              <span className={cn('trend-graph-value-prev', {
                'trend-graph-value-prev--positive': (diffValue > 0 && plusIsPositive) || (diffValue < 0 && !plusIsPositive),
                'trend-graph-value-prev--negative': (diffValue < 0 && plusIsPositive) || (diffValue > 0 && !plusIsPositive),
              })}>
                {diffValue > 0 && '↑'}
                {diffValue < 0 && '↓'}
                {!!diffValue && diffValue?.toString()?.replace('.00', '')?.replace('-', '') + `${percentage ? '%' : ''}`}
              </span>
            </div>
        : null
      }

      <div className='trend-graph-bar'>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maxBarThickness: 100,
            plugins: {
              legend: { display: false },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => `${tooltipName}: ${tooltipItem.raw}${percentage ? '%' : ''}`,
                  beforeLabel: () => '',
                },
                displayColors: false,
              },
            },
            scales: {
              x: {
                display: true,
                position: 'right',
                title: {
                  display: !!titleTextX,
                  text: titleTextX,
                  font: { size: 12, weight: 'bold', color: '#000' },
                },
                border: { display: false },
                ticks: {
                  font: [{ size: 10, weight: 500 }],
                  color: color.slice(rangeStart, rangeStart + maxBars),
                },
                
                grid: { display: false },
              },
              y: {
                display: true,
                position: 'right',
                title: {
                  display: !!titleTextY,
                  text: titleTextY,
                  font: { size: 11, weight: 'bold', color: '#000' },
                },
                border: { display: false },
                beginAtZero: true,
                ticks: {
                  font: { size: 10, weight: 500, color: '#000' },
                  callback: (value) => `${value}${percentageTicks ? '%' : ''}`,
                },
                grid: {
                  display: true,
                  color: '#EDBEBC',
                  lineWidth: 1,
                  drawTicks: true,
                },
              },
            },
          }}
        />
      </div>

      {data.length > maxBars &&
        <div className="trend-graph-slider" ref={sliderContainerRef}>
          <CustomizedTooltipHighlitedText
            placement={labels.length / 2 > highlitedTextIndex ? 'right' : 'left'}
            text={`${highlitedText} - ${data[highlitedTextIndex - 1]}%`}
            component={
              <div
                className={cn('trend-graph-slider-mark', {
                  'trend-graph-slider-mark--show': highlitedTextIndex
                })}
                style={{ left: leftValue, transform: 'translateX(-50%)' }}
                title={highlitedText}
              >
                ▼
              </div>
            }
          />
          <Slider
            min={0}
            max={Math.max(0, labels.length - maxBars)}
            value={rangeStart}
            className='trend-graph-slider-track'
            onChange={handleSliderChange}
            trackStyle={[{ backgroundColor: 'transparent', height: 10 }]}
            handleStyle={{
              borderColor: '#C42D28',
              height: 20,
              width: 30,
              backgroundColor: '#C42D28',
            }}
            drawTicks={true}
            railStyle={{ backgroundColor: '#ddd', height: 10 }}
          />
          <div className='trend-graph-slider--additional'>
            <span
              className={cn({
                'item-highlited': labels[rangeStart] === highlitedText
                })}
            ><b>{labels[rangeStart]}</b> (Rate: {data[rangeStart]}%)</span>
            <span
              className={cn({
                'item-highlited': labels[rangeStart + maxBars - 1] === highlitedText
              })}
            ><b>{labels[rangeStart + maxBars - 1]}</b> (Rate: {data[rangeStart + maxBars - 1]}%)</span>
          </div>
        </div>
      }

      <div className="trend-graph-dates">
        {loading
          ? <div className='metrics-loader-container metrics-loader-container-graph-dates'>
              <span className='metrics-loader'></span>
            </div>
          : <p>
              {moment(minMailingDate).format('MMM YYYY')} - {moment(maxMailingDate).format('MMM YYYY')}
            </p>
        }
      </div>
    </div>
  );
};
