export const removeTrailingZeros = (value) => {
  let str = typeof value === 'number' ? value.toString() : value;

  if (str.includes('.')) {
    str = str.replace(/(\.\d*[1-9])0+$/, '$1').replace(/\.0+$/, '');
  }

  return str;
};

export const handleInputChangeWithMinMaxCheckingAndDelay = (
  e, setValue, minValue, maxValue, updateState, setDisabled, timeoutId, setTimeoutId
) => {
  setDisabled(true);
  const newValue = e.target.value;
  setValue(newValue);

  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  const id = setTimeout(() => {
    let checkedValue = newValue;

    if (parseInt(newValue).toString() !== newValue) {
      checkedValue = parseInt(newValue).toString();
    }

    if (newValue < minValue) {
      checkedValue = minValue;
    } else if (newValue > maxValue) {
      checkedValue = maxValue;
    }

    setValue(checkedValue);
    updateState(checkedValue);

    setDisabled(false);
  }, 1000);
  setTimeoutId(id);
};
