import React, { useState, useCallback, useEffect, useContext } from 'react';
import Select, { components } from "react-select";
import cn from 'classnames';
import moment from 'moment';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { useCrypto } from '../../hooks/crypto.hook';
import { itemsOnPage } from '../../config/config';
import { CustomizedTooltipDisabled, CustomizedTooltipFalsePositive } from '../Tooltips/Tooltip';
import colourStyles from '../../styles/colour-style';
import ReactSelect from '../ReactSelect/ReactSelect';
import { ActionTypeOption, AllUsersOption } from '../ReactSelect/ReactSelectOption';
import { Pagination } from '../Pagination/Pagination';
import './UsersActions.css';

export const UsersActions = () => {
  const { loading, request, requestWithSecondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage, user, allUsers } = useContext(AuthContext);

  const [actions, setActions] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [actionTypes, setActionTypes] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [selectedActionId, setSelectedActionId] = useState(0);
  const [falsePositiveChangingLoading, setFalsePositiveChangingLoading] = useState(false);

  const { items, requestSort, sortConfig } = useSortableData(actions);

  const fetchData = useCallback(async (selectedUser, tenant, actionType) => {
    try {
      if (token) {
        const data = encryptData({ userId: selectedUser?.value, tenant: tenant.label, syncType: tenant.syncType, actionType: actionType?.value });
        const fetched = await request(`/back_office/api/user/sync_users_actions/${page}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`,
        });

        const decryptActions = decryptData(fetched);
        setActions(decryptActions?.rows);
        setCount(decryptActions?.count);
        !actionType && setActionTypes(decryptActions?.actionTypes);

        return fetched;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, page]);

  useEffect(() => {
    const usersTenants = allUsers.map(el => ({
      tenantName: el.tenantName,
      syncType: el.syncType
    }));
    const uniqueObjects = [...new Set(usersTenants.map(JSON.stringify))].map(JSON.parse).sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    const selectTenantArray = [{tenantName: 'All', syncType: null}, ...uniqueObjects]
    const tenantList = selectTenantArray.map((v, i) => { return { value: v?.tenantName?.toLowerCase(), label: v?.tenantName, id: i, tenant: true, syncType: v?.syncType } })

    setTenants(tenantList)
  }, [allUsers, tenants.length]);

  const exportUserActionsCsv = async (selectedUser, tenant, actionType) => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({ userId: selectedUser?.value, tenant : tenant.label, syncType: tenant.syncType, actionType: actionType?.value });
        const response = await fetch('/back_office/api/user/sync_users_actions_csv', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          showToastMessage('Failed to export CSV file!');
          setDownloadLoading(false);
          throw new Error('Download failed');
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        if (selectedUser) a.download = `users_actions__UserID-${selectedUser?.value}__` + new Date().toISOString().split('T')[0] + '.csv';
        else a.download = 'users_actions_'+ new Date().toISOString().split('T')[0] + '.csv';

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with User actions downloaded successfully!');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage('Failed to export CSV file!');
    }
  };

  const allUsersFilteredByTenant = allUsers.filter(user => selectedTenant.label !== 'All'
    ? user.tenantName === selectedTenant.label && user.syncType === selectedTenant.syncType
    : user
  );

  const allUsersOptionList = allUsersFilteredByTenant.map(user => (
    { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName }
  ))

  const actionTypesOptionList = actionTypes?.map(type => (
    {
      value: type,
      label: (" " + type).replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase()),
    }
  ))

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
    setActionType(null);
    setPage(1);
  };

  const handleUserChange = (data) => {
    setSelectedUser(data);
    setActionType(null);
    setPage(1);
  };

  const handleActionChange = (data) => {
    setActionType(data);
    setPage(1);
  };

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  useEffect(() => {
    fetchData(selectedUser, selectedTenant, actionType);
  }, [fetchData, selectedTenant, selectedUser, actionType]);

  const Option = (props) => {
    const { value, label, caption, tenant, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn(`
          syncTypes-label-${value.toString().split(' ').join('-')}
          syncTypes-label-${syncType} userslist-select2-${tenant}`,
          {
            'users-list-tenant-label': tenant
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };

  const updateFalsePositiveActionSatus = async (selectedActionId, falsePositive) => {
    try {
      const data = encryptData({ actionId: selectedActionId, falsePositive });
      const updateStatus = await requestWithSecondaryLoading(
        `/back_office/api/user/update_action_false_positive`,
        'POST',
        { data },
        { Authorization: `Bearer ${token}` }
      );

      return updateStatus;
    } catch (error) { }
  }

  const handleCheckboxChange = async (selectedActionId, falsePositive) => {
    setFalsePositiveChangingLoading(true);
    try {
      const updateStatus = await updateFalsePositiveActionSatus(selectedActionId, falsePositive);
      showToastMessage(updateStatus.error, updateStatus.message);

      if (updateStatus.message) {
        setActions(prevActions => {
          return prevActions.map(action => {
            if (action.id === selectedActionId) {
              return {
                ...action,
                falsePositive: !action.falsePositive
              };
            }
            return action;
          });
        });
      }
    } catch (error) {
      showToastMessage(`Unable to mark action as ${falsePositive ? 'False Positive' : 'Not False Positive'}!`);
    } finally {
      setFalsePositiveChangingLoading(false);
      setSelectedActionId(0);
    }
  };

  const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');

  return (
    <>
      <div className='actions-card'>
        <div className='actions-table-title'>
          <h3>Actions list</h3>
          <div className='actions-table-tenant'>
            <p>Tenant:</p>
            <div className={`input-field-actions-tenant input-field-tenant-${selectedTenant.syncType}`}>
              <Select
                id='select'
                options={tenants}
                components={{ Option }}
                placeholder="Select tenant"
                className="select-edit-actions"
                value={selectedTenant}
                onChange={handleTenantChange}
                isSearchable={true}
                styles={colourStyles}
              />
            </div>
          </div>
          <div className={'all-users-select'}>
            <label className='userslist-select-label'>
              <ReactSelect
                classNames={'multiselect-user'}
                options={allUsersOptionList}
                optionComponent={AllUsersOption}
                placeholder={'User...'}
                value={selectedUser}
                onChange={handleUserChange}
                isSearchable={true}
                isClearable={true}
                optionHeight={50}
                rows={6}
              />
            </label>
          </div>
          <div className={`action-type-select action-type-select-${actionType?.value}`}>
            <label className='userslist-select-label'>
              <ReactSelect
                classNames={'multiselect-action-type'}
                options={actionTypesOptionList}
                optionComponent={ActionTypeOption}
                placeholder={'Action Type...'}
                value={actionType}
                onChange={handleActionChange}
                isSearchable={true}
                isClearable={true}
                optionHeight={32}
                rows={10}
              />
            </label>
          </div>
          {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 &&
            <CustomizedTooltipDisabled
              position={'left'}
              text1={'This feature is available'}
              text2={'in the Professional'}
              text3={'and Enterprise plan'}
            />
          }
          {actions.length
            ? !downloadLoading
              ? <button
                  className='actions-download'
                  type="button"
                  onClick={() => exportUserActionsCsv(selectedUser, selectedTenant, actionType)}
                  disabled={user.paymentPlanId !== 3 && user.paymentPlanId !== 4}
                >
                  Export csv
                </button>
              : <button
                  className='csv-download-loading'
                  type="button"
                >
                  <span className="mini-loader-csv"></span>
                </button>
              : <div style={{'width': '120px'}}></div>
          }

        </div>
        {!loading
          ? !!actions.length
            ? <table className="actions-table">
                <thead className="table-th-actions">
                  <tr className="not-clickable-actions tr-grid-actions tr-actions">
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('firstName')}
                        className={getClassNamesFor('firstName', sortConfig)}
                      >
                        User Name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('email')}
                        className={getClassNamesFor('email', sortConfig)}
                      >
                        User Email
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('segment')}
                        className={getClassNamesFor('segment', sortConfig)}
                      >
                        Segment Name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('template')}
                        className={getClassNamesFor('template', sortConfig)}
                      >
                        Template Name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('templatesubject')}
                        className={getClassNamesFor('templatesubject', sortConfig)}
                      >
                        Template Subject
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('type')}
                        className={getClassNamesFor('type', sortConfig)}
                      >
                        Action Type
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('createdAt')}
                        className={getClassNamesFor('createdAt', sortConfig)}
                      >
                        Created At
                      </button>
                    </th>
                    <th className='th-false-positive'>
                      <button
                        type="button"
                        onClick={() => requestSort('falsePositive')}
                        className={getClassNamesFor('falsePositive', sortConfig)}
                      >
                        False Positive
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="table-body table-body-actions"
                >
                {items.map(action => {
                  const falsePositiveButtonDisabled =
                    (user.role !== 'owner' && user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                    (user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                    user.role === 'admin' ||
                    falsePositiveChangingLoading ||
                    moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth

                  let text1 = 'You do not have access to make these changes';
                  let text2 = '';

                  if (
                    (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                      && moment(action?.createdAt).format('YYYY-MM-DD') >= firstDayOfMonth
                    ) {
                    text1 = 'Mark as';
                    text2 = action?.falsePositive ? 'Not False Positive' : 'False Positive';
                  } else if (
                    (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                      && moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth
                  ) {
                    text1 = 'You can only change the status of actions that were created in the';
                    text2 = 'CURRENT MONTH'
                  }

                  return (
                    <tr
                      key={action.id}
                      className={cn(
                        'tr-grid-actions clickable-row-actions tr-actions',
                        {'false-positive-action': action?.falsePositive}
                      )}
                    >
                      <td>{action?.firstName} {action?.secondName}</td>
                      <td>{action?.email}</td>
                      <td>{action?.segment}</td>
                      <td>{action?.template}</td>
                      <td>{action?.templateSubject}</td>
                      <td>{action?.type}</td>
                      <td>{moment(action?.createdAt).utc().format('MM/DD/YYYY HH:mm:ss')}</td>
                      <td className='td-false-positive'>
                        <label
                          className={"slider-checkbox"}
                        >
                          <input
                            type="checkbox"
                            className='checkbox-input'
                            checked={action?.falsePositive}
                            onClick={() => setSelectedActionId(action.id)}
                            onChange={() => handleCheckboxChange(action.id, !action?.falsePositive)}
                            disabled={falsePositiveButtonDisabled}
                          />
                          <CustomizedTooltipFalsePositive
                            showFragment={!falsePositiveChangingLoading}
                            text1={text1}
                            text2={text2}
                            component={
                              <span className={cn("slider", {
                                "slider-checkbox-disabled": falsePositiveButtonDisabled
                              })} />
                            }
                          />
                          {action.id === selectedActionId && falsePositiveChangingLoading &&
                            <span className="mini-loader"></span>
                          }
                        </label>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            : <div>
                <p className="actions-description">
                  There are no actions yet.
                </p>
              </div>
          : <Loader />
        }
      </div>

      {!!actions.length && !loading &&
        <Pagination
          className="pagination-bar"
          siblingCount={2}
          currentPage={page}
          totalCount={count}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
    </>
  )
}
