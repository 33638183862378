module.exports = {
  INTRODUCTION_FLOW: 1,
  PHISHING_FLOW: 2,
  TRAINING_FLOW: 3,
  MIXED_TRAINING_FLOW: 4,
  MIXED_PHISING_FLOW: 5,
  ADMINISTRATIVE_FLOW: 6,
  MANDATORY_TRAINING_FLOW: 7,
  MONTHLY_UPDATE_FLOW: 8,

  PHISHING_EMAIL: 1,
  TRAINING_EMAIL: 2,
  WARNING_CONGRATS_EMAIL: 3,
  INTRODUNCTION_EMAIL: 4,
  MIXED_TRAINING_EMAIL: 5,
  MIXED_PHISHING_EMAIL: 6,
  ADMINISTRATIVE_EMAIL: 7,
  MANDATORY_TRAINING_EMAIL: 8,
  MANDATORY_TRAINING_LINK_EMAIL: 9,
  INCREASE_RISK_LEVEL_EMAIL: 10,
  MONTHLY_UPDATE_EMAIL: 11,
  EXPEDITED_TRAINING_EMAIL: 12,

  LOW_RISK_LEVEL_ID: 1,
  MODERATE_RISK_LEVEL_ID: 2,
  HIGH_RISK_LEVEL_ID: 3,
};
