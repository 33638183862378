import React, { useState, useContext } from 'react';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { NavLink } from 'react-router-dom';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import arrowback from '../images/icons/Arrow Circle Left.svg';

export const ForgotPassword = () => {
  useTitle("PhishFirewall | Forgot password");

  const auth = useContext(AuthContext);
  const { encryptData } = useCrypto();

  const { loading, request } = useHttp()
  const [forgotForm, setForgotForm] = useState({
    email: '',
  });

  const [card, setCard] = useState(true);

  const changeForgotHandler = (event) => {
    setForgotForm({ ...forgotForm, [event.target.name]: event.target.value.trim() })
  };
  
  const forgotHandler = async () => {
    try {
      const data = encryptData(forgotForm);
      const res = await request('/back_office/api/auth/forgot_password', 'POST', { data });
      auth.showToastMessage(res.error, res.message);
      
      if (!res.error) {
        setCard(!card)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="row">
      {card
        ?
          <form className="form">
            <div className="title">
              <span className="card-title">Forgot password?</span><br/>
              <span className="card-subtitle">
                No worries, we will send you reset instructions.
              </span>
            </div>
            
            <div className="card-content">
              <div className="input-field same-email">
                <label htmlFor="email">Email</label><br/>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder='Your email...'
                  className="input"
                  onChange={changeForgotHandler}
                />
              </div>
            </div>
            
            <div className="card-action">
              <button
                onClick={forgotHandler}
                disabled={loading}
              >
                Send email
              </button>
            </div>

            <div className='signup-action'>
              <img src={arrowback} alt='back'></img>
              <NavLink
                to="/back_office/login"
                className='signin-navlink'
              >
                Back to log in
              </NavLink>
            </div>
          </form>
        :
          <div className="form">
            <div className="title">
              <span className="card-title">Password recovery email was sent.</span><br/>
              <span className="card-subtitle">
                Please check your email and follow the instructions.
              </span>
            </div>
            
            <div className="card-action">
              <NavLink className="button-navlink" to="/back_office/login">
                <button className='button-reverse' onclick="location.href='/back_office'" type="button">
                  Got It
                </button>
              </NavLink>
            </div>
          </div>
      }
    </div>
  )
}