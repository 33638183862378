import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from "../Loader/Loader";
import { useCrypto } from "../../hooks/crypto.hook";
import { SetSchedulerModal } from "../SetSchedulerModal/SetSchedulerModal";
import colourStyles from '../../styles/colour-style';
import submit from '../../images/icons/Check.svg'
import { languageOptions } from '../../config/config';
import { parseAndValidateCSV } from '../../common/csvUtils';
import './CsvUpload.css'

export const CsvUploadCreate = () => {
  const { token, showToastMessage, fetchUser, fetchAllUsers, fetchAllTenants } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();
  const { request } = useHttp();
  const ref = useRef();
  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [csvLoading, setCsvLoading] = useState(false);
  const [lang, setLang] = useState('');
  const [tenantName, setTenantName] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editTenant, setEditTenant] = useState({
    tenantId: '',
    tenantName: '',
    type: '',
    scheduledLaunch: null,
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
    navigate('/back_office/user/users_database/csv/details');
  };

  const changeEditHandler = (event) => {
    setTenantName(event.target.value.trim())
  };
  
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name)
  };

  const handleLangChange = (data) => {
    setLang(data);
  };

  const updateSyncType = async () => {
    try {
      const syncType = 'syncCSV';
      const res = await request('/back_office/api/user/update_sync_type', 'POST', { syncType }, {
        Authorization: `Bearer ${token}`
      })
      showToastMessage(res.error, res.message, res.warning);
      navigate('/back_office/user/users_database/csv/details')
    } catch (error) { }
  }
  
  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`langTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (file) {
        setCsvLoading(true);

        const preparedUsers = await parseAndValidateCSV(file, showToastMessage, lang);

        if (preparedUsers.length) {
          const data = encryptData(preparedUsers);
          const tenant = tenantName.trim();

          const res = await request(
            `/back_office/api/user/csv_create?file=${fileName}&tenant=${tenant}&language=${lang?.label}&edit=false`,
            'POST',
            { data },
            { Authorization: `Bearer ${token}` }
          );

          showToastMessage(res.error, res.message, res.warning);
          setCsvLoading(false);

          if (res.addData) setEditTenant(decryptData(res.addData));
          fetchUser();
          fetchAllUsers();
          fetchAllTenants();
          if (res.message) {
            openModal();
            updateSyncType();
          }
        } else {
          setCsvLoading(false);
        }
      }
    } catch (error) {}
    finally {
      setCsvLoading(false);
      if (ref.current) ref.current.value = null;
      setFile(null);
    }
  };

  return (
    <>
      <p className="details-help">
        <span>Need help setting up sync from a CSV file? </span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.phishfirewall.com/onboarding-instructions/step-two/#toggle-id-3"
        >
          View our setup instructions.
        </a>
      </p>
      <p className="details-help">
        <a
          target="_blank"
          rel="noreferrer"
          href= "/csv_template.csv"
          download
        >
          Download CSV template
        </a>
      </p>

      <div className='csv-card'>
        <div className='csv-card-title'>
          <h2>CSV file uploading</h2>
          <p>Set up integration using CSV file with the list of users, who you want to train.</p>
        </div>

        <form
          onSubmit={() => this.refs.file.value = ''}
        >
          <div className='csv-form input-field-tenant'>
            <div className="csv-input-field">
              <label htmlFor="tenantName" className="csv-input-label input-label-required">Tenant Name</label>
              <input
                id="tenantName"
                name="tenantName"
                placeholder="Enter Tenant Name"
                className="validate input-edit"
                defaultValue={tenantName}
                onChange={changeEditHandler}
              />
            </div>
            <div></div>
            <div className="csv-file-input">
            <input
              type="file"
              accept=".csv"
              onChange={handleOnChange}
              ref={ref}
              disabled={csvLoading}
            />
            </div>
          </div>

          <div className="input-field-tenant">
            <div className='userslist-select'>
              <label className='userslist-select-label input-label-required'>
              Default user language
              </label>
                <p className="input-description">
                If no language is specified for user in the CSV, the default language will be applied.
                </p>
              <Select
                id='select'
                className='validate multiselect-azure'
                options={languageList}
                components={{ languageOption }}
                placeholder="Select language"
                value={lang}
                onChange={handleLangChange}
                isSearchable={true}
                styles={colourStyles}
              />
            </div>
          </div>

          <button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
            className="button-submit"
            disabled={!file || !fileName?.endsWith('.csv') || file?.size > 10485760 || !lang?.label || !tenantName || csvLoading}
          >
            <img src={submit} alt="submit"></img>
            Submit
          </button>
        </form>
      </div>
      {csvLoading && <Loader />}

      <SetSchedulerModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          editTenant={editTenant}
          setEditTenant={setEditTenant}
          callback={null}
        />
    </>
  );
}
