import cn from 'classnames';
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { removeTrailingZeros } from '../../../common/helpers';
Chart.register(...registerables);

export const ChartPieRiskLevels = (
  { chartData, loading, riskData, dataReady }
) => {
  const sum = chartData?.reduce((a, b) => a + b, 0);
  let preparedData = {
    labels: ['High Risk', 'Moderate Risk', 'Low Risk'],
    datasets: [
      {
        label: 'Users',
        data: chartData,
        backgroundColor: [
          '#DE2C37',
          '#ED8F00',
          '#4ca3e4',
        ],
        hoverOffset: 3,
        borderWidth: 1,
        borderColor: '#F3F3F3',
      }
    ]
  };

  if (!sum) {
    preparedData = {
      labels: ['No data available'],
      datasets: [
        {
          data: ['1'],
          backgroundColor: ['#F3F3F3'],
          hoverBackgroundColor: ['#F3F3F3'],
          hoverOffset: 0,
          borderWidth: 0,
          hoverRadius: 0,
          hoverBorderWidth: 0
        }
      ]
    };
  };

  return (
    <div className={cn('chart-container-risk-pie', {
      'chart-metric-mailing-download': loading && !dataReady,
    })}>
      <Pie
        data={preparedData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: !loading || dataReady,
              onHover: e => !!sum ? e.native.target.style.cursor = 'pointer' : e.native.target.style.cursor = 'default',
              onLeave: e => e.native.target.style.cursor = 'default',
              position: 'top',
              labels: {
                font: {
                  size: 12,
                  weight: 600,
                },
                boxWidth: 0,
                generateLabels: (chart) => {
                  const data = chart.data.datasets[0].data;
                  const labels = chart.data.labels;
                  return labels.map((label, i) => {
                    const value = data[i];
                    const percentage = removeTrailingZeros(((value / sum) * 100).toFixed(2));

                    if (Number(percentage) === 0) {
                      return null;
                    }

                    let fontColor = '#000';
                    if (label === 'High Risk') fontColor = '#B72025';
                    if (label === 'Moderate Risk') fontColor = '#ED8F00';
                    if (label === 'Low Risk') fontColor = '#4ca3e4';

                    return {
                      text: !loading || dataReady
                        ? `${label}${label !== 'No data available' ? ':' : ''} ${Number.isFinite(+percentage) ? (percentage + '%'): ''}`
                        : '',
                      fillStyle: chart.data.datasets[0].backgroundColor[i],
                      strokeStyle: chart.data.datasets[0].backgroundColor[i],
                      borderWidth: 0,
                      hidden: !chart.getDataVisibility(i),
                      index: i,
                      fontColor,
                    };
                  }).filter(Boolean);
                }
              }
            },
          }
        }}
      />
    </div>
  );
};
