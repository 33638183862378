import Papa from 'papaparse';
import { csvValidationMaxValueLength } from '../config/config';

export const parseAndValidateCSV = (file, showToastMessage, lang) => {
  return new Promise((resolve, reject) => {
    let preparedUsers = [];

    Papa.parse(file, {
      complete: (results) => {
        const headers = results.data[0];
        let parsedResults = [];

        for (let i = 1; i < results.data.length; i++) {
          const row = results.data[i];

          const isRowEmpty = row.every(field => field.trim() === '');
          if (isRowEmpty) {
            const res = { warning: `Row ${i + 1} is completely empty!` };
            return reject(showToastMessage(res.warning));
          }

          let resultsItem = {};
          for (let j = 0; j < headers.length; j++) {
            resultsItem = { ...resultsItem, [headers[j]]: row[j] };
          }
          parsedResults.push(resultsItem);
        }

        for (const entry of parsedResults) {
          if (!entry['First Name']) {
            const res = { warning: 'There is no "First Name" field in the CSV file!' };
            return reject(showToastMessage(res.warning));
          }
          if (!entry['First Name'].match(/^[\w- .]+$/)) {
            const res = { warning: `First Name in user: ${entry['First Name']} ${entry['Last Name']} contains invalid characters!` };
            return reject(showToastMessage(res.warning));
          }
          if (entry['First Name'].length > csvValidationMaxValueLength) {
            const res = { warning: `First Name in user: ${entry['First Name']} ${entry['Last Name']} exceeds the maximum length of ${csvValidationMaxValueLength} characters!` };
            return reject(showToastMessage(res.warning));
          }

          if (!entry['Last Name']) {
            const res = { warning: 'There is no "Last Name" field in the CSV file!' };
            return reject(showToastMessage(res.warning));
          }
          if (!entry['Last Name'].match(/^[\w- .]+$/)) {
            const res = { warning: `Last Name in user: ${entry['First Name']} ${entry['Last Name']} contains invalid characters!` };
            return reject(showToastMessage(res.warning));
          }
          if (entry['Last Name'].length > csvValidationMaxValueLength) {
            const res = { warning: `Last Name in user: ${entry['First Name']} ${entry['Last Name']} exceeds the maximum length of ${csvValidationMaxValueLength} characters!` };
            return reject(showToastMessage(res.warning));
          }

          if (!entry['Email Address']) {
            const res = { warning: 'There is no "Email Address" field in the CSV file!' };
            return reject(showToastMessage(res.warning));
          }
          if (!entry['Email Address'].match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            const res = { warning: `Email Address in user: ${entry['First Name']} ${entry['Last Name']} is not valid!` };
            return reject(showToastMessage(res.warning));
          }
          if (entry['Email Address'].length > csvValidationMaxValueLength) {
            const res = { warning: `Email Address in user: ${entry['First Name']} ${entry['Last Name']} exceeds the maximum length of ${csvValidationMaxValueLength} characters!` };
            return reject(showToastMessage(res.warning));
          }

          const checkField = (field, fieldName) => {
            if (entry[field]) {
              if (!entry[field].match(/^[\w- .]+$/)) {
                const res = { warning: `${fieldName} in user: ${entry['Email Address']} contains invalid characters!` };
                return reject(showToastMessage(res.warning));
              }
              if (entry[field].length > csvValidationMaxValueLength) {
                const res = { warning: `${fieldName} in user: ${entry['Email Address']} exceeds the maximum length of ${csvValidationMaxValueLength} characters!` };
                return reject(showToastMessage(res.warning));
              }
            }
          };

          checkField('Location', 'Location');
          checkField('Location(optional)', 'Location(optional)');
          checkField('Department', 'Department');
          checkField('Department(optional)', 'Department(optional)');
          checkField('Language', 'Language');
          checkField('Language(optional)', 'Language(optional)');

          preparedUsers.push({
            givenName: entry['First Name'].trim(),
            surname: entry['Last Name'].trim(),
            mail: entry['Email Address'].trim(),
            department: entry['Department']?.trim() || entry['Department(optional)']?.trim(),
            location: entry['Location']?.trim() || entry['Location(optional)']?.trim(),
            language: entry['Language']?.trim() || entry['Language(optional)']?.trim() || lang?.label,
          });
        }

        resolve(preparedUsers);
      },
    });
  });
};
