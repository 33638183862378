import React from 'react';
import cn from 'classnames';
import { NavLink, Outlet } from 'react-router-dom';
import { useTitle } from '../../hooks/title.hook';
import './SysAdminPage.css'

export const SysAdminPage = () => {
  useTitle("PhishFirewall | System Admin Environment");

  return (
    <div className="us-database-main">
      <h2 className="us-database-main-title">
        System Admin Environment
      </h2>

      <div className="us-database">
        <nav className="us-database-nav">
          <NavLink
            to="/back_office/sys_admin/bulk_emailing"
            className={({ isActive }) => cn('us-database-item', {
              'us-database-item--active': isActive,
            })}
          >
            <span>Bulk Emailing</span>
          </NavLink>

          <NavLink
            to="/back_office/user/users_database/all_tenants"
            className={({ isActive }) => cn('us-database-item', {
              'us-database-item--active': isActive,
              'item--disabled': true
            })}
          >
            <span>Sending Emails Individually</span>
          </NavLink>
        </nav>
      </div>

      <Outlet />
    </div>
  )
}
