import React from 'react';
import cn from 'classnames';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { DepartmentOption, LocationOption, TenantOption } from '../../components/ReactSelect/ReactSelectOption';
import MandatoryTrainingAdditionalDetails from './MandatoryTrainingAdditionalDetails';

export const CategoriesSummaryFilterBar = ({
  tenants, locations, departments, loading,
  selectedTenant, setSelectedTenant,
  selectedDepartment, setSelectedDepartment,
  selectedLocation, setSelectedLocation,
  selectSequence, setSelectSequence, categories,
  selectedCategory, setSelectedCategory
}) => {
  const handleDepartmentChange = (data) => {
    setSelectedDepartment(data);
    setSelectedCategory({ id: 0, value: 0, label: 'All Trainings' });

    if (data) {
      setSelectSequence('department');
     } else if (!data && selectSequence === 'department') {
      setSelectSequence(null);
     }
  };

  const handleLocationChange = (data) => {
    setSelectedLocation(data);
    setSelectedCategory({ id: 0, value: 0, label: 'All Trainings' });

    if (data) {
      setSelectSequence('location');
     } else if (!data && selectSequence === 'location') {
      setSelectSequence(null);
     }
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedCategory({ id: 0, value: 0, label: 'All Trainings' });

     if (data) {
      setSelectSequence('tenant');
     } else if (!data && selectSequence === 'tenant') {
      setSelectSequence(null);
     }
  };

  return (
    <>
      <div className='categories-summary'>
        <div className='user-analytics-input'>
          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-tenant'>
              <label>
                <ReactSelect
                  options={tenants}
                  optionComponent={TenantOption}
                  placeholder={'Select Tenant...'}
                  classNames={'option-select-default'}
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={30}
                  rows={10}
                />
              </label>
            </div>
          </div>

          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-department'>
              <label className={'department-select'}>
                <ReactSelect
                  options={departments}
                  optionComponent={DepartmentOption}
                  placeholder={'Select Department...'}
                  classNames={'option-select-default'}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>

          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-location'>
              <label className={'location-select'}>
                <ReactSelect
                  options={locations}
                  optionComponent={LocationOption}
                  placeholder={'Select Location...'}
                  classNames={'option-select-default'}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='categories-summary-details'>
        <MandatoryTrainingAdditionalDetails
          selectedTenant={selectedTenant}
          selectedDepartment={selectedDepartment}
          selectedLocation={selectedLocation}
          categoriesOptions={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
    </>
  );
}
