import React from "react";
import { NavLink } from "react-router-dom";
import check from '../../../images/icons/Check Circle Green Large.svg'
import './CheckoutSession.css'

export const CheckoutSessionSuccess = () => {
  return (
    <div className="billing-page">
      <NavLink
        className="billing-back-link"
        to={'/back_office/user/membership_plan/plans'}
      >
        Back to Membership plans
      </NavLink>
      <div className="billing-cards">
        <div className="billing-cards-wrap">
          <h2>Payment checkout</h2>
          <div>
            <div className="succes-result-card">
              <div className="succes-result-card-alert">
                <img src={check} alt='alert'></img>
                <h4>Payment successful!</h4>
                <span>Your plan is activated, updates will be applied soon!</span>
                <p>The payment will appear in your billing history.</p>
                <p>Next steps:</p>
                <div>
                  <NavLink
                  to='/back_office/groups'
                    className='succes-result-link'
                  >
                      Set up your Groups
                  </NavLink>
                  <NavLink
                  to='/back_office/activity/users_actions'
                    className='succes-result-link'
                  >
                    View User actions
                  </NavLink>
                  <NavLink
                  to='/back_office/activity/mailing_history'
                    className='succes-result-link'
                  >
                    View Mailing history
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}