import React from 'react';
import cn from 'classnames';
import { CustomizedTooltipInfo } from '../../Tooltips/Tooltip';

export const SummaryMetrics = ({ summaryData, loading }) => {
  return (
    <div className='analytics-metrics analytics-metrics-container'>
      <div className='analytics-metrics-item'>
        <h4>Phishing</h4>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.phishClickRate || '0')?.toString()?.replace('.00', '')}%</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffPhishClickRate < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffPhishClickRate > 0
                })}>
                  {summaryData.diffPhishClickRate > 0 && '+'}
                  {!!summaryData.diffPhishClickRate && summaryData.diffPhishClickRate?.toString()?.replace('.00', '') + '%'}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Phish Click Rate</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.phishClickCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffPhishClickCount < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffPhishClickCount > 0
                })}>
                  {summaryData.diffPhishClickCount > 0 && '+'}
                  {!!summaryData.diffPhishClickCount && summaryData.diffPhishClickCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Phish Click Count</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.totalPhishingEmailSent || 0).toLocaleString('en-US')}</span>
              </div>
            }
          <div className='analytics-metrics-label'>Total Phishing Simulations</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.pFReportedEmails || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffPFReportedEmails > 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffPFReportedEmails < 0
                })}>
                  {summaryData.diffPFReportedEmails > 0 && '+'}
                  {!!summaryData.diffPFReportedEmails && summaryData.diffPFReportedEmails}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Phishing Simulations Reported</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.phishSimulationsReportRate || '0')?.toString()?.replace('.00', '')}%</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffPhishSimulationsReportRate > 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffPhishSimulationsReportRate < 0
                })}>
                  {summaryData.diffPhishSimulationsReportRate > 0 && '+'}
                  {!!summaryData.diffPhishSimulationsReportRate && summaryData.diffPhishSimulationsReportRate?.toString()?.replace('.00', '') + '%'}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Phishing Simulations Report Rate</div>
        </div>
      </div>

      <div className='analytics-metrics-item'>
        <h4>Risk</h4>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.phishRisk || 0)?.toString()?.replace('.00', '')}%</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffPhishRisk < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffPhishRisk > 0
                })}>
                  {summaryData.diffPhishRisk > 0 && '+'}
                  {!!summaryData.diffPhishRisk && summaryData.diffPhishRisk?.toString()?.replace('.00', '') + '%'}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Human Risk Rate</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.highRiskUserCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffHighRiskUserCount < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffHighRiskUserCount > 0
                })}>
                  {summaryData.diffHighRiskUserCount > 0 && '+'}
                  {!!summaryData.diffHighRiskUserCount && summaryData.diffHighRiskUserCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>High Risk User Count</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.moderateRiskUserCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffModerateRiskUserCount < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffModerateRiskUserCount > 0
                })}>
                  {summaryData.diffModerateRiskUserCount > 0 && '+'}
                  {!!summaryData.diffModerateRiskUserCount && summaryData.diffModerateRiskUserCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Moderate Risk User Count</div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.realPhishReportedEmails || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffRealPhishReportedEmails > 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffRealPhishReportedEmails < 0
                })}>
                  {summaryData.diffRealPhishReportedEmails > 0 && '+'}
                  {!!summaryData.diffRealPhishReportedEmails && summaryData.diffRealPhishReportedEmails}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>Real Phish Reported</div>
        </div>
      </div>

      <div className='analytics-metrics-item'>
        <h4>Engagement</h4>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.engagementRate || '0')?.toString()?.replace('.00', '')}%</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffEngagementRate > 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffEngagementRate < 0
                })}>
                  {summaryData.diffEngagementRate > 0 && '+'}
                  {!!summaryData.diffEngagementRate && summaryData.diffEngagementRate?.toString()?.replace('.00', '') + '%'}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'>
            Engagement Rate
            <CustomizedTooltipInfo
              text={
                `Engagement Rate is calculated only for full completed months.`
              }
            />
          </div>
        </div>

        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.totalEducationalEmailsSent || 0).toLocaleString('en-US')}</span>
              </div>
          }
          <div className='analytics-metrics-label'>Educational Emails Sent</div>
        </div>

        {/* <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.nonEngagedUsersCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffNonEngagedUsersCount < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffNonEngagedUsersCount > 0
                })}>
                  {summaryData.diffNonEngagedUsersCount > 0 && '+'}
                  {!!summaryData.diffNonEngagedUsersCount && summaryData.diffNonEngagedUsersCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Non Engaged Users</div>
        </div> */}

        {/* Temporary disabled Under Engaged Users */}
        <div className="analytics-metrics-item-container">
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>{(summaryData.underEngagedUsersCount || 0).toLocaleString('en-US')}</span>
                <span className={cn('analytics-metrics-value-prev', {
                  'analytics-metrics-value-prev--positive': summaryData.diffUnderEngagedUsersCount < 0,
                  'analytics-metrics-value-prev--negative': summaryData.diffUnderEngagedUsersCount > 0
                })}>
                  {summaryData.diffUnderEngagedUsersCount > 0 && '+'}
                  {!!summaryData.diffUnderEngagedUsersCount && summaryData.diffUnderEngagedUsersCount}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Under Engaged Users</div>
        </div>
      </div>

      <div className='analytics-metrics-item'>
        <h4>General</h4>
        <div className='analytics-metrics-item-general'>
          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(summaryData.totalUsersCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Users</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
              <span className='analytics-metrics-value-current'>
                {(summaryData.tenantIds?.length || 0).toLocaleString('en-US')}
              </span>
            </div>
          }
          <div className='analytics-metrics-label'># of Tenants</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(summaryData.departments?.length || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Departments</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(summaryData.locations?.length || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of Locations</div>

          {loading
            ? <div className='metrics-loader-container'>
                <span className='metrics-loader'></span>
              </div>
            : <div className='analytics-metrics-value'>
                <span className='analytics-metrics-value-current'>
                  {(summaryData.recentUsersCount || 0).toLocaleString('en-US')}
                </span>
              </div>
          }
          <div className='analytics-metrics-label'># of New Employees</div>
        </div>
      </div>
    </div>
  )
}
