import React from "react";
import './PlansTable.css';
import check from '../../images/icons/Check Icon.svg'

export const PlansTable = ({
    smallBizMinUsers, smallBizMaxUsers, proffesionalMaxUsers, enterpriseMaxUsers
}) => {
  return (
    <div className='plans-table-card'>
        <div className='plans-table-title'>
          <h3>Full feature matrix</h3>
        </div>
        <table className="plans-table">
          <thead className="table-th-plans">
            <tr style={{'width': '100%'}} className="not-clickable-plans tr-grid-plans tr-plans">
              <th style={{'width': '28%'}}>Feature</th>
              <th style={{'width': '18%'}}>Free</th>
              <th style={{'width': '18%'}}>Small Biz</th>
              <th style={{'width': '18%'}}>Professional</th>
              <th style={{'width': '18%'}}>Enterprise</th>
            </tr>
          </thead>
          <tbody className="table-body-plans">
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Users</td>
              <td>Up to {smallBizMinUsers}</td>
              <td>Up to {smallBizMaxUsers}</td>
              <td>Up to {proffesionalMaxUsers}</td>
              <td>Up to {enterpriseMaxUsers}</td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Basic Security Awareness Training</td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Autonamous Phishing</td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Advanced analytics</td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Time based phishing</td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Phish reporting tool</td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>GRC Controls & Reporting</td>
              <td></td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Advanced Language Controls<br/>
                <ul>
                  <li>Spanish</li>
                  <li>French</li>
                  <li>German</li> 
                  <li>+7 Other</li>
                </ul>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Role Based Training<br/>
                <ul>
                  <li>IT Security Treats</li>
                  <li>C-Suit Cybersecurity</li>
                  <li>OWASP Top 10 Developer</li> 
                  <li>HR</li>
                  <li>Finance</li>
                  <li>InfoSec Defenders</li>
                </ul>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr className={"tr-grid-plans clickable-row-plans tr-plans"}>
              <td>Role Based Phishing</td>
              <td></td>
              <td></td>
              <td></td>
              <td><img src={check} alt='check'></img></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className="tr-grid-plans-button"><button>Get subscription</button></td>
              <td className="tr-grid-plans-button"><button>Get subscription</button></td>
              <td className="tr-grid-plans-button"><button>Get subscription</button></td>
            </tr>
          </tbody>
        </table>
      </div>
  )
}
