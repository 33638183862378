import React, { useContext, useState, useCallback, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useTitle } from "../../../hooks/title.hook";
import { useHttp } from "../../../hooks/http.hook";
import { AuthContext } from "../../../context/AuthContext";
import { useCrypto } from "../../../hooks/crypto.hook";
import { Loader } from "../../Loader/Loader";
import './BillingHistory.css';

export const Invoices = () => {
  useTitle("PhishFirewall | Invoices");

  const { loading, request } = useHttp();
  const { token, showToastMessage } = useContext(AuthContext);
  const { decryptData, encryptData } = useCrypto();

  const [card, setCard] = useState(2);
  const [items, setItems] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/payment/invoices', 'GET', null, {
          Authorization: `Bearer ${token}`,
        });

        const decryptHistory = decryptData(fetched);
        setItems(decryptHistory);

        showToastMessage(fetched.error, fetched.message, fetched.warning);
  
        return fetched;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const downloadInvoice = async (name, downloadLink) => {
    try {
      if (token) {
        const data = encryptData({ name, downloadLink });
        const response = await axios.post('/back_office/api/payment/invoices/download', { data }, {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.error) {
          showToastMessage(response.error);
        } else {
          const contentType = response.headers['content-type'];

          const blob = new Blob([response.data], { type: contentType });

          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = name;
          link.click();

          showToastMessage(null, `File ${name} downloaded successfully!`);
        }
      }
    } catch (error) {
      showToastMessage('Unable to download a file');
    }
  }

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!items?.length) setCard(1);
    else setCard(2);
  }, [items, loading]);

  if (loading) {
    return <Loader />
  }
  
  return (
    <>
      {!loading &&
        <div className='actions-card'>
          {card === 1 &&
            <div>
              <p className="actions-description">
                There are no invoices yet.
              </p>
            </div>
          }
          
          {card === 2 &&
            <>
              <div className='billing-table-title'>
                <h3>Invoices</h3>
              </div>
              <table className="billing-table invoices-table">
                <thead className="table-th-billing">
                  <tr className="not-clickable-billing tr-grid-billing tr-billing">
                    <th>Invoice</th>
                    <th>Tenant name</th>
                    <th>Billing date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {items.length ? <tbody
                  className="table-body table-body-billing"
                >
                  {items.map(i => (
                    <tr
                      key={i.paymentNumber}
                      className={"tr-grid-billing clickable-row-billing tr-billing"}
                    >
                      <td>{i.name}</td>
                      <td>{i.tenantName}</td>
                      <td>{moment(i.billingDate).format("MMM DD, YYYY")}</td>
                      <td>${(i.amount).toLocaleString("en-US")}</td>
                      <td><div className="billing-status">Paid</div></td>
                      <td>
                        <button
                          type="button"
                          onClick={() => downloadInvoice(i.name, i.download)}
                          className="billing-button"
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                : <tbody></tbody>}
              </table>
            </>
          }
        </div>
      }
    </>
  )
}