import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import ReactSelect from '../ReactSelect/ReactSelect';
import { AllUsersOption, DateRangeOption, DepartmentOption, LocationOption, TenantOption } from '../ReactSelect/ReactSelectOption';
import { useHttp } from '../../hooks/http.hook';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';
import { dateRangeOptions, lastThirtyDaysChunk } from '../../config/config';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import 'react-datepicker/dist/react-datepicker.css';

export const MailingAndActionsFilterBar = ({
  selectedTenant, setSelectedTenant, selectedUser, setSelectedUser,
  startDate, setStartDate, endDate, setEndDate, minDate, setMinDate,
  maxDate, setMaxDate, selectedDateRange, setSelectedDateRange,
  startDateParam, endDateParam, removeQueryParams,
  dateRangeOptionsList, setDateRangeOptionsList,
  selectedDepartment, setSelectedDepartment,
  selectedLocation, setSelectedLocation,
}) => {
  const { request } = useHttp();
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations,
    generalLoading
  } = useContext(AnalyticsGeneralContext);
  const { allUsers, token } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);

  const defaultStartDate = moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD');

  useMemo(async () => {
    if (!token) return;

    try {
      const data = encryptData({
        tenantId: selectedTenant?.value,
        userId: selectedUser?.value,
        department: selectedDepartment?.value,
        location: selectedLocation?.value
      });

      const fetched = await request('/back_office/api/analytics/min_date', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      });

      const decryptedFetchedData = decryptData(fetched);
      startDateParam && setStartDate(startDateParam);
      endDateParam && setEndDate(endDateParam);
      setMinDate(decryptedFetchedData?.minDate);
      setMaxDate(moment().format('YYYY-MM-DD'));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, selectedUser, startDateParam, selectedDepartment, selectedLocation, endDateParam]);

  const handleTenantChange = useCallback((data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(defaultStartDate);
    setEndDate(moment().format('YYYY-MM-DD'));
  }, [defaultStartDate, removeQueryParams, setEndDate, setSelectedDateRange, setSelectedTenant, setSelectedUser, setStartDate]);

  const handleDepartmentChange = useCallback((data) => {
    setSelectedDepartment(data);
    setSelectedUser(null);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(defaultStartDate);
    setEndDate(moment().format('YYYY-MM-DD'));
  }, [defaultStartDate, removeQueryParams, setEndDate, setSelectedDateRange, setSelectedDepartment, setSelectedUser, setStartDate]);

  const handleLocationChange = useCallback((data) => {
    setSelectedLocation(data);
    setSelectedUser(null);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(defaultStartDate);
    setEndDate(moment().format('YYYY-MM-DD'));
  }, [defaultStartDate, removeQueryParams, setEndDate, setSelectedDateRange, setSelectedLocation, setSelectedUser, setStartDate]);

  const handleUserChange = useCallback((data) => {
    setSelectedUser(data);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(defaultStartDate);
    setEndDate(moment().format('YYYY-MM-DD'));
  }, [defaultStartDate, removeQueryParams, setEndDate, setSelectedDateRange, setSelectedUser, setStartDate]);

  const handleDateRangeChange = useCallback((data) => {
    if (!data || data?.value === 0) {
      setStartDate(minDate);
      setEndDate(maxDate);
    } else {
      setStartDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    }
    setSelectedDateRange(data);
    removeQueryParams();
  }, [setSelectedDateRange, removeQueryParams, setStartDate, minDate, setEndDate, maxDate]);

  const filteredUsers = useMemo(() =>
    allUsers.filter(user => (
        (!selectedTenant || user.tenantId === selectedTenant?.value) &&
        (!selectedDepartment || user.department === selectedDepartment?.value) &&
        (!selectedLocation || user.location === selectedLocation?.value)
    )),
    [allUsers, selectedTenant, selectedDepartment, selectedLocation]
  );

  const tenantOptions = useMemo(() =>
    selectedTenant && !selectedDepartment && !selectedLocation
      ? tenants
      : tenants.filter(({ id }) => filteredUsers.some(({ tenantId }) => tenantId === id)),
  [filteredUsers, selectedDepartment, selectedLocation, selectedTenant, tenants]);

  const departmentOptions = useMemo(() =>
    selectedDepartment && !selectedTenant && !selectedLocation
      ? departments
      : departments.filter(({ value }) => filteredUsers.some(({ department }) => department === value)),
  [selectedDepartment, selectedTenant, selectedLocation, departments, filteredUsers]);

  const locationOptions = useMemo(() =>
    selectedLocation && !selectedTenant && !selectedDepartment
      ? locations
      : locations.filter(location => filteredUsers.some(user => user.location === location.value)),
  [filteredUsers, locations, selectedDepartment, selectedLocation, selectedTenant]);

  const allUsersOptionList = useMemo(() => filteredUsers?.map(user => ({
    value: user.id,
    label: user.email,
    caption: `${user.firstName} ${user.secondName}`,
    tenantId: user.tenantId
  })), [filteredUsers]);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);

    const minDateDiff = moment().diff(moment(minDate), 'days');
    const maxDateDiff = moment().diff(moment(maxDate), 'days');
    setDateRangeOptionsList(dateRangeOptions.filter(option => (option.value <= minDateDiff && option.value >= maxDateDiff) || option.value === 0));
  }, [contextDepartments, contextLocations, contextTenants, maxDate, minDate, setDateRangeOptionsList]);

  return (
    <div className='history-card history-card-mailing'>
      <div className="input-container-history-actions">
        <div className="input-container-history-actions-select">
          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': generalLoading })}>
            <div className='executive-input-field-analytics-tenant'>
              <label className={cn('userslist-select-label', { 'input-edit-history-loading': generalLoading })}>
                <ReactSelect
                  options={tenantOptions}
                  optionComponent={TenantOption}
                  placeholder={'Select Tenant...'}
                  classNames={'option-select-default'}
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable
                  isClearable
                  isDisabled={generalLoading}
                  optionHeight={30}
                  rows={10}
                />
              </label>
            </div>
          </div>

          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': generalLoading })}>
            <div className='executive-input-field-analytics-department'>
              <label className={'department-select'}>
                <ReactSelect
                  options={departmentOptions}
                  optionComponent={DepartmentOption}
                  placeholder={'Select Department...'}
                  classNames={'option-select-default'}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={generalLoading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>

          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': generalLoading })}>
            <div className='executive-input-field-analytics-location'>
              <label className={'location-select'}>
                <ReactSelect
                  options={locationOptions}
                  optionComponent={LocationOption}
                  placeholder={'Select Location...'}
                  classNames={'option-select-default'}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={generalLoading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="input-container-history-actions-select">
          <div className={cn('all-users-select', { 'input-container-analytics-item--disabled': generalLoading })}>
            <label className={cn('userslist-select-label', { 'input-edit-history-loading': generalLoading })}>
              <ReactSelect
                classNames={'multiselect-user'}
                options={allUsersOptionList}
                optionComponent={AllUsersOption}
                placeholder={'Select User...'}
                onChange={handleUserChange}
                value={selectedUser}
                isSearchable
                isClearable
                isDisabled={generalLoading}
                optionHeight={50}
                rows={6}
              />
            </label>
          </div>

          <div className='history-date-range-container'>
            <p>Date range:</p>
            <div className='input-field-history-date-range-container'>
              <DatePicker
                selected={startDate ? moment(startDate).toDate() : null}
                onChange={(date) => {
                  setStartDate(moment(date).format('YYYY-MM-DD'));
                  setSelectedDateRange({ value: -1, label: 'Custom range' });
                  removeQueryParams();
                }}
                selectsStart
                onKeyDown={(e) => e.preventDefault()}
                minDate={minDate ? moment(minDate).toDate() : null}
                maxDate={endDate ? moment(endDate).toDate() : null}
                className={cn("input-edit-history", { 'input-edit-history-loading': generalLoading })}
                disabled={generalLoading}
                placeholderText="Enter Date"
              />
              <p>&#11020;</p>
              <DatePicker
                selected={endDate ? moment(endDate).toDate() : null}
                onChange={(date) => {
                  setEndDate(moment(date).format('YYYY-MM-DD'));
                  setSelectedDateRange({ value: -1, label: 'Custom range' });
                  removeQueryParams();
                }}
                selectsStart
                onKeyDown={(e) => e.preventDefault()}
                minDate={startDate ? moment(startDate).toDate() : null}
                maxDate={maxDate ? moment(maxDate).toDate() : null}
                className={cn("input-edit-history", { 'input-edit-history-loading': generalLoading })}
                disabled={generalLoading}
                placeholderText="Enter Date"
              />
            </div>

            <div className='date-range-select'>
              <label className={cn('date-range-select-label', { 'input-edit-history-loading': generalLoading })}>
                <ReactSelect
                  options={dateRangeOptionsList}
                  optionComponent={DateRangeOption}
                  placeholder={'Date Range...'}
                  onChange={handleDateRangeChange}
                  value={selectedDateRange}
                  isSearchable
                  isDisabled={generalLoading}
                  optionHeight={28}
                  rows={10}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
