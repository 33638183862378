import React from 'react';
import { TemplateProneTable } from './TemplateProneTable';

export const ProneTable = ({ tablesData, loading, callback, downloadLoading, height }) => {
  return (
    <div className="prone-tables-grid">
      {tablesData?.map((table, index) => (
        <TemplateProneTable
          key={index}
          title={table.title}
          columns={table.columns}
          data={table.data}
          loading={loading}
          callback={callback}
          downloadLoading={downloadLoading}
          height={height}
        />
      ))}
    </div>
  );
};
