import React from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import './DetailsPage.css';
import { AccountDetails } from './AccountDetails';

export const DetailsPage = () => {
  const { loading } = useHttp();

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details">
            <AccountDetails />
          </div>
        </>
      }
    </>
  )
}
