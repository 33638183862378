import React, { useContext, useState, useCallback, useEffect } from "react";
import { useTitle } from "../../../hooks/title.hook";
import { useHttp } from "../../../hooks/http.hook";
import { AuthContext } from "../../../context/AuthContext";
import './BillingHistory.css';
import { useCrypto } from "../../../hooks/crypto.hook";
import { Loader } from "../../Loader/Loader";

export const BillingHistory = () => {
  useTitle("PhishFirewall | Billing History");

  const { loading, request } = useHttp();
  const { token, showToastMessage } = useContext(AuthContext);
  const { decryptData } = useCrypto();

  const [card, setCard] = useState(2);
  const [items, setItems] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/payment/billing_history', 'GET', null, {
          Authorization: `Bearer ${token}`,
        });

        const decryptHistory = decryptData(fetched);
        setItems(decryptHistory);

        showToastMessage(fetched.error, fetched.message, fetched.warning);
  
        return fetched;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!items?.length) setCard(1);
    else setCard(2);
  }, [items, loading]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  if (loading) {
    return <Loader />
  }
  
  return (
    <>
      {!loading &&
        <div className='actions-card'>
          {card === 1 &&
            <div>
              <p className="actions-description">
                There are no billing history yet.
              </p>
            </div>
          }
          
          {card === 2 &&
            <>
              <div className='billing-table-title'>
                <h3>Billing history</h3>
              </div>
              <table className="billing-table">
                <thead className="table-th-billing">
                  <tr className="not-clickable-billing tr-grid-billing tr-billing">
                    <th>Payment</th>
                    <th>Billing date</th>
                    <th>Amount</th>
                    <th>Plan</th>
                    <th>Users</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {items.length ? <tbody
                  className="table-body table-body-billing"
                >
                  {items.map(i => (
                    <tr
                      key={i.paymentNumber}
                      className={"tr-grid-billing clickable-row-billing tr-billing"}
                    >
                      <td>Payment #{i.paymentNumber} - {formatDate(i.billingDate)}</td>
                      <td>{formatDate(i.billingDate)}</td>
                      <td>${(i.amount / 100).toLocaleString("en-US")}</td>
                      <td>{i.plan}</td>
                      <td>{i.users}</td>
                      <td><div className="billing-status">{i.status}</div></td>
                      <td>
                        <a href={`${i.download}`}>
                          <button className="billing-button">Download</button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                : <tbody></tbody>}
              </table>
            </>
          }
        </div>
      }
    </>
  )
}