import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';

import './UsersFilters.css';

export const UsersFilters = (props) => {
    const { setFilteredUsers, users  } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSyncType, setSyncType] = useState({
    value: 'all',
    label: 'All',
    id: 0,
    syncType: null,
  });
  const [selectedTenant, setSelectedTenant] = useState({
    value: 'all',
    label: 'All',
    id: 0,
    syncType: null,
  });
  const [usersTenants, setUsersTenants] = useState([]);

  const filterUsers = (items) =>
    items.filter(
      (user) =>
        (selectedTenant.value !== 'all'
          ? user.tenantName === selectedTenant.label &&
            user.syncType === selectedTenant.syncType
          : true) &&
        (selectedSyncType.value !== 'all'
          ? user?.syncType === selectedSyncType.value
          : true) &&
        (selectedUser?.value ? user?.email === selectedUser?.label : true)
    );

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    // setSelectedRows([]);
    setSelectedUser(null);
  };

  const handleUserChange = (data) => {
    setSelectedUser(data);
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    // setSelectedRows([]);
    setSelectedTenant({ value: 'all', label: 'All', id: 0, syncType: null });
    setSelectedUser(null);
  };

  useEffect(() => {
    const tenants = users.map((el) => ({
      tenantName: el.tenantName,
      syncType: el.syncType,
    }));
    const uniqueObjects = [...new Set(tenants.map(JSON.stringify))]
      .map(JSON.parse)
      .sort((a, b) => a.tenantName.localeCompare(b.tenantName))
      .filter((tenant) =>
        selectedSyncType.label !== 'All'
          ? tenant.syncType === selectedSyncType.value
          : tenant
      );
    const selectTenantArray = [
      { tenantName: 'All', syncType: null },
      ...uniqueObjects,
    ];
    const tenantList = selectTenantArray.map((v, i) => {
      return {
        value: v?.tenantName?.toLowerCase(),
        label: v?.tenantName,
        id: i,
        tenant: true,
        syncType: v?.syncType,
      };
    });

    setUsersTenants(tenantList);
  }, [users, selectedSyncType]);

  const syncTypes = [
    { value: 'all', label: 'All', id: 0 },
    { value: 'azure', label: 'Azure Active Directory', id: 1 },
    { value: 'csv', label: 'CSV', id: 2 },
    { value: 'google', label: 'Google Workspace', id: 3 },
  ];

  const allUsersOptionList = users
    .filter((user) =>
      selectedTenant.label !== 'All'
        ? user.tenantName === selectedTenant.label &&
          user.syncType === selectedTenant.syncType
        : user && selectedSyncType.label !== 'All'
        ? user.syncType === selectedSyncType.value
        : user
    )
    .map((user) => ({
      value: user.id,
      label: user.email,
      caption: user.firstName + ' ' + user.secondName,
    }));

  const Option = (props) => {
    const { label, value, tenant, caption, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div
          className={cn(
            `syncTypes-label-${value.toString().split(' ').join('-')}`,
            `syncTypes-label-${syncType}`,
            `actions-label-${value.toString().split(' ').join('-')}`,
            'users-filter-label',
            {
              'users-list-tenant-label': tenant,
            }
          )}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className="caption">{caption}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    const filteredUsers = filterUsers(users);
    setFilteredUsers(filteredUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, selectedSyncType, selectedUser]);

  return (
    <>
      <div className="all-filters-input">
        <div
          className={`user-filters-select user-filters-select2-${selectedSyncType.value} user-filters-select-sync`}
        >
          <label className="user-filters-select-label">
            Sync method
            <Select
              id="select"
              className="multiselect"
              options={syncTypes}
              components={{ Option }}
              placeholder="Select sync type"
              value={selectedSyncType}
              onChange={handleSyncTypeChange}
              isSearchable={true}
              styles={colourStyles}
            />
          </label>
        </div>
        <div
          className={`user-filters-select user-filters-select-tenant user-filters-field-tenant-${selectedTenant.syncType}`}
        >
          <label className="user-filters-select-label">
            Tenant
            <Select
              id="select"
              className="multiselect"
              options={usersTenants}
              components={{ Option }}
              placeholder="Select tenant"
              value={selectedTenant}
              onChange={handleTenantChange}
              isSearchable={true}
              styles={colourStyles}
            />
          </label>
        </div>

        <div className={'filter-users-select'}>
          <label className="user-filters-select-label">
            User
            <Select
              id="select"
              className="multiselect"
              options={allUsersOptionList}
              components={{ Option }}
              value={selectedUser}
              placeholder="Search user..."
              onChange={handleUserChange}
              isSearchable={true}
              isClearable={true}
              styles={colourStyles}
            />
          </label>
        </div>
      </div>

      {/* <div
        className={cn('users-filter-search-container', {
          'users-filter-search-container-empty':
            !selectedRows.length || !selectedUsersShown,
        })}
      >
        <div className="users-filter-selected-bar">
          <button
            className={
              selectedUsersShown
                ? 'users-filter-email-button-active'
                : 'users-filter-email-button'
            }
            onClick={() => setSelectedUsersShown((prev) => !prev)}
          >
            {selectedRows.length < 2
              ? `${selectedRows.length} user selected`
              : `${selectedRows.length} users selected`}
          </button>
          {!!selectedRows.length && (
            <button
              className="users-filter-secondary-button"
              onClick={() => setSelectedRows([])}
            >
              Clear all
            </button>
          )}
        </div>
      </div>
      {!!selectedUsersShown && (
        <div className="users-filter-emails-container">
          {selectedRows.map((selectedId) => (
            <div className="users-filter-email-container">
              {users.find((u) => u.id === selectedId)?.email}
              <span className="users-filter-email-container-img">
                <img
                  onClick={() =>
                    setSelectedRows((prev) =>
                      prev.filter((id) => id !== selectedId)
                    )
                  }
                  src={cross}
                  alt="delete"
                ></img>
              </span>
            </div>
          ))}
        </div>
      )} */}
    </>
  );
};
