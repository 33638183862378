import React, { useContext, useEffect } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/title.hook';
import { AuthContext } from '../../context/AuthContext';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import { useCrypto } from '../../hooks/crypto.hook';
import { getCookie, removeCookie } from '../../common/cookieHandler';
import './UserPage.css';

export const UserPage = () => {
  useTitle("PhishFirewall | Sync");

  const { loading }= useHttp();
  const { user, showToastMessage } = useContext(AuthContext);
  const { decryptData } = useCrypto();
  const navigate = useNavigate();

  useEffect(() => {
    const cookieData = getCookie('sso_verified_test');
    const decodedCookieData = decodeURIComponent(cookieData);
    removeCookie('sso_verified');

    if (decodedCookieData) {
      removeCookie('sso_verified_test');
      const data = decryptData(JSON.parse(decodedCookieData));

      navigate('/back_office/user/details');
      showToastMessage(data.error, data.message, data.warning);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div className="user-main">
        <h2 className="user-main-title">
          User Database
        </h2>
        <p className="user-main-description">
          Please set up your user’s database using one of the options below
        </p>

        <ol>
          <li>
            <span>1. Set up integration with Azure ID Active Directory</span><br/>
            {(!user?.tenants?.syncAccess && user?.role !== 'owner') &&
              <CustomizedTooltipDisabled
                position={'top'}
                text1={'The Owner did not'}
                text2={'give you permission'}
                text3={'to set up a synchronization'}
                // text1={'This functionality'}
                // text2={'is not available yet'}
              />
            }
            <NavLink to="/back_office/user/users_database/ad/create" className="user-main-link">
              <button
                className='btn-ad'
                disabled={!user?.tenants?.syncAccess && user?.role !== 'owner'}
              >
                Set Up Azure Active Directory
              </button>
            </NavLink>
          </li>
          <li>
            <span>2. Set up integration with Google Business Directory</span><br/>
            {!user?.tenants?.syncAccess && user?.role !== 'owner' &&
              <CustomizedTooltipDisabled
                position={'top'}
                text1={'The Owner did not'}
                text2={'give you permission'}
                text3={'to set up a synchronization'}
                // text1={'This functionality'}
                // text2={'is not available yet'}
              />
            }
            <NavLink to="/back_office/user/users_database/google/create" className="user-main-link">
              <button
                className='btn-google'
                disabled={!user?.tenants?.syncAccess && user?.role !== 'owner'}
              >
                Google Business Directory
              </button>
            </NavLink>
          </li>
          <li>
            <span>3. Upload users’ list using CSV file</span><br/>
            {!user?.tenants?.syncAccess && user?.role !== 'owner' &&
              <CustomizedTooltipDisabled
                position={'top'}
                text1={'The Owner did not'}
                text2={'give you permission'}
                text3={'to set up a synchronization'}
                // text1={'This functionality'}
                // text2={'is not available yet'}
              />
            }
            <NavLink to="/back_office/user/users_database/csv/create" className="user-main-link">
              <button
                className='btn-csv'
                disabled={!user?.tenants?.syncAccess && user?.role !== 'owner'}
              >
                Upload CSV file
              </button>
            </NavLink>
          </li>
        </ol>

        <p className="user-main-description">
          Or review launch schedule for current tenants
        </p>
        <NavLink to="/back_office/user/users_database/all_tenants" className="user-main-link">
          <button
            className='btn-all-tenants'
            // disabled={!user?.tenants?.syncAccess && user?.role !== 'owner'}
          >
            All Tenants
          </button>
        </NavLink>
      </div>
      }
    </>
  )
}
