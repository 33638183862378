import React, { useState } from "react";
import Modal from 'react-modal';
import { customStyles } from "../../styles/customStyles";

const scrollToPlans = () => document.getElementById('plans')
  .scrollIntoView({block: "center", inline: "start", behavior: "smooth"});

export const Free = ({ formatExpiredDate, dateSoonExpired, dateExpired }) => {
  return (
    <>
      <h3 className="plans-name">FREE</h3>
      <p>This plan includes:</p>
      <ul className="plans-description">
        <li>Basic Security Awareness Training</li>
        <li>Basic Reporting</li>
        <li>User Synchronisation Tools</li>
      </ul>
      <p className="plans-last-item">This plan is <strong>FREE for the first 6 months.</strong></p>
      {!dateSoonExpired && !dateExpired &&
        <>
          <span className="plans-expire">Expire date: </span>
          <span className="plans-expire-date">{formatExpiredDate}</span>
        </>
      }
      {dateSoonExpired && !dateExpired &&
        <>
          <div className="plans-description-soon">
            Your FREE plan will be expired on {formatExpiredDate}.
          </div>
          <p className="plans-description-upgrade">Please <span onClick={scrollToPlans}>upgrade your subscription plan</span>. Choose on of the options below to proceed.</p>
        </>
      }
      {dateExpired &&
        <>
          <div className="plans-description-expired">
            Your FREE plan was expired on {formatExpiredDate}.
          </div>
          <p className="plans-description-upgrade">Please <span onClick={scrollToPlans}>upgrade your subscription plan</span>. Choose on of the options below to proceed.</p>
        </>
      }
    </>
  )
};

export const SmallBiz = ({
  user, formatExpiredDate, dateExpired, paused, allUsers, setPlanActive, cancelSubscription
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  
  return (
    <>
      <div>
        <span className="plans-name-subscripton">Subscription Plan</span>
        <h3 className="plans-name plans-name-pro">SMALL BIZ</h3>
        <div className="plans-name-subscripton-block">
          <span>Used seats/Payed seats</span><br/>
          <span>
            <strong>{allUsers}</strong>
            <span>/{user.stripePayment?.seats || '0'}</span>
          </span>
        </div>
        <div className="plans-name-subscripton-block">
          <span>Billing</span><br/>
          <span><strong>{user.stripePayment?.type}</strong></span>
        </div>
      
      {!dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <span>Expire date:</span><br/>
          <span><strong className="plans-expire-date">{formatExpiredDate}</strong></span><br/>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      {dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <div className="plans-description-expired plans-description-expired-pro">
            Your SMALL BIZ plan was expired on {formatExpiredDate}.
          </div>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-renew"
          >
            Renew your plan
          </button>
        </div>
      }
      {paused && !dateExpired &&
        <div className="plans-name-subscripton-block">
          <p className="plans-expire">Expire date: <span className="plans-expire-date">{formatExpiredDate}</span></p>
          <div className="plans-description-paused">
            Your SMALL BIZ plan was paused.<br/>
            <span>
              The subscription fee was not charged or the<br/>
              number of users has exceeded the maximum<br/>
              number of paid seats in your plan.
            </span>
          </div>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      </div>
      
      <div>
        <p className="plans-description-includes">This plan includes:</p>
        <ul className="plans-description">
          <li>Basic Security Awareness Training</li>
          <li>Basic Reporting</li>
          <li>User Synchronisation Tools</li>
          <li>Autonomous Phishing</li>
        </ul>
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal">
          <button onClick={closeModal} className="modal-close"></button>
          <h4 className="modal-cancel-title">Confirm cancellation</h4>
          <div className="modal-cancel-subscription">
            <p>
              Are you sure you want to cancel your subscription plan?
            </p>
            <p>
              If you proceed, your subscription will be cancelled at the end of the current billing cycle.
              You can still use your subscription plan features of the app after cancellation.
            </p>
            <p>
              If you have any concerns or feedback regarding your subscription,
              please feel free to <a href='mailto:sales@phishfirewall.com'>contact our support team</a> for assistance.
            </p>
          </div>
          <div className="modal-cancel-button">
            <button
              onClick={() => {
                closeModal();
              }}
            >
              Stay SMALL BIZ
            </button>
            <button
              onClick={() => {
                cancelSubscription();
                closeModal();
              }}
            >
              Yes, cancel subscription
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
};

export const Pro = ({
  user, formatExpiredDate, dateExpired, paused, allUsers, setPlanActive, cancelSubscription
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  
  return (
    <>
      <div>
        <span className="plans-name-subscripton">Subscription Plan</span>
        <h3 className="plans-name plans-name-pro">PROFESSIONAL</h3>
        <div className="plans-name-subscripton-block">
          <span>Used seats/Payed seats</span><br/>
          <span>
            <strong>{allUsers}</strong>
            <span>/{user.stripePayment?.seats}</span>
          </span>
        </div>
        <div className="plans-name-subscripton-block">
          <span>Billing</span><br/>
          <span><strong>{user.stripePayment?.type || '0'}</strong></span>
        </div>
      
      {!dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <span>Expire date:</span><br/>
          <span><strong className="plans-expire-date">{formatExpiredDate}</strong></span><br/>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      {dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <div className="plans-description-expired plans-description-expired-pro">
            Your PROFESSIONAL plan was expired on {formatExpiredDate}.
          </div>
          <button onClick={scrollToPlans} className="plans-button-renew">Renew your plan</button>
        </div>
      }
      {paused && !dateExpired &&
        <div className="plans-name-subscripton-block">
          <p className="plans-expire">Expire date: <span className="plans-expire-date">{formatExpiredDate}</span></p>
          <div className="plans-description-paused">
            Your PROFESSIONAL plan was paused.<br/>
            <span>
              The subscription fee was not charged or the<br/>
              number of users has exceeded the maximum<br/>
              number of paid seats in your plan.
            </span>
          </div>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      </div>

      <div>
        <p className="plans-description-includes">This plan includes:</p>
        <ul className="plans-description">
          <li>User Synchronisation Tools</li>
          <li>Security Awareness Training</li>
          <li>Autonomous Phishing</li>
          <li>Advanced Analytics</li>
          <li>Phish Reporting Tool</li>
        </ul>
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal">
          <button onClick={closeModal} className="modal-close"></button>
          <h4 className="modal-cancel-title">Confirm cancellation</h4>
          <div className="modal-cancel-subscription">
            <p>
              Are you sure you want to cancel your subscription plan?
            </p>
            <p>
              If you proceed, your subscription will be cancelled at the end of the current billing cycle.
              You can still use your subscription plan features of the app after cancellation.
            </p>
            <p>
              If you have any concerns or feedback regarding your subscription,
              please feel free to <a href='mailto:sales@phishfirewall.com'>contact our support team</a> for assistance.
            </p>
          </div>
          <div className="modal-cancel-button">
            <button
              onClick={() => {
                closeModal();
              }}
            >
              Stay PROFESSIONAL
            </button>
            <button
              onClick={() => {
                cancelSubscription();
                closeModal();
              }}
            >
              Yes, cancel subscription
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
};

export const Enterprise = ({
  user, formatExpiredDate, dateExpired, paused, allUsers, setPlanActive, cancelSubscription
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  return (
    <>
      <div>
        <span className="plans-name-subscripton">Subscription Plan</span>
        <h3 className="plans-name plans-name-pro">ENTERPRISE</h3>
        <div className="plans-name-subscripton-block">
          <span>Used seats/Payed seats</span><br/>
          <span>
            <strong>{allUsers}</strong>
            <span>/{user.stripePayment?.seats || '0'}</span>
          </span>
        </div>
        <div className="plans-name-subscripton-block">
          <span>Billing</span><br/>
          <span><strong>{user.stripePayment?.type}</strong></span>
        </div>
      
      {!dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <span>Expire date:</span><br/>
          <span><strong className="plans-expire-date">{formatExpiredDate}</strong></span><br/>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      {dateExpired && !paused &&
        <div className="plans-name-subscripton-block">
          <div className="plans-description-expired plans-description-expired-pro">
            Your ENTERPRISE plan was expired on {formatExpiredDate}.
          </div>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Renew your plan
          </button>
        </div>
      }
      {paused && !dateExpired &&
        <div className="plans-name-subscripton-block">
          <p className="plans-expire">Expire date: <span className="plans-expire-date">{formatExpiredDate}</span></p>
          <div className="plans-description-paused">
            Your ENTERPRISE plan was paused.<br/>
            <span>
              The subscription fee was not charged or the<br/>
              number of users has exceeded the maximum<br/>
              number of paid seats in your plan.
            </span>
          </div>
          <button
            onClick={() => {
              setPlanActive(false);
              setTimeout(() => {
                scrollToPlans();
              }, 100)
            }}
            className="plans-button-upgrade"
          >
            Upgrade plan
          </button>
          <button onClick={openModal} className="plans-button-cancel">Cancel subscription</button>
        </div>
      }
      </div>
      <div>
        <p className="plans-description-includes">This plan includes:</p>
        <ul className="plans-description">
          <li>User Synchronisation Tools</li>
          <li>Security Awareness Training</li>
          <li>Phish Reporting Tool</li>
          <li>Advanced Analytics</li>
          <li>Advanced Language Controls
            <ul>
              <li>Spanish</li>
              <li>French</li>
              <li>German</li>
              <li>+7 Other</li>
            </ul>
          </li>
        </ul>
      </div>
      
      <div>
        <ul className="plans-description plans-description-second">
          <li>GRC</li>
          <li>Autonomous Phishing</li>
          <li>Role Based Training
            <ul>
              <li>IT Security Treats</li>
              <li>C-Suit Cybersecurity</li>
              <li>OWASP Top 10 Developer</li> 
              <li>HR</li>
              <li>Finance</li>
              <li>InfoSec Defenders</li>
            </ul>
          </li>
        </ul>
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal">
          <button onClick={closeModal} className="modal-close"></button>
          <h4 className="modal-cancel-title">Confirm cancellation</h4>
          <div className="modal-cancel-subscription">
            <p>
              Are you sure you want to cancel your subscription plan?
            </p>
            <p>
              If you proceed, your subscription will be cancelled at the end of the current billing cycle.
              You can still use your subscription plan features of the app after cancellation.
            </p>
            <p>
              If you have any concerns or feedback regarding your subscription,
              please feel free to <a href='mailto:sales@phishfirewall.com'>contact our support team</a> for assistance.
            </p>
          </div>
          <div className="modal-cancel-button">
            <button
              onClick={() => {
                closeModal();
              }}
            >
              Stay ENTERPRISE
            </button>
            <button
              onClick={() => {
                cancelSubscription();
                closeModal();
              }}
            >
              Yes, cancel subscription
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
};
