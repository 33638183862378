import CryptoJS from "crypto-js";

export const useCrypto = () => {
  const encryptData = (receivedData) => {
    const secretPass = process.env.REACT_APP_SECRET;
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(receivedData),
      secretPass
    ).toString();
  
    return data;
  };

  const decryptData = (receivedData) => {
    const secretPass = process.env.REACT_APP_SECRET;
    
    const bytes = CryptoJS.AES.decrypt(receivedData, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
    return data;
  };

  return { encryptData, decryptData }
}
