// export const calculatePrice = (
//   startPrice, numberOfUsers, transientNumber, firstTierDiscount, secondTierDiscount
// ) => {
//   let price;
  
//   if (numberOfUsers <= transientNumber) {
//     price = (firstTierDiscount ** numberOfUsers) * startPrice;
//   } else {
//     const initialPrice = (firstTierDiscount ** (transientNumber - 1)) * startPrice;
//     price = (secondTierDiscount ** (numberOfUsers - transientNumber)) * initialPrice;
//   }

//   return Math.ceil(price * 100) / 100;
// };
function calculateSalesPrice(quantity, basePrice) {
  let price = 0;
  const tiers = [
      {qty: 50, discount: 0.00},
      {qty: 50, discount: 0.10},
      {qty: 400, discount: 0.25},
      {qty: 500, discount: 0.30},
      {qty: 1000, discount: 0.35},
      {qty: 1000, discount: 0.40},
      {qty: 2000, discount: 0.45},
      {qty: 1000, discount: 0.50},
      {qty: 1000, discount: 0.55},
      {qty: 1000, discount: 0.60},
      {qty: Infinity, discount: 0.65}  // For quantities above 8000
  ];

  for (let i = 0; i < tiers.length; i++) {
      if (quantity <= 0) break;
      
      const tierQty = Math.min(quantity, tiers[i].qty);
      price += tierQty * basePrice * (1 - tiers[i].discount);
      
      quantity -= tierQty;
  }

  return price;
}
export const calculatePrice = (
  startPrice, numberOfUsers
) => {
  let price = calculateSalesPrice(numberOfUsers, startPrice);

  return price;
};