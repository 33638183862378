import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { useTitle } from '../hooks/title.hook';
import { useCrypto } from '../hooks/crypto.hook';
import eye from '.././images/icons/Eye.svg';
import closeEye from '.././images/icons/Eye Slash.svg';
import cn from 'classnames';
import 'react-phone-input-2/lib/style.css';

export const RegisterPageByInvation = () => {
  useTitle("Welcome to PhishFirewall - Get Started Today FREE");

  const auth = useContext(AuthContext);
  const { token } = useParams();
  const navigate = useNavigate();
  const { request, loading } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    tel: '',
    password: '',
    confirmPassword: '',
    token
  });
 
  const [inputError, setError] = useState({
    firstName: '',
    lastName: '',
    tel: '',
    password: '',
    confirmPassword: ''
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const [card, setCard] = useState(1);
  const [maxTelLength, setMaxTelLength] = useState(17);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
 
  const onInputChange = e => {
    const { name, value, country, formattedValue } = e.target;
    
    setIsPhoneValid(country?.format.length === formattedValue?.length
      && (value.startsWith(country?.dialCode) || country?.dialCode.startsWith(value) || country?.dialCode !== value));
    
      setInput(prev => ({
      ...prev,
      [name]: value.trimStart()
    }));
    validateInput(e);
  }
 
  const validateInput = e => {
    let { name, value, country, formattedValue } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
      
      switch (name) {
      case "firstName":
        if (!value) {
          stateObj[name] = "Please enter your First Name";
        } else if (!value.match(/^[\w- .]{0,100}$/)) {
          stateObj[name] = "This First Name is not valid";
        }
        break;
      case "lastName":
        if (!value) {
          stateObj[name] = "Please enter your Last Name";
        } else if (!value.match(/^[\w- .]{0,100}$/)) {
          stateObj[name] = "This Last Name is not valid";
        }
        break;
      case "tel":
        if (!value) {
          stateObj[name] = "Please enter your phone number";
        }
        else if (country?.format.length !== formattedValue?.length
          && (!value.startsWith(country?.dialCode) || !country?.dialCode?.startsWith(value) || country.dialCode === value)
        ) {
          stateObj[name] = "Enter a valid phone number";
        }
        break;
      case "password":
        if (!value) {
          stateObj[name] = "Please enter Password";
        } else if (input.confirmPassword && value !== input.confirmPassword) {
          stateObj["confirmPassword"] = "Password and Confirm Password does not match";
        } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{12,50}$/)) {
          stateObj[name] = "The password must contain lowercase letters, uppercase letters, numbers and be at least 12 characters long";
        } else {
          stateObj["confirmPassword"] = input.confirmPassword ? "" : inputError.confirmPassword;
        }
        break;

      case "confirmPassword":
        if (!value) {
          stateObj[name] = "Please enter Confirm Password";
        } else if (input.password && value !== input.password) {
          stateObj[name] = "Password and Confirm Password does not match";
        }
        break;

      default:
        break;
      }
 
      return stateObj;
    });
  };

  const validateTelInput = (value, isPhoneValid) => {
    setError(prev => {
      const stateObj = { ...prev, tel: "" };
      if (!value) {
        stateObj.tel = "Please enter your phone number";
      }
      else if (!isPhoneValid) {
        stateObj.tel = "Enter a valid phone number";
      }

      return stateObj;
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
  };

  const registerHandler = async (e) => {
    try {
      const data = encryptData(input);
      const response = await request('/back_office/api/auth/register_by_invation', 'POST', { data });
      auth.showToastMessage(response.error);

      if (response.message) {
        const res = await request('/back_office/api/auth/login', 'POST', { data: response.data });
        const decryptRes = decryptData(res);

        if (decryptRes.token) {
          auth.login(decryptRes.token, decryptRes.userId);
        } else if (decryptRes.companyName) {
          navigate(`/back_office/login/${decryptRes.companyName}`);
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isPhoneValid === undefined) {
      setIsPhoneValid(false);
    }
  }, [isPhoneValid]);

  return (
    <form className={cn('form', { 'form-loading': loading})} onSubmit={handleSubmit}>
      <div className="title">
        <span className="card-title">Welcome to Phishfirewall!</span><br/>
        <span className="card-subtitle">
          Please fill out the following information to complete your registration.
        </span>
      </div>

      {card === 1 &&
        <>
          <div className='back-register'>
            <h3>Set up your account details</h3>
          </div>
          <div className="card-content">
            <div className="input-field">
              <label
                htmlFor="firstName"
                className="input-label input-label-required"
              >
                First name
              </label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                placeholder='Your First name...'
                className={cn("input", "validate", {"err-input": inputError.firstName})}
                value={input.firstName}
                onChange={onInputChange}
                onBlur={validateInput}></input>
              {inputError.firstName && <span className='err'>{inputError.firstName}</span>}
            </div>

            <div className="input-field">
              <label
                htmlFor="lastName"
                className="input-label input-label-required"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                placeholder='Your Last name...'
                className={cn("input", "validate", {"err-input": inputError.lastName})}
                value={input.lastName}
                onChange={onInputChange}
                onBlur={validateInput}></input>
              {inputError.lastName && <span className='err'>{inputError.lastName}</span>}
            </div>

            <div className="input-field">
              <label
                htmlFor="tel"
                className="input-label input-label-required"
              >
                Phone number
              </label>
              <PhoneInput
                inputProps={{
                  maxLength: maxTelLength
                }}
                enableLongNumbers={true}
                country={'us'}
                inputClass={cn("input", "validate", {"err-input": inputError.tel})}
                dropdownClass={"input-dropdown"}
                id="tel"
                type="tel"
                name="tel"
                value={input.tel}
                required={true}
                onChange={(value, country, event, formattedValue) => {
                  setMaxTelLength(country.format.length)
                  const e = {
                    target: {
                      name: "tel",
                      value,
                      country,
                      event,
                      formattedValue
                    }
                  }
                  onInputChange(e)
                }}
                onBlur={(value) => {
                  validateTelInput(value, isPhoneValid)
                }}
              />
              {inputError.tel && <span className='err'>{inputError.tel}</span>}
            </div>
          </div>

          <div className="card-action">
            <button
              className='next-register-button'
              onClick={() => setCard(2)}
              disabled={!input.firstName || !input.lastName || !input.tel
                || inputError.firstName || inputError.lastName || inputError.tel}
              type="button"
            >
              Next
            </button>
          </div>
        </>
      }

      {card === 2 &&
        <>
          <div className='back-register'>
            <button
              className={cn('back-register-button', {'back-register-button-loading': loading})}
              onClick={() => setCard(1)}
              type="button"
              disabled={loading}
            >
              Back
            </button>
          </div>
          <div className='back-register'>
            <h3>Set up your password</h3>
          </div>
          <div>
            <div className={cn('input-field', {'input-field-loading': loading})}>
              <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
              <label
                htmlFor="password"
                className={cn("input-label input-label-required", {'input-label-loading': loading})}
              >
                Password
              </label>
              <input
                id="password"
                type={passwordShown ? "text" : "password"}
                name="password"
                placeholder='Create your password...'
                className={cn("input", "validate", {"err-input": inputError.password, "input-loading": loading})}
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
                disabled={loading}></input>
              {inputError.password && <span className='err'>{inputError.password}</span>}
            </div>

            <div className="input-field">
              <img src={!passwordShown ? eye : closeEye} alt='Eye' className='eye' onClick={togglePassword}></img>
              <label
                htmlFor="confirmPassword"
                className={cn("input-label input-label-required", {'input-label-loading': loading})}
              >
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type={passwordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder='Confirm your password...'
                className={cn("input", "validate", {"err-input": inputError.confirmPassword, "input-loading": loading})}
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}
                disabled={loading}></input>
              {inputError.confirmPassword && <span className='err'>{inputError.confirmPassword}</span>}
            </div>

            <div className="checkbox-action"></div>
          </div>

          <div className="card-action">
            <button
              className='last-register-button'
              onClick={registerHandler}
              disabled={
                !input.password || !input.confirmPassword ||
                inputError.password || inputError.confirmPassword || loading
              }
              type="submit"
            >
              Start Training Now
            </button>
          </div>
        </>
      }
    </form>
  );
}
