import React, { useContext, useEffect, useState, useCallback } from "react";
import Modal from 'react-modal';
import moment from "moment";
import cn from 'classnames'
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import { Free, SmallBiz, Pro, Enterprise } from './PaymentPlans';
import { Loader } from "../../components/Loader/Loader";
import { PlansTable } from "./PlansTable";
import { CustomizedTooltipDisabledMonthly, CustomizedTooltipSeats } from "../../components/Tooltips/Tooltip";
import { NavLink } from "react-router-dom";
import { calculatePrice } from "../../common/calculatePrice";
import { useTitle } from "../../hooks/title.hook";
import { maxUsers } from "../../config/config";
import { customStyles } from "../../styles/customStyles";
import './MembershipPlan.css';

export const Plans = () => {
  useTitle("PhishFirewall | Plans & Pricing");
  const testCompanyId = process.env.REACT_APP_TEST_COMPANY_ID; // for testing payments
  const [testPayment, setTestPayment] = useState(false); // for testing payments

  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { user, fetchUser, showToastMessage, planPaused, dateExpired } = useContext(AuthContext);
  const context = useContext(AuthContext);
  const allProspects = context?.allUsers?.length;
  const { decryptData } = useCrypto();

  const route = "/back_office/user/buy_plan";

  const paymentPlan = user?.paymentPlanName;
  const formatExpiredDate = moment(user?.paymentExpires).format('MMM DD, YYYY');

  const [planActive, setPlanActive] = useState(false);
  const [renewPlan, setRenewPlan] = useState(false);
  const [subscription, setSubscription] = useState('annual');
  const [smallBizPrice, setSmallBizPrice] = useState({});
  const [proPrice, setProPrice] = useState({});
  const [enterprisePrice, setEnterprisePrice] = useState({});
  const [dateSoonExpired, setDateSoonExpired] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(1);
  const [allPrices, setAllPrices] = useState(null);
  const [prices, setPrices] = useState(null);
  const [allUsers, setAllUsers] = useState(0);
  const [seats, setSeats] = useState(0);
  const [minSeats, setMinSeats] = useState(0);
  const [maxSeats, setMaxSeats] = useState(0);
  const [maxAvailableSeats, setMaxAvailableSeats] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [requestPlan, setRequestPlan] = useState({});
  
  const openModal = (data) => {
    setIsOpen(true);
    setRequestPlan(data)
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const monthlySubscriptionHandle = () => {
    setSubscription('monthly');
    setSmallBizPrice({
      type: 'monthly',
      price: prices.smallBizMonthlyPrice
    });
    setProPrice({
      type: 'monthly',
      price: prices.proffesionalMonthlyPrice
    });
    setEnterprisePrice({
      type: 'monthly',
      price: prices.enterpriseMonthlyPrice
    });
  }
  const annuallSubscriptionHandle = () => {
    setSubscription('annual');
    setSmallBizPrice({
      type: 'annual',
      price: prices.smallBizAnnualPrice
    });
    setProPrice({
      type: 'annual',
      price: prices.proffesionalAnnualPrice
    });
    setEnterprisePrice({
      type: 'annual',
      price: prices.enterpriseAnnualPrice
    });
  }

  useEffect(() => {
    if (subscription === 'annual') {
      setSmallBizPrice({
        type: 'annual',
        price: prices?.smallBizAnnualPrice
      });
      setProPrice({
        type: 'annual',
        price: prices?.proffesionalAnnualPrice
      });
      setEnterprisePrice({
        type: 'annual',
        price: prices?.enterpriseAnnualPrice
      });
    }
    
    if (subscription === 'monthly') {
      setSmallBizPrice({
        type: 'monthly',
        price: prices?.smallBizMonthlyPrice
      });
      setProPrice({
        type: 'monthly',
        price: prices?.proffesionalMonthlyPrice
      });
      setEnterprisePrice({
        type: 'monthly',
        price: prices?.enterpriseMonthlyPrice
      });
    }
    
  }, [prices, subscription])

  useEffect(() => {}, [smallBizPrice, proPrice, enterprisePrice])
  
  useEffect(() => {
    const soonExpired = new Date(moment(user?.paymentExpires).subtract(7, 'days'));
    const today = new Date();
    
    if (today > soonExpired) {
      setDateSoonExpired(true);
    } else {
      setDateSoonExpired(false);
    }
    setCurrentPlanId(user?.paymentPlanId)
  }, [user, loading])

  const RadioButton = ({ label, value, onChange, checked }) => {
    return (
      <div className={cn("radio-button", {
        "radio-button-disabled": !checked
          && user?.stripePayment?.type === "Annual"
          && user?.stripePayment?.active
      })}>
        <label className="form-controll">
          <input
            disabled={user?.stripePayment?.type === "Annual" && user?.stripePayment?.active
          }
            type="radio"
            value={value}
            onChange={onChange}
            checked={checked}
          />
          <span className="radio-ckecked">{label}</span>
        </label>
      </div>
    );
  };

  const sendRequestEmail = async (requestPlan) => {
    try {
      const req = await request('/back_office/api/payment/send_request_email', "POST", { requestPlan }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(req.error, req.message)
    } catch {}
  }

  const getAllUsersAndPrices = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/user/membership_prices/all_users_and_prices', 'GET', null, {
          Authorization: `Bearer ${token}`
        });
        
        fetchUser();
        const decryptedData = decryptData(fetched);
        
        setAllPrices({
          smallBizPrices: decryptedData.smallBizPrices,
          proffesionalPrices: decryptedData.proffesionalPrices,
          enterprisePrices: decryptedData.enterprisePrices,
        })
        setAllUsers(decryptedData.countOfAllUsers > allProspects ? decryptedData.countOfAllUsers : allProspects);
        setSeats(decryptedData.countOfAllUsers >= decryptedData?.minSeats
          ? decryptedData.countOfAllUsers
          : 50);
        setMinSeats(decryptedData.countOfAllUsers >= decryptedData?.minSeats
          ? decryptedData.countOfAllUsers
          : decryptedData?.minSeats);
        setMaxSeats(decryptedData?.maxSeats);
        setMaxAvailableSeats(maxUsers);
  
        return decryptedData;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    if (allPrices && seats) {
      const formatPrice = (price) => price.toFixed(2)
      const smallBizActual = allPrices?.smallBizPrices?.filter(price => price.minUsers <= seats && price.maxUsers >= seats);
      // const smallBizAnnualPrice = seats * smallBizActual[0]?.annualPrice;
      // const smallBizMonthlyPrice = seats * smallBizActual[0]?.monthlyPrice;
      const smallBizAnnualPrice = 1000;
      const smallBizMonthlyPrice = 100;

      const proffesionalActual = allPrices?.proffesionalPrices?.filter(price => price.minUsers <= seats && price.maxUsers >= seats);
      const proffesionalAnnualPrice= +(calculatePrice(
        proffesionalActual[0]?.startPrice, seats) * 12).toFixed(2);
      const proffesionalAnnualSinglePrice = formatPrice(proffesionalAnnualPrice / seats);
      
      const proffesionalMonthlyPrice  = +(calculatePrice(
        proffesionalActual[0]?.startPrice, seats) * 1.2).toFixed(2);
      const proffesionalMonthlySinglePrice = formatPrice(proffesionalMonthlyPrice / seats);
      
      const enterpriseActual = allPrices?.enterprisePrices?.filter(price => price.minUsers <= seats && price.maxUsers >= seats);
      const enterpriseAnnualPrice = +(calculatePrice(
        enterpriseActual[0]?.startPrice, seats) * 12).toFixed(2);
      const enterpriseAnnualSinglePrice = formatPrice(enterpriseAnnualPrice / seats);
      const enterpriseMonthlyPrice = +(calculatePrice(
        enterpriseActual[0]?.startPrice, seats) * 1.2).toFixed(2);
      const enterpriseMonthlySinglePrice= formatPrice(enterpriseMonthlyPrice / seats);

      setPrices({
        smallBizAnnualPrice: smallBizAnnualPrice ? +smallBizAnnualPrice.toFixed(2) : null,
        smallBizMonthlyPrice: smallBizMonthlyPrice ? +smallBizMonthlyPrice.toFixed(2) : null,
        smallBizAnnualSinglePrice: (+smallBizAnnualPrice / seats).toFixed(2),
        smallBizMonthlySinglePrice: (+smallBizMonthlyPrice / seats).toFixed(2),
        proffesionalAnnualPrice: proffesionalAnnualPrice ? +proffesionalAnnualPrice.toFixed(2) : null,
        proffesionalMonthlyPrice: proffesionalMonthlyPrice ? +proffesionalMonthlyPrice.toFixed(2) : null,
        proffesionalAnnualSinglePrice,
        proffesionalMonthlySinglePrice,
        enterpriseAnnualPrice: enterpriseAnnualPrice ? +enterpriseAnnualPrice.toFixed(2) : null,
        enterpriseMonthlyPrice: enterpriseMonthlyPrice ? +enterpriseMonthlyPrice.toFixed(2) : null,
        enterpriseAnnualSinglePrice,
        enterpriseMonthlySinglePrice,
      })

      // For testing payments
      if (testCompanyId === user.testCompanyId) {
        // console.log('Prices for testing payments for test Company')
        setPrices({
          smallBizAnnualPrice: smallBizAnnualPrice ? Math.ceil((smallBizAnnualPrice / 1000) * 100) / 100 : null,
          smallBizMonthlyPrice: smallBizMonthlyPrice ? Math.ceil((smallBizMonthlyPrice / 1000) * 100) / 100 : null,
          smallBizAnnualSinglePrice: Math.ceil((smallBizActual[0]?.annualPrice / 1000) * 100) / 100,
          smallBizMonthlySinglePrice: Math.ceil((smallBizActual[0]?.monthlyPrice / 1000) * 100) / 100,
          proffesionalAnnualPrice: proffesionalAnnualPrice ? Math.ceil((proffesionalAnnualPrice / 1000) * 100) / 100 : null,
          proffesionalMonthlyPrice: proffesionalMonthlyPrice ? Math.ceil((proffesionalMonthlyPrice / 1000) * 100) / 100 : null,
          proffesionalAnnualSinglePrice: Math.ceil((proffesionalAnnualSinglePrice / 1000) * 100) / 100,
          proffesionalMonthlySinglePrice: Math.ceil((proffesionalMonthlySinglePrice / 1000) * 100) / 100,
          enterpriseAnnualPrice: enterpriseAnnualPrice ? Math.ceil((enterpriseAnnualPrice / 1000) * 100) / 100 : null,
          enterpriseMonthlyPrice: enterpriseMonthlyPrice ? Math.ceil((enterpriseMonthlyPrice / 1000) * 100) / 100 : null,
          enterpriseAnnualSinglePrice: Math.ceil((enterpriseAnnualSinglePrice / 1000) * 100) / 100,
          enterpriseMonthlySinglePrice: Math.ceil((enterpriseMonthlySinglePrice / 1000) * 100) / 100,
        })

        setTestPayment(true);
      }

      // console.log('-----------------------')
      // console.log('PRPFFESIONAL monthly price', prices?.proffesionalMonthlySinglePrice)
      // console.log('PRPFFESIONAL annual price', prices?.proffesionalAnnualSinglePrice)
      // console.log('***********************')
      // console.log('ENTERPRISE monthly price', prices?.enterpriseMonthlySinglePrice)
      // console.log('ENTERPRISE annual price', prices?.enterpriseAnnualSinglePrice)
      // console.log('-----------------------')
      // console.log('                       ')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPrices, seats])
  
  useEffect(() => {
    getAllUsersAndPrices();
    setPlanActive(user?.stripePayment?.active && !dateExpired && !planPaused);
    setRenewPlan(user?.stripePayment?.active);
  }, [dateExpired, getAllUsersAndPrices, planPaused, user?.stripePayment?.active]);

  useEffect(() => {
    if (user?.stripePayment?.active && user?.stripePayment?.type === 'Annual') {
      setSubscription('annual')
    }
  }, [user?.stripePayment?.active, user?.stripePayment?.type]);

  const smallBizMinUsers = allPrices?.smallBizPrices.at(0).minUsers;
  const smallBizMaxUsers = allPrices?.smallBizPrices.at(-1).maxUsers;
  const proffesionalMinUsers = allPrices?.proffesionalPrices.at(0).minUsers;
  const proffesionalMaxUsers = allPrices?.proffesionalPrices.at(-1).maxUsers;
  const enterpriseMinUsers = allPrices?.enterprisePrices.at(0).minUsers;
  const enterpriseMaxUsers = allPrices?.enterprisePrices.at(-1).maxUsers;

  const cancelSubscription = async () => {
    try {
      const res = await request(
        '/back_office/api/payment/cancel_subscription', "POST", { subscription: user.stripePayment.subscription }, {
        Authorization: `Bearer ${token}`
      })
  
      showToastMessage(res.error, res.message, res.warning)
    } catch (error) {
      showToastMessage(
        'We encountered an error. Please try again or if this problem persists contact support@phishfirewall.com for additional assistance'
      );
      console.error(error);
    }
  };

  let currentPlan;
  switch (paymentPlan) {
    case 'free':
      currentPlan = <Free
                      formatExpiredDate={formatExpiredDate}
                      dateSoonExpired={dateSoonExpired}
                      dateExpired={dateExpired}
                    />
      break;
    case 'small-biz':
      currentPlan = <SmallBiz
                      user={user}
                      formatExpiredDate={formatExpiredDate}
                      dateExpired={dateExpired}
                      paused={planPaused}
                      allUsers={allUsers}
                      seats={seats}
                      setPlanActive={setPlanActive}
                      cancelSubscription={cancelSubscription}
                    />
      break;
    case 'pro':
      currentPlan = <Pro
                      user={user}
                      formatExpiredDate={formatExpiredDate}
                      dateExpired={dateExpired}
                      paused={planPaused}
                      allUsers={allUsers}
                      seats={seats}
                      setPlanActive={setPlanActive}
                      cancelSubscription={cancelSubscription}
                    />
      break;
    case 'enterprise':
      currentPlan = <Enterprise
                      user={user}
                      formatExpiredDate={formatExpiredDate}
                      dateExpired={dateExpired}
                      paused={planPaused}
                      allUsers={allUsers}
                      seats={seats}
                      setPlanActive={setPlanActive}
                      cancelSubscription={cancelSubscription}
                    />
      break;
    default:
      currentPlan = <Free
                      formatExpiredDate={formatExpiredDate}
                      dateSoonExpired={dateSoonExpired}
                      dateExpired={dateExpired}
                    />
      break;
  }

  return (
    <>
      <div id="plans" className='plans-container'>
        <div>
          <div className="plans-current">
            <p className="plans-current-title">Your current plan</p>
          </div>
          <div className={cn("plans-card plans-card-current", {"plans-card-current-free": paymentPlan === 'free'})}>
            {paymentPlan
              ? <>{currentPlan}</>
              : <Loader />
            }
          </div>
        </div>
        
        {!planActive &&
          <>
            <h3>Available plans</h3>
            <div className="seats-card">
              <div className="seats-input">
                <span>How many seats you want to have in your paid plan?</span>
                <form>
                  <input
                    required
                    type="number"
                    min={minSeats}
                    max={maxSeats}
                    step={1}
                    value={seats}
                    onChange={(e) => {
                      setSeats(e.target.value)
                    }}
                  />
                </form>
                <CustomizedTooltipSeats
                  text1={'Your current number of synced users is '}
                  text2={`${allUsers}`}
                  text3={'. You can pay for current number or to set the number of seats that you expect to have during the paid period.'}
                />
              </div>
              <div className="seats-radio">
                <span>How often would you like to be billed?</span>
                {user?.stripePayment?.type === "Annual" && user?.stripePayment?.active &&
                  <CustomizedTooltipDisabledMonthly
                  position={'bottom'}
                  text={`At the moment, you have an annual billing plan. Once your current plan expires,
                    you have the option to switch to a monthly billing plan if you prefer.`}
                  />
                }
                <div className="plans-pricing-radio">
                  <RadioButton
                    label="Annually"
                    value={subscription}
                    onChange={annuallSubscriptionHandle}
                    checked={subscription === 'annual'}
                  />
                                    <RadioButton
                    label="Monthly"
                    value={subscription}
                    onChange={monthlySubscriptionHandle}
                    checked={subscription === 'monthly'}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="plans-cards">
              {seats <= smallBizMaxUsers && <div className={cn("plans-card", {
                "availible-plan": !!smallBizPrice.price && seats >= allUsers
                  && ((smallBizPrice.price > user?.stripePayment?.paidAmount
                    && user?.stripePayment?.active)
                      || !user?.stripePayment?.active)
              })}>
                <h4 className="plans-available-name">Small biz</h4>
                <div className="plans-subcard">
                  <div className="plans-pricing">
                    {!!smallBizPrice.price && seats >= allUsers
                      && ((smallBizPrice.price > user?.stripePayment?.paidAmount
                        && user?.stripePayment?.active)
                          || !user?.stripePayment?.active)
                      ?
                        <div>
                          <div className="plans-pricing-price">
                            {currentPlanId === 2 && !dateExpired && user?.stripePayment?.active &&
                              <div className="plans-pricing-current">
                                This is your<br/> current plan
                              </div>
                            }
                            {subscription === 'monthly' &&
                              <>
                                <span>${prices.smallBizMonthlyPrice}*</span>
                                <span>per month</span>
                              </>
                            }
                            {subscription === 'annual' &&
                              <>
                                <span>${prices.smallBizAnnualPrice}*</span>
                                <span>per year</span>
                              </>
                            }
                          </div>
                          <p>*VAT and local taxes may apply</p>
                        </div>
                      : <div className="plans-pricing-blocked">
                        {user?.stripePayment?.active
                          ? <span>Not available</span>
                          : <>
                              {seats > smallBizMaxUsers &&
                                <span>This plan is not available for you. Your number of seats is more than {smallBizMaxUsers}.</span>
                              }
                              {/* {seats < smallBizMinUsers && !(seats < allUsers) &&
                                <span>This plan is not available for you. Your number of seats is less than {smallBizMinUsers}.</span>
                              } */}
                              {seats < allUsers &&
                                <span>Not available. The number of seats must be equal to or greater than the number of synced users.</span>
                              }
                            </>
                        }
                        </div>
                    }
                  </div>
                  
                  {seats <= smallBizMaxUsers
                    ? <NavLink
                        to={route}
                        state={{
                          plan: 'Small Biz',
                          priceDetails: smallBizPrice,
                          company: user.company,
                          annualPrice: prices?.smallBizAnnualSinglePrice,
                          monthlyPrice: prices?.smallBizMonthlySinglePrice,
                          seats,
                          renewPlan,
                          testPayment // for testing payments
                        }}
                      >
                        <button
                          type="button"
                          disabled={seats < smallBizMinUsers || seats > smallBizMaxUsers || seats < allUsers
                            || (smallBizPrice.price <= user?.stripePayment?.paidAmount && user?.stripePayment?.active)
                          }
                        >
                          Go to Small Biz
                        </button>
                      </NavLink>
                    : <button
                        type="button"
                        onClick={() => openModal({
                          email: user.email,
                          seats,
                          plan: 'Small Biz',
                          type: smallBizPrice.type
                        })}
                      >
                        Contact Sales
                      </button>
                  }
                  
                  <p className="plans-subcard-users">Up to {allPrices?.smallBizPrices.at(-1).maxUsers} users</p>
                  <p className="plans-subcard-includes">This plan includes:</p>
                  <ul className="plans-description">
                    <li>Basic Security Awareness Training</li>
                    <li>Basic Reporting</li>
                    <li>User Synchronisation Tools</li>
                    <li>Autonomous Phishing</li>
                  </ul>
                </div>
              </div>}

              {seats > smallBizMaxUsers && <>
              <div className={cn("plans-card", {
                "availible-plan":
                  !!proPrice.price && seats >= allUsers
                    && ((proPrice.price > user?.stripePayment?.paidAmount
                      && user?.stripePayment?.active)
                        || !user?.stripePayment?.active)
              })}>
                <h4 className="plans-available-name plans-available-name--2">Professional</h4>
                <div className="plans-subcard">
                  <div className="plans-pricing">
                    {!!proPrice.price && seats >= allUsers
                      && ((proPrice.price > user?.stripePayment?.paidAmount
                      && user?.stripePayment?.active)
                      || !user?.stripePayment?.active)
                        ?
                          <div>
                            <div className="plans-pricing-price">
                              {currentPlanId === 3 && !dateExpired && user?.stripePayment?.active &&
                                <div className="plans-pricing-current">
                                  This is your<br/> current plan
                                </div>
                              }
                              {subscription === 'monthly' &&
                                <>
                                  <span>${prices.proffesionalMonthlyPrice}*</span>
                                  <span>per month</span>
                                </>
                              }
                              {subscription === 'annual' &&
                                <>
                                  <span>${prices.proffesionalAnnualPrice}*</span>
                                  <span>per year</span>
                                </>
                              }
                            </div>
                            <p>*VAT and local taxes may apply</p>
                          </div>
                        : <div className="plans-pricing-blocked">
                            {user?.stripePayment?.active
                              ? <span>Not available</span>
                              : <>
                                  {seats > proffesionalMaxUsers &&
                                    <span>This plan is not available for you. Your number of seats is more than {proffesionalMaxUsers}.</span>
                                  }
                                  {seats < proffesionalMinUsers && !(seats < allUsers) &&
                                    <span>This plan is not available for you. Your number of seats is less than {proffesionalMinUsers}.</span>
                                  }
                                  {seats < allUsers &&
                                    <span>Not available. The number of seats must be equal to or greater than the number of synced users.</span>
                                  }
                                </>
                            }
                          </div>
                    }
                  </div>

                  {seats <= maxAvailableSeats
                    ? <NavLink
                        to={route}
                        state={{
                          plan: 'Professional',
                          priceDetails: proPrice,
                          company: user.company,
                          annualPrice: prices?.proffesionalAnnualSinglePrice,
                          monthlyPrice: prices?.proffesionalMonthlySinglePrice,
                          seats,
                          renewPlan,
                          testPayment // for testing payments
                        }}
                      >
                        <button 
                          type="button"
                          disabled={seats < proffesionalMinUsers || seats > proffesionalMaxUsers || seats < allUsers
                            || (proPrice.price <= user?.stripePayment?.paidAmount && user?.stripePayment?.active)
                          }
                        >
                          Go to Professional
                        </button>
                      </NavLink>
                    : <button 
                        type="button"
                        onClick={() => openModal({
                          email: user.email,
                          seats,
                          plan: 'Professional',
                          type: proPrice.type
                        })}
                      >
                        Contact Sales
                      </button>
                  }

                  <p className="plans-subcard-users">Up to {allPrices?.proffesionalPrices.at(-1).maxUsers} users</p>
                  
                  <p>SMALL BIZ features included, plus:</p>
                  <ul className="plans-description">
                    <li>Advanced analytics</li>
                    <li>Time based phishing</li>
                    <li>Phish reporting tool</li>
                  </ul>
                </div>
              </div>
              
              <div className={cn("plans-card", {
                "availible-plan":
                  !!enterprisePrice.price && seats >= allUsers
                    && ((enterprisePrice.price > user?.stripePayment?.paidAmount
                      && user?.stripePayment?.active)
                        || !user?.stripePayment?.active)
              })}>
                <h4 className="plans-available-name plans-available-name--3">ENTERPRISE</h4>
                <div className="plans-subcard">
                  <div className="plans-pricing">
                    {!!enterprisePrice.price && seats >= allUsers
                    && ((enterprisePrice.price > user?.stripePayment?.paidAmount
                      && user?.stripePayment?.active)
                        || !user?.stripePayment?.active)
                        ?
                          <div>
                            <div className="plans-pricing-price">
                              {currentPlanId === 4 && !dateExpired && user?.stripePayment?.active &&
                                <div className="plans-pricing-current">
                                  This is your<br/> current plan
                                </div>
                              }
                              {subscription === 'monthly' &&
                                <>
                                  <span>${prices.enterpriseMonthlyPrice}*</span>
                                  <span>per month</span>
                                </>
                              }
                              {subscription === 'annual' &&
                                <>
                                  <span>${prices.enterpriseAnnualPrice}*</span>
                                  <span>per year</span>
                                </>
                              }
                            </div>
                            <p>*VAT and local taxes may apply</p>
                          </div>
                        : <div className="plans-pricing-blocked">
                            {user?.stripePayment?.active
                              ? <span>Not available</span>
                              : <>
                                  {seats > enterpriseMaxUsers && 
                                    <span>This plan is not available for you. Your number of seats is more than {enterpriseMaxUsers}.</span>
                                  }
                                  {seats < enterpriseMinUsers && !(seats < allUsers) &&
                                    <span>This plan is not available for you. Your number of seats is less than {enterpriseMinUsers}.</span>
                                  }
                                  {seats < allUsers &&
                                    <span>Not available. The number of seats must be equal to or greater than the number of synced users.</span>
                                  }
                                </>
                            }
                          </div>
                    }
                  </div>
                  
                  {seats <= maxAvailableSeats
                    ? <NavLink
                        to={route}
                        state={{
                          plan: 'Enterprise',
                          priceDetails: enterprisePrice,
                          company: user.company,
                          annualPrice: prices?.enterpriseAnnualSinglePrice,
                          monthlyPrice: prices?.enterpriseMonthlySinglePrice,
                          seats,
                          renewPlan,
                          testPayment // for testing payments
                        }}
                      >
                        <button
                          type="button"
                          disabled={seats < enterpriseMinUsers || seats > enterpriseMaxUsers || seats < allUsers
                            || (enterprisePrice.price <= user?.stripePayment?.paidAmount && user?.stripePayment?.active)}
                        >
                          Go to Enterprise
                        </button>
                      </NavLink>
                    : <button
                        type="button"
                        onClick={() => openModal({
                          email: user.email,
                          seats,
                          plan: 'Enterprise',
                          type: enterprisePrice.type
                        })}
                      >
                        Contact Sales
                      </button>
                  }

                  <p className="plans-subcard-users">Up to {allPrices?.enterprisePrices.at(-1).maxUsers} users</p>
                  
                  <p>PRO features included, plus:</p>
                  <ul className="plans-description">
                    <li>GRC Controls & Reporting</li>
                    <li>Advanced Language Controls
                      <ul>
                        <li>Spanish</li>
                        <li>French</li>
                        <li>German</li>
                        <li>+7 Other</li>
                      </ul>
                    </li>
                    <li>Role Based Training
                      <ul>
                        <li>IT Security Treats</li>
                        <li>C-Suit Cybersecurity</li>
                        <li>OWASP Top 10 Developer</li> 
                        <li>HR</li>
                        <li>Finance</li>
                        <li>InfoSec Defenders</li>
                      </ul>
                    </li>
                    <li>Role Based Phishing</li>
                  </ul>
                </div>
              </div>
              </>}

            </div>
            
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modal">
                <button onClick={closeModal} className="modal-close"></button>
                <h4 className="modal-cancel-title">Subscription upgrade</h4>
                <div className="modal-cancel-subscription">
                  <p>
                    Your subscription upgrade request is on its way to our<br/>
                    <strong>Sales Team</strong>. We will contact you shortly.
                  </p>
                </div>
                <div className="modal-gotit-button">
                  <button
                    onClick={() => {
                      closeModal();
                      sendRequestEmail(requestPlan);
                    }}
                  >
                    Got It!
                  </button>
                </div>
              </div>
            </Modal>
          </>
        }
      </div>
      {!planActive &&
        <PlansTable
          smallBizMinUsers={smallBizMinUsers}
          smallBizMaxUsers={smallBizMaxUsers}
          proffesionalMaxUsers={proffesionalMaxUsers}
          enterpriseMaxUsers={enterpriseMaxUsers}
        />
      }
    </>
  )
};
